import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatorService } from '.';
import { AlertService } from './alert.service';
import { AuthService } from './auth.service';
import { MenuService } from './menu.service';
import { RequestService } from './request.service';
import { UtilityService } from './utility.service';

@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule
    ],
    providers: [
        AlertService,
        AuthService,
        MenuService,
        RequestService,
        UtilityService,
        TranslatorService
    ]
})
export class CoreModule {
    constructor(private translatorService: TranslatorService) { }
}
