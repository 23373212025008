<div class="container-fluid footer-wrap row p-5">
    <div class="col-12 col-lg-10 mx-auto row">
        <div class="col-12 col-sm-4 col-lg-5 col-xl-6 mb-5 m-sm-auto">
            <img src="../../../assets/logo-footer.svg" alt="logo-FARMASI" class="img-fluid d-block mx-auto" loading="lazy">
            <div class="text-sm mt-2" *ngIf="companyInfo?.details">
                <div class="d-flex justify-content-center text-center text-center">
                    {{ companyInfo.details.name }}
                </div>
                <div class="d-flex justify-content-center text-center">
                    {{ companyInfo.details.address }} {{ companyInfo.details.addressNo }}, {{ companyInfo.details.zip }} {{ companyInfo.details.place }}, {{ companyInfo.details.country }}
                </div>
                <div class="d-flex flex-column flex-xl-row justify-content-center text-center">
                    <div class="mr-2">
                        {{ 'FOOTER.VAT_ID' | translate }}: <span class="font-weight-bold">{{ companyInfo.details.vatId }}</span>
                    </div>
                    <div>
                        {{ 'FOOTER.REGISTRATION_AUTHORITY' | translate }}: <span class="font-weight-bold">{{ companyInfo.details.registrationAuthority }}</span>
                    </div>
                </div>
                <div class="d-flex flex-column flex-xl-row justify-content-center text-center">
                    <div class="mr-2">
                        {{ 'FOOTER.REGISTRATION_NUMBER' | translate }}: <span class="font-weight-bold">{{ companyInfo.details.registrationNo }}</span>
                    </div>
                    <div>
                        {{ 'FOOTER.IBAN' | translate }}: <span class="font-weight-bold">{{ companyInfo.details.iban }}</span>
                    </div>
                </div>
                <div class="d-flex flex-column flex-xl-row justify-content-center text-center">
                    <div class="mr-2">
                        {{ 'FOOTER.PHONE' | translate }}: <span class="font-weight-bold">{{ companyInfo.details.phone }}</span>
                    </div>
                    <div>
                        {{ 'FOOTER.MOBILE' | translate }}: <span class="font-weight-bold">{{ companyInfo.details.mobile }}</span>
                    </div>
                </div>
                <div class="d-flex flex-column flex-xl-row justify-content-center text-center">
                    <div class="mr-2">
                        {{ 'FOOTER.EMAIL' | translate }}: <span class="font-weight-bold">{{ companyInfo.details.email }}</span>
                    </div>
                    <div class="d-none d-xl-block mr-2">
                        |
                    </div>
                    <div>
                        {{ companyInfo.details.url }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-8 col-lg-7 col-xl-6">
            <div class="row">
                <div class="col-12 col-md-4 text-center text-md-left mb-4 mb-md-0">
                    <h6 class="text-uppercase text-bolder mb-3 d-none d-sm-block">Farmasi</h6>
                    <a class="mb-1 footer-link" routerLink="/">{{ (contextService.isPublicMember() ? 'MENU.SHOP' : 'MENU.HOMEPAGE') | translate }}</a>
                    <a class="mb-1 footer-link" [routerLink]="'/shop'">{{ (contextService.isPublicMember() ? 'MENU.ARTICLES' : 'MENU.SHOP') | translate }}</a>
                    <ng-container *ngIf="!contextService.isPublicMember()">
                        <a class="mb-1 footer-link" [routerLink]="'/club/my-orders'">{{ 'MENU.ORDER' | translate }}</a>
                        <a class="mb-1 footer-link" routerLink="/club">{{ 'MENU.MY_CLUB' | translate }}</a>
                        <!-- <a class="mb-1 footer-link" routerLink="/catalogues">{{ 'MENU.CATALOGUE' | translate }}</a> -->
                    </ng-container>
                </div>
                <div class="col-12 col-sm-6 col-md-4 text-center text-sm-left">
                    <h6 class="text-uppercase text-bolder mt-2 mt-sm-0 mb-3">{{ 'DASHBOARD.CONTACT_US' | translate }}
                    </h6>
                    <p class="mb-1">
                        <a class="text-bolderr"
                            href="tel:{{companyInfo?.contacts?.phoneNumber}}">{{companyInfo?.contacts?.phoneNumber}}</a>
                    </p>
                </div>
                <div class="col-12 col-sm-6 col-md-4 text-center text-sm-left">
                    <h6 class="text-uppercase text-bolder mt-2 mt-sm-0 mb-3">{{ 'SHARED.FOLLOW_US' | translate }}</h6>
                    <a target="_blank" href="https://www.facebook.com/{{companyInfo?.contacts?.fb}}">
                        <app-icons [icon]="'fb'"></app-icons>
                    </a>
                    <a target="_blank" href="https://www.instagram.com/{{companyInfo?.contacts?.ig}}">
                        <app-icons [icon]="'ig'"></app-icons>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 text-sm text-center">
    <span>@ {{ settingsService.getAppSetting('year') }} FARMASI. All Rights Reserved.</span>
</div>