import { Component, OnInit } from '@angular/core';
import { ContextService } from 'src/app/core';
import { SettingsService } from '../../core/settings.service';
import { CompanyInfo } from '../routes/cart/cart.model';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    companyInfo: CompanyInfo = new CompanyInfo;
    public pdfURL: string = '';

    constructor(public settingsService: SettingsService, public contextService: ContextService) { }

    ngOnInit(): void {
        this.companyInfo = this.contextService.getCompanyInfo();
        this.pdfURL = "assets/docs/" + window.location.hostname + ".pdf";

        if(this.contextService.getCompanyId() === 2)
          this.pdfURL = "https://www.farmasi.rs/index.php/informacije/opsti-uslovi-prodaje-u-internet-prodavnici"
        else if(this.contextService.getCompanyId() === 3)
          this.pdfURL = "https://www.farmasi.hr/index.php/informacije/opci-uvjeti-prodaje-internet-trgovine";
        else if(this.contextService.getCompanyId() === 4)
          this.pdfURL = "https://www.farmasi.si/index.php/informacije/splosni-pogoji-prodaje-internetne-trgovine";
        else if(this.contextService.getCompanyId() === 6)
          this.pdfURL = "https://www.farmasi.co.me/index.php/informacije/opsti-uslovi-prodaje-internet-trgovine";
    }
}
