import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { LoadingService, AuthService, AlertService, ContextService } from 'src/app/core';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
    public detailedInstructionsUrl: string = `https://s3-eu-west-1.amazonaws.com/test-farmasi-bucket/manual/ordering-manual-${window.location.hostname}.pdf`;

    public loginForm: FormGroup = this.formBuilder.group({
        'username': [null, Validators.required],
        'password': [null, Validators.required]
    });
    public userDisabledModalVisible: boolean = false;

    constructor(
        public loadingService: LoadingService,
        private authService: AuthService,
        private alertService: AlertService,
        public contextService: ContextService,
        private appService: AppService,
        private formBuilder: FormBuilder,
        private router: Router, 
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        let basket = localStorage.getItem('basket');
        let currentVersion = localStorage.getItem('club_appVersion');
        //localStorage.clear();
        if(basket != null)
            localStorage.setItem('basket', basket);
        if(currentVersion != null)
            localStorage.setItem('club_appVersion', currentVersion);

        if(!!localStorage.getItem("public_user"))
            localStorage.removeItem("public_user");
    }

    ngAfterViewInit() {
        this.appService.focusElement('username');
    }

    public login(): void {
        this.loadingService.showLoader();
        

        this.authService.login(this.loginForm.value).subscribe(
            _ =>  {
                this.loadingService.hideLoader();
                if (this.authService.checkAuthority('USER')){
                    this.contextService.getProfile();
                    this.navigateTo();
                }
                else {
                    this.authService.removeAuth();
                    this.alertService.error('ERRORS.LOGIN-FAILED');
                }
            },
            (error) => {
                this.loadingService.hideLoader();
                try {
                    if (error.error.error_description == 'User account is locked')
                        this.router.navigate(['password-reset']);
                    else if(error.error.error_description == 'User is disabled')
                        this.userDisabledModalVisible = true;
                }
                finally {
                    this.alertService.error('ERRORS.LOGIN-FAILED');
                }
            }
        );

    
        // this.authService.login(this.loginForm.value.username.trim(), this.loginForm.value.password)
        //     .finally(() => this.appService.hideLoader()).subscribe(
        //         response => {
        //             if (this.authService.checkAuthority('USER')){
        //                 this.contextService.getProfile();
        //                 this.navigateTo();
        //             }
        //             else {
        //                 this.authService.removeAuth();
        //                 this.alertService.danger('ERRORS.LOGIN-FAILED');
        //             }
        //         },
        //         error => {
        //             try {
        //                 if (error.json().error_description == 'User account is locked')
        //                     this.router.navigate(['password-reset']);
        //                 else if(error.json().error_description == 'User is disabled'){
        //                     this.userDisabledModalVisible = true;
        //                 }
        //             }
        //             finally {
        //                 this.alertService.danger('ERRORS.LOGIN-FAILED');
        //             }
        //         }
        //     );
    }

    private navigateTo(){
        let redirectURL = this.route.snapshot.queryParams['redirectURL'];
        if(redirectURL)
            this.router.navigateByUrl(redirectURL);
        else 
            this.router.navigate(['']);
    }
}
