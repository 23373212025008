import { Injectable } from '@angular/core';

import { RequestService, HttpRequestType } from '../../core';
import { Banner } from './banners.models';

@Injectable({
    providedIn: 'root'
})
export class BannersService {
    constructor(private requestService: RequestService) {}

    public getBanners(promoBannerLocationType: string, onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Get, `/mlm/api/v1/members/promo-banners/search?location=${promoBannerLocationType}&target=WEB&target2=WEB_MOBILE`, null, null, (banners: Banner[]) => {
            onSuccess(banners);
        });
    }

    public getCampaignArtikals(onSuccess?: Function): void {
      this.requestService.createRequest(HttpRequestType.Get, `/mlm/api/v1/products/current-campaign/catalogue-artikals`, null, null, (response: CatalogueArtikal[]) => {
          onSuccess(response);
      });
    }


}
export class CatalogueArtikal {
  productName: string;
  priceCatalogue: number;
  productId: string;
  selectedQuantity?: number;
}