import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Product } from '../routes/shop/shop.models';
import { Carousel } from './carousel.model';

declare let $: any;
@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
    @Input('products') products: Product[] = [];
    @Input('carousels') carousels: Carousel[] = [];
    @Input('imgCarousel') imgCarousel: boolean = true;

    public productsInSlide: number
    public numberOfSliders = [1, 2, 3];
    public imageLoaded: boolean = false;

    constructor(private router: Router, @Inject(DOCUMENT) private document: Document) { }

    ngOnInit(): void {
        this.setProductCarlousel();
        if(this.imgCarousel)
            $("#carouselCaptions").carousel();
    }

    private setProductCarlousel() {
        if (window.screen.width > 1200) {
            this.productsInSlide = 4;
            this.numberOfSliders = [1, 2];
        }
        else if (window.screen.width < 1200 && window.screen.width > 992) {
            this.productsInSlide = 3;
            this.numberOfSliders = [1, 2];
        }
        else {
            this.productsInSlide = 2;
            this.numberOfSliders = [1, 2, 3];
        }
    }

    public openLink(link: string) {
        if(!link && link.length < 2) return       
        if (link.includes('www.') || link.includes('https'))
            this.document.location.href = link; // if external link
        else
            this.router.navigate([link]); // if route is in this project
    }

    next() {
        $('.carousel-products').carousel('next');
    }

    prev() {
        $('.carousel-products').carousel('prev');
    }


}
