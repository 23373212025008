<div [@inOutAnimation] class="points-container" *ngIf="memberLoyaltyProgram?.length > 0">    
    <mat-expansion-panel [disabled]="!showNonCalculatedDropdown" class="shadow-none loyalty-expansion">
        <mat-expansion-panel-header>
          <mat-panel-title class="fs-sm-10 align-items-center">
            Stanje coinsa
          </mat-panel-title>
          <mat-panel-description class="fs-sm-10 align-items-center">
            <span class="key">
                <img src="../../../assets/icons/farmasi_wallet.png" class="clickable height-20p">
                {{ memberLoyaltyProgram[0].pointsBalance || 0 }}
            </span>
            <span class="key">
                <img src="../../../assets/icons/farmasi_coin.png" class="clickable height-20p">
                {{ memberLoyaltyProgram[0].pointsEarned || 0 }}
            </span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent *ngIf="showNonCalculatedDropdown">
            <ng-container *ngFor="let loyaltyProgram of memberLoyaltyProgram">
                <mat-panel-title class="fs-sm-10 my-2">
                    Stanje neobračunatih coinsa
                  </mat-panel-title>
                <ng-container *ngFor="let item of loyaltyProgram.items">
                    <div class="item-container" *ngIf="!item.calculated">
                        <span class="key">
                            {{ 'ORDER.CAMPAIGN' | translate }}
                        </span>
                        <span class="value">
                            {{ item.campaignDescription }}
                        </span>
                        <span class="key">
                            {{ 'LOYALTY.POINTS_EARNED' | translate }}
                        </span>
                        <span class="value">
                            {{ item.pointsEarned }}
                        </span>
                        <span class="key">
                            Broj naručenih brand proizvoda
                        </span>
                        <span class="value">
                            {{ item.productsOrdered }}
                        </span>
                    </div>
                </ng-container>
            </ng-container>
        </ng-template>
    </mat-expansion-panel>
    
    <div class="item-container mt-3">
      <span class="key">
        Ulazni kumulativni iznos narudžbi
      </span>
      <span class="value">
          {{ memberLoyaltyProgram[0].refernceValue > -1 ? (memberLoyaltyProgram[0].refernceValue| number:'1.2-2') : 0 | number:'1.2-2' }}
      </span>
      <span class="key">
        Trenutni kumulativni iznos narudžbi
      </span>
      <span class="value">
          {{ currentCumulativeOrderValue | number:'1.2-2' }}
      </span>
      <span class="key">
        Razlika
      </span>
      <span class="value">
          {{ currentCumulativeOrderValue - memberLoyaltyProgram[0].refernceValue | number:'1.2-2' }}
      </span>
      <span class="key">
        Coinsi na čekanju
      </span>
      <span class="value">
          {{ memberLoyaltyProgram[0].pointsEarnedCalculationCalculated }}
      </span>
    </div>
</div>