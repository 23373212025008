import { Injectable } from '@angular/core';
import { HttpRequestType, RequestService } from '../../core/request.service'

@Injectable({
    providedIn: 'root'
})

export class ReferralService {

    private readonly url: string = '/mlm/api/v1/referral';

    constructor(private requestService: RequestService) { }


    public getValidReferral(memberNumber: string, onSuccess: Function, onFail: Function): void {
        this.requestService.createRequest(HttpRequestType.Get, this.url + '/' + memberNumber, null, null, (result: any) => {
            onSuccess(result);
        }, (error: any) => {
            onFail(error);
        });
    }

    public generateMemberNumber(companyId: number, onSuccess: Function, onFail: Function): void {
        this.requestService.createRequest(HttpRequestType.Get, this.url + '/generate/' + companyId, null, null, (result: String) => {
            onSuccess(result);
        }, (error: any) => {
            onFail(error);
        });
    }

    public getCountries(){
        return [
            { img: "../../../../assets/images/icon-albania.png", companyId: 12, url: "klub.farmasi.al" },
            { img: "../../../../assets/images/icon-bosnia.png", companyId: 1, url: "klub.farmasi.ba"},
            { img: "../../../../assets/images/icon-croatia.png", companyId: 3, url: "klub.farmasi.hr"},
            { img: "../../../../assets/images/icon-kosovo.png", companyId: 13, url: "klub.farmasi.com.al"},
            { img: "../../../../assets/images/icon-macedonia.png", companyId: 5, url: "klub.farmasi.mk"},
            { img: "../../../../assets/images/icon-serbia.png", companyId: 2,  url: "klub.farmasi.rs"},
            { img: "../../../../assets/images/icon-slovenia.png", companyId: 4, url: "klub.farmasi.si"},
            { img: "../../../../assets/images/icon-cg.png", companyId: 6,  url: "klub.farmasi.co.me"}
        ];
    }
}
