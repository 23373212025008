
import { Injectable,  } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


import { Constants } from '../app.constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertService } from './alert.service';

export class Token {
    public access_token: string;
    public refresh_token: string;
    public expires_in: number;
    public issued: Date;
    public user_name: string;
    public authorities: Array<string>
}

@Injectable()
export class AuthService {
    private readonly endpoint: string = `${ Constants.auth }oauth/token`;
    private readonly guestEndpoint: string ='/mlm/api/v1/public/link';
    private readonly tokenName: string = 'erp_mlm_shop_token';
    private _loginHeaders: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Basic Y2xpZW50d3A6czNjcjN0' });


    private readonly credentialsExpiryDateStorageName: string = 'last_shown_credentials_expiry_date_message';

    public showCredentialsExpiryDateMessage: boolean = false;
    public credentialsExpiryDaysLeft: number;

    public onLangChange: (tokenLanguage: string) => void = null;

    constructor(private http: HttpClient, private router: Router, private alertService: AlertService) {
        if (this.isTokenInStorage) {
            this.token = JSON.parse(localStorage.getItem(this.tokenName));
            this._authHeaders.delete('Authorization');
            this._authHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.token.access_token });
            this.multipartRequestHeaders.delete('Authorization');
            this.multipartRequestHeaders = new HttpHeaders({ 'Authorization': `Bearer ${this.token.access_token}` });
        }

        this.checkCredentialsExpiryDate();

        // setInterval(() => {
        //     if (!this.isTokenInStorage && this.isTokenSet) {
        //         this.removeAuth();
        //         this.router.navigate(['login']);
        //         this.alertService.error('ERRORS.UNAUTHORIZED');
        //     }
        // }, 3000);

        setInterval(() => {
            this.checkCredentialsExpiryDate();
        }, 1800000);
    }

    private _anonymousHeaders: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    public get anonymousHeaders(): HttpHeaders {
        return this._anonymousHeaders;
    }

    private _authHeaders: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    private multipartRequestHeaders: HttpHeaders = new HttpHeaders();

    public get authHeaders(): HttpHeaders {
        return this._authHeaders;
    }

    public isTokenSet(): boolean {
        if (this.token == null)
            return false;

        return true;
    }

    public get isTokenInStorage(): boolean {
        return !!localStorage.getItem(this.tokenName);
    }
    
    public isTokenGone(): boolean {
        return !!localStorage.getItem(this.tokenName);
    }

    public get username(): string {
        return this.token != null ? this.token.user_name : '';
    }
    

    // public get userId(): string {
    //     return this.token != null ? this.token.userId : '';
    // }

    // public get roles(): string[] {
    //     return this.token != null ? this.token.roles : [];
    // }

    public get authorities(): string[] {
        return this.token != null ? this.token.authorities : [];
    }

    // public get language(): string {
    //     return this.token != null ? this.token.language : '';
    // }

    public get refreshTokenValue(): string {
        return this.token != null ? this.token.refresh_token : null;
    }

    public token: Token;

    private setToken(token: Token) {
        token.issued = new Date();

        let userInfo: Token = JSON.parse(atob(token.access_token.split('.')[1]));

        // token.roles = userInfo.roles;
        token.authorities = userInfo.authorities;
        token.user_name = userInfo.user_name;

        this.token = token;

        localStorage.removeItem(this.tokenName);
        localStorage.setItem(this.tokenName, JSON.stringify(token));

        this._authHeaders.delete('Authorization');
        this.multipartRequestHeaders.delete('Authorization');
        this._authHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.token.access_token });
        this.multipartRequestHeaders = new HttpHeaders({ 'Authorization': `Bearer ${this.token.access_token}`});
        this.checkCredentialsExpiryDate();
    }

    public login(credentials): Observable<any> {
        let parameters: string = `username=${ credentials.username.trim() }&password=${ credentials.password }&grant_type=password`;

        return this.http
                    .post(this.endpoint, parameters, { headers: this._loginHeaders })
                    .pipe(map((response: any) => {
                        this.setToken(response);
                    }));
    }

    public refreshTokenIfNeeded(): Observable<void> {
        if (this.token != null) {
            let expirationTime: Date = new Date();
            let currentTime: Date = new Date();

            expirationTime.setTime(new Date(this.token.issued).getTime() + (this.token.expires_in - 30) * 1000);

            if (currentTime.getTime() > expirationTime.getTime())
                return this.refreshToken();
        }

        return of(null).pipe(map(() => null));
    }
        
    public getMultipartHeaders() {
        return this.multipartRequestHeaders;
    }


    // public hasRole(role: string): boolean {
    //     return this.token != null && this.token.roles && this.token.roles.includes(role);
    // }

    // public hasAnyRole(...roles: string[]): boolean {
    //     return roles == null || (this.token != null && this.token.roles && roles.some(role => this.hasRole(role)));
    // }

    public hasAuthority(authority: string): boolean {
        return this.token != null && this.token.authorities && this.token.authorities.includes(authority);
    }

    public hasAnyAuthority(...authorities: string[]): boolean {
        return authorities == null || (this.token != null && this.token.authorities && authorities.some(authority => this.hasAuthority(authority)));
    }

    public performRedirection(): void {        
        this.router.navigate(['']);
    }

    public setLanguageHeaders(language: string): void {
        this._loginHeaders.delete('Accept-Language');
        this._loginHeaders.append('Accept-Language', language);
        this._anonymousHeaders.delete('Accept-Language');
        this._anonymousHeaders.append('Accept-Language', language);
        this._authHeaders.delete('Accept-Language');
        this._authHeaders.append('Accept-Language', language);
    }

    /**
     * Deletes auth token from local storage.
     */
    public removeAuth(): void {
        delete this.token;
        this._authHeaders.delete('Authorization');
        this.multipartRequestHeaders.delete('Authorization');
        let currentVersion = localStorage.getItem(Constants.versionStorageName);
        localStorage.clear();
        if(currentVersion != null)
            localStorage.setItem(Constants.versionStorageName, currentVersion);
        window.location.reload(true);
    }

    public refreshToken(): Observable<void> {
        if (!this.refreshTokenValue)
            this.removeAuth();

        const parameters: string = `refresh_token=${ this.refreshTokenValue }&grant_type=refresh_token`;

        return this.http
                   .post(this.endpoint, parameters, { headers: this._loginHeaders })
                   .pipe(map((response: any) => this.setToken(response)));
    }

    public checkCredentialsExpiryDate() {
        // if(this.isTokenSet && this.token.credentialsExpireDate) {
        //     let t2 = new Date(`${this.token.credentialsExpireDate}Z`).getTime();
        //     let t1 = new Date().getTime();

        //     this.credentialsExpiryDaysLeft = parseInt(((t2-t1)/(24*3600*1000)).toString());

        //     if(this.credentialsExpiryDaysLeft < 10) {
        //        if(!!localStorage.getItem(this.credentialsExpiryDateStorageName)) {
        //            let d1 = new Date(localStorage.getItem(this.credentialsExpiryDateStorageName));
        //            let d2 = new Date();

        //            if(d1.toDateString() !== d2.toDateString()) {
        //                this.showCredentialsExpiryDateMessage = true;
        //            }
        //        } else 
        //         this.showCredentialsExpiryDateMessage = true;
        //     }
        // }
    }

    public setShowCredentialsExpiryDateMessage() {
        this.showCredentialsExpiryDateMessage = false;
        localStorage.removeItem(this.credentialsExpiryDateStorageName);
        localStorage.setItem(this.credentialsExpiryDateStorageName, new Date().toString());
    }


    public createGuestAndLogin(memberNumber: string = '', companyId: number, leaderMemberNumber: string = '', productId: string = '', isTempMember: boolean, onSuccess: Function): Observable<any> {
        // member number je ako je korisnik logovan i ima svoj memberNumber
        //  samo je companyId required

        
        const parameters = `memberNumber=${memberNumber}&` + 
                         `companyId=${companyId}&` + 
                         `leaderMemberNumber=${leaderMemberNumber}&` + 
                         `productId=${productId}&` +
                         `isTempMember=${isTempMember}`;

        return this.http.post(this.guestEndpoint , parameters, { headers: this._loginHeaders } )
                        .pipe(
                            map( (response: any) => {
                                //get informations about current profile
                                let res = response;
                                this.setCookie(res.token);
                                this.setHeaders(res.token.access_token);
                                onSuccess(res);
                            }
                        ));
    }

    private setCookie(token: Token): void {
        token.issued = new Date();

        let userInfo: Token = JSON.parse(atob(token.access_token.split('.')[1]))
        token.authorities = userInfo.authorities;
        token.user_name = userInfo.user_name;

        this.token = token;

        localStorage.removeItem(this.tokenName);
        localStorage.setItem(this.tokenName, JSON.stringify(token));
    }

    private setHeaders(access_token: string): void {
        this._authHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token });
        this.multipartRequestHeaders = new HttpHeaders({ 'Authorization': `Bearer ${access_token}` });
    }

    public checkAuthority(authorityToCheck: string): boolean {
        if (this.token == null)
            return false;

        for (let authority of this.token.authorities)
            if (authority == authorityToCheck)
                return true;

        return false;
    }
}

