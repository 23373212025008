
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PreloadingStrategy, Route } from '@angular/router';

@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {

    preload(route: Route, loadMe: () => Observable<any>): Observable<any> {
        if (route.data && route.data['preloadDelay']) {
          // new Observable((aa) => {
          //   setTimeout(() => {
          //     aa.next(loadMe())
          //     // return loadMe()
          //   }, 2000);
          // })
          return of(null);// this route will load only when activate it
        }
        else
            return loadMe(); // this route will load immediately
    }

}
