<div class="container pt-register-wrapper">
    <div class="row">
        <div class="col-md-12 ">
            <img class="pt-register-logo" src="../../../assets/logo-header.svg" />
            <h4 class="text-black text-bolder mb-4">{{'REGISTRATION.HEADERMESSAGE' | translate}}</h4>
        </div>
    </div>
    <form novalidate [formGroup]="registerForm" (ngSubmit)="submitForm(registerForm.value)">
        <div class="row">
            <div class="col-md-6">
                <div *ngIf="type == 'COMPANY'" class="form-group has-feedback">
                    <input placeholder="{{ 'REGISTRATION.NAME' | translate }}" id="name" type="text" class="form-control" formControlName="name" [pattern]="'^[^\u0370-\uffff]+$'">
                    <span *ngIf="registerForm.controls['name'].hasError('required') && registerForm.controls['name'].touched && registerForm.controls['name'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                    <span *ngIf="registerForm.controls['name'].hasError('pattern') && registerForm.controls['name'].touched && registerForm.controls['name'].touched" class="error">{{ 'VALIDATION.LATIN-INVALID' | translate }}</span>
                </div>
                <div *ngIf="type == 'PERSON' && visibleData['firstName']" class="form-group has-feedback">
                    <input placeholder="{{ 'REGISTRATION.FIRSTNAME' | translate }}" id="firstName" type="text" class="form-control" formControlName="firstName" maxlength="20" [pattern]="'^[^\u0370-\uffff]+$'" (blur)="capitalize('firstName')">
                    <span *ngIf="registerForm.controls['firstName'].hasError('required') && registerForm.controls['firstName'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                    <span *ngIf="registerForm.controls['firstName'].hasError('pattern') && registerForm.controls['firstName'].touched" class="error">{{ 'VALIDATION.LATIN-INVALID' | translate }}</span>
                    <span *ngIf="!registerForm.controls['firstName'].hasError('required') && registerForm.controls['firstName'].touched && !registerForm.controls['firstName'].hasError('pattern') && registerForm.controls['firstName'].hasError('minlength')" class="error">{{ 'VALIDATION.SHORT-INPUT' | translate }}</span>
                </div>
                <div *ngIf="type == 'PERSON' && visibleData['parentName']" class="form-group has-feedback">
                    <input placeholder="{{ 'REGISTRATION.PARENTNAME' | translate }}" id="parentName" type="text" class="form-control" formControlName="parentName" maxlength="20" [pattern]="'^[^\u0370-\uffff]+$'" (blur)="capitalize('parentName')">
                    <span *ngIf="registerForm.controls['parentName'].hasError('required') && registerForm.controls['parentName'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                    <span *ngIf="registerForm.controls['parentName'].hasError('pattern') && registerForm.controls['parentName'].touched" class="error">{{ 'VALIDATION.LATIN-INVALID' | translate }}</span>
                    <span *ngIf="!registerForm.controls['parentName'].hasError('required') && registerForm.controls['parentName'].touched && !registerForm.controls['parentName'].hasError('pattern') && registerForm.controls['parentName'].hasError('minlength')" class="error">{{ 'VALIDATION.SHORT-INPUT' | translate }}</span>
                </div>
                <div *ngIf="type == 'PERSON' && visibleData['lastName']" class="form-group has-feedback">
                    <input placeholder="{{ 'REGISTRATION.LASTNAME' | translate }}" id="lastName" type="text" class="form-control" formControlName="lastName" maxlength="30" [pattern]="'^[^\u0370-\uffff]+$'" (blur)="capitalize('lastName')">
                    <span *ngIf="registerForm.controls['lastName'].hasError('required') && registerForm.controls['lastName'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                    <span *ngIf="registerForm.controls['lastName'].hasError('pattern') && registerForm.controls['lastName'].touched" class="error">{{ 'VALIDATION.LATIN-INVALID' | translate }}</span>
                    <span *ngIf="!registerForm.controls['lastName'].hasError('required') && registerForm.controls['lastName'].touched && !registerForm.controls['lastName'].hasError('pattern') && registerForm.controls['lastName'].hasError('minlength')" class="error">{{ 'VALIDATION.SHORT-INPUT' | translate }}</span>
                </div>
                <div *ngIf="visibleData['gender']" class="form-group has-feedback">
                    <input placeholder="{{'REGISTRATION.GENDER' | translate}}" list="gender" (change)="onGenderChanged($event)" (click)="registerForm.controls['gender'].markAsTouched()">
                    <datalist id="gender">
                        <option *ngFor="let gender of genderOptions" data-value="{{ gender.value }}" value="{{gender.label}}" ></option>
                    </datalist>
                    <span *ngIf="registerForm.controls['gender'].hasError('required') && registerForm.controls['gender'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                </div>
                <div *ngIf="visibleData['email']" class="form-group has-feedback">
                    <input placeholder="{{ 'REGISTRATION.EMAILADDRESS' | translate }}" id="email" type="text" class="form-control" [readOnly]="recDisabled" formControlName="email" maxlength="100" (blur)="capitalize('email')">
                    <span *ngIf="registerForm.controls['email'].hasError('required') && registerForm.controls['email'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                    <span *ngIf="!registerForm.controls['email'].hasError('required') && registerForm.controls['email'].touched && registerForm.controls['email'].hasError('pattern')" class="error">{{ 'VALIDATION.INVALID-EMAIL' | translate }}</span>
                </div>
                <div *ngIf="visibleData['phoneMobile']" class="form-group has-feedback">
                    <div class="input-group">
                        <span class="input-group-addon phone-addon">{{ telephoneCountryCode }}</span>
                        <input placeholder="{{ 'REGISTRATION.PHONEMOBILE' | translate }}" formControlName="phoneMobile" type="text" class="form-control phoneMobile" maxlength="11" (keypress)="ignore($event)" >
                    </div>
                    <span *ngIf="registerForm.controls['phoneMobile'].hasError('required') && registerForm.controls['phoneMobile'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                    <span *ngIf="!registerForm.controls['phoneMobile'].hasError('required') && registerForm.controls['phoneMobile'].touched && registerForm.controls['phoneMobile'].hasError('minlength')" class="error">{{ 'VALIDATION.SHORT-INPUT' | translate }}</span>
                    <span *ngIf="!registerForm.controls['phoneMobile'].hasError('required') && registerForm.controls['phoneMobile'].touched && !registerForm.controls['phoneMobile'].hasError('minlength') && registerForm.controls['phoneMobile'].hasError('pattern')" class="error">{{ 'VALIDATION.INVALID-PHONE' | translate }}</span>
                </div>
                <div *ngIf="!validateZipCode && visibleData['place']" class="form-group has-feedback">
                    <input placeholder="{{ 'REGISTRATION.PLACE' | translate }}" id="place" type="text" class="form-control" formControlName="place" maxlength="20" [pattern]="'^[^\u0370-\uffff]+$'" (blur)="capitalize('place')">
                    <span *ngIf="registerForm.controls['place'].hasError('required') && registerForm.controls['place'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                    <span *ngIf="registerForm.controls['place'].hasError('pattern') && registerForm.controls['place'].touched" class="error">{{ 'VALIDATION.LATIN-INVALID' | translate }}</span>
                    <span *ngIf="!registerForm.controls['place'].hasError('required') && registerForm.controls['place'].touched && !registerForm.controls['place'].hasError('pattern') && registerForm.controls['place'].hasError('minlength')" class="error">{{ 'VALIDATION.SHORT-INPUT' | translate }}</span>
                </div>
                <div *ngIf="validateZipCode && visibleData['place']" class="form-group has-feedback">
                    <input list="place" [placeholder]="'REGISTRATION.PLACE' | translate" formControlName="place" (change)="changePlace()">
                    <datalist id="place">
                        <option *ngFor="let place of placesOptions" value="{{place.label}}"></option>
                    </datalist>
                    <span *ngIf="(registerForm.controls['place'].hasError('required') && !registerForm.controls['place'].touched && registerForm.controls['place'].hasError('pattern')) || (registerForm.controls['place'].hasError('required') && registerForm.controls['place'].touched)" class="error">{{ 'SHARED.CHOOSE_FROM_SUGGESTED' | translate }}</span>
                </div>
                <div *ngIf="visibleData['zipCode']" class="form-group has-feedback">
                    <input placeholder="{{ 'REGISTRATION.ZIP' | translate }}" id="zipCode" type="text" class="form-control" formControlName="zipCode" [readOnly]="validateZipCode" maxlength="5">
                    <span *ngIf="registerForm.controls['zipCode'].hasError('required') && registerForm.controls['zipCode'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                    <span *ngIf="registerForm.controls['zipCode'].hasError('pattern') && registerForm.controls['zipCode'].touched" class="error">{{ 'VALIDATION.INVALID' | translate }}</span>
                    <span *ngIf="validateZipCode && registerForm.controls['place'].touched && registerForm.controls['zipCode'].hasError('required')" class="error">{{ 'SHARED.CHOOSE_FROM_SUGGESTED' | translate }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div *ngIf="visibleData['address']" class="form-group has-feedback">
                    <input placeholder="{{ 'REGISTRATION.ADDRESS' | translate }}" id="address" type="text" class="form-control" formControlName="address" maxlength="60" [pattern]="'^[^\u0370-\uffff]+$'" (blur)="capitalize('address')">
                    <span *ngIf="registerForm.controls['address'].hasError('required') && registerForm.controls['address'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                    <span *ngIf="registerForm.controls['address'].hasError('pattern') && registerForm.controls['address'].touched" class="error">{{ 'VALIDATION.LATIN-INVALID' | translate }}</span>
                    <span *ngIf="!registerForm.controls['address'].hasError('required') && registerForm.controls['address'].touched && !registerForm.controls['address'].hasError('pattern') && registerForm.controls['address'].hasError('minlength')" class="error">{{ 'VALIDATION.SHORT-INPUT' | translate }}</span>
                </div>
                <div *ngIf="visibleData['addressNo']" class="form-group has-feedback">
                    <input placeholder="{{ 'REGISTRATION.ADDRESSNUMBER' | translate }}" id="addressNo" type="text" class="form-control" formControlName="addressNo" maxlength="4" [pattern]="'^[^\u0370-\uffff]+$'">
                    <span *ngIf="registerForm.controls['addressNo'].hasError('required') && registerForm.controls['addressNo'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                    <span *ngIf="registerForm.controls['addressNo'].hasError('pattern') && registerForm.controls['addressNo'].touched" class="error">{{ 'VALIDATION.LATIN-INVALID' | translate }}</span>
                </div>
                <div *ngIf="visibleData['idNumber']" class="form-group has-feedback">
                    <input placeholder="{{ 'REGISTRATION.IDNUMBER' | translate }}" id="idNumber" type="text" class="form-control" formControlName="idNumber" maxlength="20" [pattern]="'^[^\u0370-\uffff]+$'">
                    <span *ngIf="registerForm.controls['idNumber'].hasError('required') && registerForm.controls['idNumber'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                    <span *ngIf="registerForm.controls['idNumber'].hasError('pattern') && registerForm.controls['idNumber'].touched" class="error">{{ 'VALIDATION.LATIN-INVALID' | translate }}</span>
                    <span *ngIf="!registerForm.controls['idNumber'].hasError('required') && registerForm.controls['idNumber'].touched && !registerForm.controls['idNumber'].hasError('pattern') && registerForm.controls['idNumber'].hasError('minlength')" class="error">{{ 'VALIDATION.SHORT-INPUT' | translate }}</span>
                </div>
                <div *ngIf="visibleData['personalIdentificationNumber']" class="form-group has-feedback">
                    <input placeholder="{{ 'REGISTRATION.PERSONALIN' | translate }}" id="personalIdentificationNumber" type="text" class="form-control" formControlName="personalIdentificationNumber" maxlength="13" [pattern]="'^[^\u0370-\uffff]+$'">
                    <span *ngIf="registerForm.controls['personalIdentificationNumber'].hasError('pattern') && registerForm.controls['personalIdentificationNumber'].touched" class="error">{{ 'VALIDATION.LATIN-INVALID' | translate }}</span>
                </div>
                <div *ngIf="visibleData['birthDate']" class="form-group has-feedback">
                    <input type="text" class="form-control" formcontrolname="birthDate" id="birthDate" [min]="minDate" [max]="maxDate" placeholder="{{'REGISTRATION.BIRTHDATE' | translate}}" (click)="registerForm.controls['birthDate'].markAsTouched();" (change)="checkBirthDate($event)" onfocus="(this.type='date')">
                    <span *ngIf="registerForm.controls['birthDate'].hasError('required') && registerForm.controls['birthDate'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                </div>
                <div *ngIf="visibleData['birthPlace']" class="form-group has-feedback">
                    <input placeholder="{{ 'REGISTRATION.BIRTHPLACE' | translate }}" id="birthPlace" type="text" class="form-control" formControlName="birthPlace" maxlength="20" [pattern]="'^[^\u0370-\uffff]+$'" (blur)="capitalize('birthPlace')">
                    <span *ngIf="registerForm.controls['birthPlace'].hasError('required') && registerForm.controls['birthPlace'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                    <span *ngIf="registerForm.controls['birthPlace'].hasError('pattern') && registerForm.controls['birthPlace'].touched" class="error">{{ 'VALIDATION.LATIN-INVALID' | translate }}</span>
                    <span *ngIf="!registerForm.controls['birthPlace'].hasError('required') && registerForm.controls['birthPlace'].touched && !registerForm.controls['birthPlace'].hasError('pattern') && registerForm.controls['birthPlace'].hasError('minlength')" class="error">{{ 'VALIDATION.SHORT-INPUT' | translate }}</span>
                </div>
                <div *ngIf="visibleData['defaultLocale']" class="form-group has-feedback">
                    <input list="language" placeholder="{{'REGISTRATION.LANGUAGE' | translate}}" (click)="registerForm.controls['defaultLocale'].markAsTouched();" (change)="onLanguageChanged($event)">
                    <datalist id="language">
                        <option *ngFor="let language of languagesOptions" value="{{language.label}}" ></option>
                    </datalist>
                    <span *ngIf="registerForm.controls['defaultLocale'].hasError('required') && registerForm.controls['defaultLocale'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                </div>
                <div *ngIf="visibleData['recommendation']" class="form-group has-feedback">
                    <input placeholder="{{ 'REGISTRATION.RECOMMENDATION' | translate }}" id="recommendation" type="text" class="form-control" [readOnly]="recDisabled" formControlName="recommendation" maxlength="40" [pattern]="'^[^\u0370-\uffff]+$'">
                    <span *ngIf="registerForm.controls['recommendation'].hasError('pattern') && registerForm.controls['recommendation'].touched" class="error">{{ 'VALIDATION.LATIN-INVALID' | translate }}</span>
                </div>
            </div>
        </div>
    </form>
    <div class="row pt-register-checkboxes">
        <div class="col-md-12">
            <input class="mr-2 checkbox" [formControl]="registerForm.controls['subscribeNewsLetter']" type="checkbox" id="termsCheckBox01" name="termsCheckBox01" value="termsCheckBox01">
            <label for="termsCheckBox01">{{'REGISTRATION.ACCEPTTERMSANDNEWSLETTER' | translate}}</label><br>
            <div class="pt-spacer-20"></div>
            <input class="mr-2 checkbox" [formControl]="registerForm.controls['touAccepted']" type="checkbox" id="termsCheckBox02" name="termsCheckBox02" value="termsCheckBox02">
            <label for="termsCheckBox02">{{'REGISTRATION.ACCEPTCONDITIONS' | translate}} 
                <a class="tou-label" [href]="pdfURL" target="_blank">{{'REGISTRATION.ACCEPTCONDITIONSSECOND' | translate}}</a>.</label><br>
        </div>
    </div>
    <div class="row pt-register-registrationButton">
        <div class="col-md-12">
            <input id="registrationButton" [disabled]="!registerForm.valid || !registerForm.controls['touAccepted'].value || appService.requestsOnWait" class="shortAnim text-center noselect" (click)="submitForm(registerForm.value)" [value]="'REGISTRATION.CREATEACCOUNT' | translate">
            <!-- <input id="registrationButton" class="shortAnim" type="submit" (click)="submitForm(registerForm.value)" [value]="'REGISTRATION.CREATEACCOUNT' | translate"> -->
            <p *ngIf="isTempMember">
                <a [routerLink]="['/']">{{ 'REGISTRATION.BACK' | translate}}</a>
            </p>
            <p *ngIf="!isTempMember"><a [routerLink]="['/login']" >{{'REGISTRATION.BACKTOLOGIN' | translate}}</a></p>
        </div>
    </div>
</div>