import { Injectable } from '@angular/core';
import { AuthService, HttpRequestType, RequestService } from 'src/app/core';

@Injectable({
    providedIn: 'root'
})
export class UserBlockService {
    public userBlockVisible: boolean;
    private readonly url: string = '/mlm/api/v1/members/profile-photo';
    private readonly urlUpload: string = '/mlm/api/v1/files';

    constructor(private requestService: RequestService, private authService: AuthService) {
        this.userBlockVisible = true;
    }

    getVisibility() {
        return this.userBlockVisible;
    }

    setVisibility(stat = true) {
        this.userBlockVisible = stat;
    }

    toggleVisibility() {
        this.userBlockVisible = !this.userBlockVisible;
    }

    updateProfileImage(file: any, callback) {
        this.requestService.createRequest(HttpRequestType.Post, this.urlUpload, file, this.authService.getMultipartHeaders(), (result: any) => {     
            this.requestService.createRequest(HttpRequestType.Put, this.url, {fileName: result.catalogueURL}, null, (result: any) => {
                callback()
            });
        });
    }
}
