import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CroppieDirective } from 'angular-croppie-module';
import { ContextService } from 'src/app/core';
import { ProfileService } from 'src/app/inside-layout/routes/club-layout/profile/profile.service';
import { UserBlockService } from './user-block.service';

@Component({
    selector: 'app-user-block',
    templateUrl: './user-block.component.html',
    styleUrls: ['./user-block.component.scss']
})
export class UserBlockComponent implements OnInit {
    @ViewChild('uploadPicture') uploadPicture;
    @Input('showPicture') showPicture: boolean = true;

    public showEditPictureModal: boolean = false;
    public editProfileImage: any;
    public editPictureForm: FormGroup;
    public profileTouched: boolean = false;
    public profileImg: string;
    public base64textStringProfile: string = "";
    public typeProfile: string = "";
    public croppieDirective: CroppieDirective;

    modalRef: any;

    constructor(public contextService: ContextService, public profileService: ProfileService, private userblockService: UserBlockService, private formBuilder: FormBuilder) { }

    ngOnInit(): void {
        // this.setEditPictureFormGroup();
    }
    
    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

}
