import { Injectable } from '@angular/core';
import { HttpRequestType, RequestService } from '../../../core/request.service'
import { AuthService } from '../../../core/auth.service';
import { AppService } from '../../../app.service';
import { Order, Complaint } from './cart.model';

@Injectable({
    providedIn: 'root'
})
export class CartService {
    public orders: Array<Order> = [];
    public minimalValue: number;
    public paymentTypes = [];
    public verifyResponse: Array<any>;
    public motivationalPlanItems: Array<any>;

    private readonly url: string = '/mlm/api/v1/orders';
    private readonly baseUrl: string = '/mlm/api/v1';

    constructor(private requestService: RequestService, private appService: AppService, private authService: AuthService) {
        // if (this.authService.isTokenSet())
        //     this.getMinimalValue();
    }

    public getAllOrders(callback: Function): void {
        this.requestService.createRequest(HttpRequestType.Get, this.url, null, null, (orders: Array<Order>) => {
            this.orders = orders;
            if (callback)
                callback();
        }, () => {
            if (callback)
                callback();
        });
    }

    public verifyOrder(orderId: number, onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Post, this.url + '/' + orderId + '/verify', null, null, (response: any) => {
            this.verifyResponse = response;
            onSuccess(response);
        });
    }
    
    public createOrder(order: Order, onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Post, this.url, order, null, (orderRes: Order) => {
            this.orders.unshift(orderRes);
            onSuccess(orderRes);
        });
    }

    // public createOrderPublic(order: Order, email: string, onSuccess: Function): void {
    //     this.requestService.createRequest(HttpRequestType.Post, this.url+"/public?email=" + email, order, null, (orderRes: Order) => {
    //         this.orders.unshift(orderRes);
    //         onSuccess(orderRes);
    //     });
    // }

    
    public editOrder(order: Order, onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Put, this.url, order, null, (orderRes: Order) => {
            this.orders[this.orders.findIndex(e => e.id == orderRes.id)] = orderRes;
            onSuccess(orderRes);
        });
    }

    public submitOrder(orderId, callback): void {
        this.requestService.createRequest(HttpRequestType.Post, this.url + '/' + orderId + '/submit', null, null, (response: any) => {
            var index = this.orders.findIndex(el => el.id == orderId);
            this.orders[index].status = 'SUBMITTED';

            callback();
        });
    }

    public submitOrderPublic(orderId, email: string, callback, tempUserEmail: string = ''): void {
        let url = this.url + '/' + orderId + '/public/submit?email=';

        if(tempUserEmail != '') {
            url += tempUserEmail + `&deliveryMail=${email}`;
            let publicUser: any = localStorage.getItem('public_user');
            if(publicUser != null) {
                publicUser = JSON.parse(publicUser);
                url += `&shippingFirstName=${publicUser.firstName}&shippingLastName=${publicUser.lastName}`;
            }
        }

        else
          url += email; 
        
        this.requestService.createRequest(HttpRequestType.Post, url, null, null, (response: any) => {
            var index = this.orders.findIndex(el => el.id == orderId);
            this.orders[index].status = 'SUBMITTED';

            callback();
        });
    }
    
    public ordersCheck(onSuccess: Function, onFail: Function): void {
        this.requestService.createRequest(HttpRequestType.Get, this.url, null, null, () => {
            onSuccess();
        }, () => {
            onFail();
        });
    }

    public getOrderById(id: number, onSuccess: Function, onFail: Function = null): void {
        this.requestService.createRequest(HttpRequestType.Get, this.url + '/' + id, null, null, (order: Order) => {
            onSuccess(order);
        }, () => {
            if (onFail != null)
                onFail();
        });
    }

    public getOrderByIdWithLeader(id: number, onSuccess: Function, onFail: Function = null): void {
        this.requestService.createRequest(HttpRequestType.Get, this.url + '/member/' + id, null, null, (order: Order) => {
            onSuccess(order);
        }, () => {
            if (onFail != null)
                onFail();
        });
    }

    public deleteOrder(orderId, callback): void {
        this.requestService.createRequest(HttpRequestType.Delete, this.url + '/' + orderId, null, null, (order: Order) => {
            var index = this.orders.findIndex(el => el.id == orderId);
            this.orders.splice(index, 1);

            callback();
        });
    }
    
    public uploadComplaint(data: Complaint, image: any, onSuccess: Function): void {        
        let formData = new FormData();
        formData.append('body', new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));
        if (image != null && image[0] != null)
            formData.append('image', new Blob([image[0]], {
                type: image[0].type
            }), image[0].name);

        this.requestService.createRequest(HttpRequestType.Post, '/mlm/api/v1/orders/complain', formData, this.authService.getMultipartHeaders(), () => {
            onSuccess();
        });
    }
    
    public getMotivationalItems(orderId: number, onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Get, this.url + '/' + orderId + '/motivational-plan-items', null, null, (motivationalPlanItems: Array<any>) => {
            this.motivationalPlanItems = motivationalPlanItems;            

            onSuccess();
        });
    }

    public addMotivationalPlanItemsToOrder(orderId: number, motivationalPlanItems: Array<any>, motivationalNote: string, onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Post, this.url + '/' + orderId + '/motivational-plan-items', { items: motivationalPlanItems, note: motivationalNote }, null, (order: Order) => {
            onSuccess(order);
        });
    }

    public getMinimalValue(onSuccess: Function = null) {
        if(this.minimalValue)
            onSuccess(this.minimalValue);
        else
            this.requestService.createRequest(HttpRequestType.Get, this.url + '/minimum-value', null, null, (minValue: any) => {
                this.minimalValue = minValue.minValue;
                if(onSuccess)
                    onSuccess(minValue.minValue);
            });
    }
    
    public getAllDeliveryProviders(companyId: number, onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Get, `/mlm/api/v1/companies/${companyId}/delivery-providers/active`, null, null, (deliveryProviders: Array<any>) => {
            onSuccess(deliveryProviders);
        });
    }

    public getAllPaymentType(onSuccess: Function): void {
        if(this.paymentTypes.length != 0)
            onSuccess(this.paymentTypes);
        else
            this.requestService.createRequest(HttpRequestType.Get, this.baseUrl + '/payment-type', null, null, (paymentTypes: Array<any>) => {
                this.paymentTypes = [];

                for (let paymentType of paymentTypes)
                    this.paymentTypes.push(paymentType.sifra.toUpperCase());
                onSuccess();
            });
    }

    public getOrdersByEmail(callback: Function, email: string): void {
        this.requestService.createRequest(HttpRequestType.Get, this.url + '/email?email=' + email, null, null, (orders: Array<Order>) => {
            this.orders = orders;
            if (callback)
                callback();
        }, () => {
            if (callback)
                callback();
        });
    }

    public clearData(): void {
        this.orders = [];
        // this.ordersMergeOptions = [];
        this.paymentTypes = [];
        this.verifyResponse = null;
        this.motivationalPlanItems = null;
        this.minimalValue = 0;
    }
}
