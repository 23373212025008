import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { Order, RebateScaleTranslation } from '../cart.model';
import { AppService } from 'src/app/app.service';
import { CartService } from '../cart.service';
import { Router } from '@angular/router';
import { AlertService, AuthService, ContextService, LoadingService } from 'src/app/core';
import { Product } from '../../shop/shop.models';
import { MemberRegistrationIn } from '../cart.model';
import { BasketService } from 'src/app/shared/basket.service';
import { RegisterService } from 'src/app/outside-layout/register/register.service';
import { ReferralService } from 'src/app/outside-layout/referral/referral.service';
import { Banner, PromoBannerLocationType } from 'src/app/shared/banners/banners.models';
import { TranslateService } from '@ngx-translate/core';
import { filter, find, take } from 'rxjs/operators';
import { ProfileService } from '../../club-layout/profile/profile.service';
import { DialogService } from 'src/app/shared/dialog.service';
import { BannersService, CatalogueArtikal } from 'src/app/shared/banners/banners.service';
import { InputDialogData, SurveyDialogData } from 'src/app/shared/shared.models';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
    selector: 'app-order-preview',
    templateUrl: './order-preview.component.html',
    styleUrls: ['./order-preview.component.scss']
})
export class OrderPreviewComponent implements OnInit {
    @Input('order') order: Order;
    @Input('userPublic') userPublic: any;

    // TODO treba li ovaj closeitemscartevent 
    @Output() closeItemsCartEvent = new EventEmitter();
    @Output() closeOrderPreviewEvent = new EventEmitter();

    public orderDc;
    public orderItems: Product[];
    public modalOpen: boolean = false;
    public acceptContract: boolean = false;
    public displayContract: boolean = false;
    public displayContractModal: boolean = false;
    public showFirstOrderModal: boolean = false;
    public showFirstOrderModalForPublic: boolean = false;
    public accountNumber = "ProcreditBank: 220-0000000139221-14; UnicreditBank: 170-0030023688000-04;";
    public paymentDeadline = 10;
    public referral: any;
    public PromoBannerLocationType = PromoBannerLocationType;
    public rebateMessage: string;
    public company: any;
    public deliveryProviders: any[] = [];
    public showOrderNotice: boolean = false;

    altCurrency: string;
    altCurrencyExchangeRate: number;

    @HostBinding('class') classes = "korpica-modal-x";

    constructor(
        private appService: AppService, 
        private cartService: CartService, 
        private router: Router, 
        private authService: AuthService,
        private registerService: RegisterService, 
        public contextService: ContextService, 
        private basketService: BasketService, 
        private referralService: ReferralService, 
        public loadingService: LoadingService,
        private translateService: TranslateService, 
        private profileService: ProfileService, 
        private dialogService: DialogService, 
        private bannersService: BannersService, 
        private alertService: AlertService,
        private gtmService: GoogleTagManagerService ) { }

    ngOnInit(): void {
        this.company = this.contextService.context.companies[0];
        this.orderDc = this.appService.createDeepCopy(this.order);

        this.orderDc.items.sort((a, b) => a.productBrand.localeCompare(b.productBrand));

        if (window.location.hostname == 'klub.farmasi.rs')
            this.displayContract = true;
        if (!!localStorage.getItem('referral_number'))
            this.referral = JSON.parse(localStorage.getItem('referral_number'))
        this.checkAltCurrency();
        this.prepareDeliveryProviders();

        if(!this.contextService.isPublicMember()) {
            setTimeout(() => {
                this.calculateRebateScale();
            }, 500);
        }
        this.showOrderNotice = this.company.id === 4;
    }

    private prepareDeliveryProviders() {
        this.cartService.getAllDeliveryProviders(this.company.id, (deliveryProviders: Array<any>) => {
            this.deliveryProviders = deliveryProviders;
        });
    }

    public findDeliveryProviderTitle(id: number) {
        let deliveryProvider = this.deliveryProviders.find((dp: any) => dp.id == id);
        return deliveryProvider?.title ?? '';
    }

    public findDeliveryProviderParcelMachineDescription(deliveryProviderId: number, parcelMachineId: number) {
        let deliveryProvider = this.deliveryProviders.find((dp: any) => dp.id == deliveryProviderId);
        if(deliveryProvider && deliveryProvider.parcelMachines && deliveryProvider.parcelMachines.length > 0) {
            let parcelMachine = deliveryProvider.parcelMachines.find((pm: any) => pm.id == parcelMachineId);
            return parcelMachine?.description ?? '';
        }
        return '';
    }

    public submitOrder(): void {

        if(this.contextService.isTempMember() && !!localStorage.getItem('referral_number')) {
            this.referralService.getValidReferral(this.referral.referral_number, (response) => {
                if (response.valid) {
                    this.submitPublicOrder(() => {
                        this.broadcastPurchaseEvent();
                        this.alertService.success('GENERAL.SAVE-SUCCESS');
                        this.router.navigate(['/shop']);
                    }, this.contextService.profile.userProfile.email);
                } else {
                    localStorage.removeItem('referral_number');
                    this.authService.removeAuth();
                }
            }, () => {
                localStorage.removeItem('referral_number');
                this.authService.removeAuth();
            })
        }
        else if (this.contextService.isPublicMember() && !!localStorage.getItem('referral_number')) {
            this.referralService.getValidReferral(this.referral.referral_number, (response) => {
                if (response.valid) {
                    this.registerPublicUserWithDeliveryInformation(this.order, () => {
                        this.submitPublicOrder(() => {
                            this.broadcastPurchaseEvent();
                            this.router.navigate(['public-message']);
                        });
                    });
                } else {
                    localStorage.removeItem('referral_number');
                    this.authService.removeAuth();
                }
            }, () => {
                localStorage.removeItem('referral_number');
                this.authService.removeAuth();
            });
        } else {
            this.submitNewOrder(() => {
                this.broadcastPurchaseEvent();
                this.basketService.clearItems();
                this.router.navigate(['club/my-orders']);
            });
        }
    }

    //GTAG brodcast  
    private broadcastPurchaseEvent(): void {

        if(this.contextService.getCompanyId() !== 1)
            return;

        const gtmTag = {
            event: 'purchase_event',
            orderId: this.order.id,
            paymentType: this.order.paymentType,
            retailValue: this.order.retailValue,
            rebateValue: this.order.rebateValue,
            invoiceValue: this.order.invoiceValue,
            serviceValue: this.order.serviceValue,
            serviceVat: this.order.serviceVAT,
            serviceTotal: this.order.serviceTotal,
            orderTotal: this.order.total,
            items: this.order.items.map((item) => ({
                productId: item.productId,
                productName: item.productName,
                productBrand: item.productBrand,
                quantity: item.quantity,
                retailPrice: item.retailPrice,
                rebatePercent: item.rebatePercent,
                invoicePrice: item.invoicePrice
            }))
        };

        this.gtmService.pushTag(gtmTag);
        console.log('order', gtmTag);
    }

    private submitNewOrder(onSuccess: Function) {
        this.cartService.submitOrder(this.order.id, () => {
            this.cartService.ordersCheck(() => {
                this.closeItemsCartEvent.emit('orderPreview');
                localStorage.removeItem('referral_number');
                onSuccess();
            }, () => {
                this.closeItemsCartEvent.emit('orderPreview');
                this.showFirstOrderModal = true;
                this.authService.removeAuth();
            });
        });
    }


    private submitPublicOrder(onSuccess: Function, tempUserEmail: string = '') {
        this.cartService.submitOrderPublic(this.order.id, this.userPublic.email.trim().toLowerCase(), () => {
            this.cartService.ordersCheck(() => {
                this.closeItemsCartEvent.emit('orderPreview');
                this.showFirstOrderModalForPublic = true;
                onSuccess();
            }, () => {
                this.closeItemsCartEvent.emit('orderPreview');
                this.showFirstOrderModal = true;
                this.authService.removeAuth();
            });
        }, tempUserEmail);
    }

    public acceptContractChanged() {
        this.acceptContract = !this.acceptContract;
    }

    public openContractModal() {
        this.displayContractModal = true;
    }

    private registerPublicUserWithDeliveryInformation(order: any, onSuccess: Function) {
        let user: MemberRegistrationIn = new MemberRegistrationIn();
        user.firstName = this.userPublic.firstName; //name[0];
        user.lastName = this.userPublic.lastName;//name[1] == null ? '':name[1];
        user.address = order.shippingAddress;
        user.addressNo = order.shippingAddressNo;
        if (this.referral != null) {
            if (this.referral.companyId == this.contextService.getCompanyId()) {
                user.companyId = this.referral.companyId;
                user.recommendation = this.referral.referral_number;
            } else {
                user.companyId = this.contextService.getCompanyId();
            }
            user.referral = this.referral.referral_number;
        }
        user.zipCode = order.shippingZip;
        user.place = order.shippingCity;
        user.phoneMobile = order.deliveryContactPhoneNumber;
        user.gender = "OTHER";
        user.type = "PERSON";
        user.email = this.userPublic.email.trim().toLowerCase();
        //TODO defaultLocal za odabir u select box

        user.defaultLocale = this.registerService.currentCompany.defaultLocale;


        this.registerService.registerUser(user, () => {
            onSuccess();
        });
    }

    closeModal() {
        this.closeOrderPreviewEvent.emit('orderPreview');
        window.scrollTo(0, 0);
        if (this.orderDc.status == 'DRAFT' && this.orderDc.campaign == this.contextService.profile.currentCampaignId)
            this.router.navigate(['/cart/edit', this.orderDc.id]);
        // orderDc.status == 'DRAFT' && orderDc.campaign == contextService.profile.currentCampaignId
        // this.basketService.clearItems();
        // TODO ovo moze li da se cleara direktno odvadje ?
    }

    async calculateRebateScale(): Promise<void> {
        let text = '';
        // const rebateFarmasi: RebateScaleTranslation | null = await this.contextService.getRebateMessage(this.orderDc.retailValue, this.profileService.mlmLevelCode);

        if(this.orderDc.items.some(item => item.productBrand === 'FARMASI')) {
            const rebateFarmasi: RebateScaleTranslation | null = await this.contextService.getRebateMessage(this.orderDc.retailValue, this.profileService.mlmLevelCode);
            if(rebateFarmasi) {
                text += this.translateService.instant(
                    (rebateFarmasi.altCurrency && rebateFarmasi.altCurrencyExchangeRate) ? 
                    'SHOP.REBATE_ORDER_ALT_FARMASI' : 
                    'SHOP.REBATE_ORDER_FARMASI', 
                    rebateFarmasi
                );
            }
        }

        if(this.orderDc.items.some(item => item.productBrand === 'NUTRIPLUS')) {
            let nutriplusTotal = 0;
            this.orderDc.items
                        .forEach(item => {
                            if(item.productBrand === 'NUTRIPLUS') 
                                nutriplusTotal += item.retailPrice * item.approvedQuantity;
                        });


            const nutriplusRebate: RebateScaleTranslation | null = await this.contextService.getRebateMessage(nutriplusTotal, this.profileService.mlmLevelCode, "NUTRIPLUS");

            if(nutriplusRebate) {
                if(text !== '')
                  text += ' ';

                text += this.translateService.instant(
                    (nutriplusRebate.altCurrency && nutriplusRebate.altCurrencyExchangeRate) ? 
                    'SHOP.REBATE_ORDER_ALT_NUTRIPLUS' : 
                    'SHOP.REBATE_ORDER_NUTRIPLUS', 
                    nutriplusRebate
                );
            }
        }

        if(text !== '') {
            setTimeout(() => {
                this.dialogService.OpenModal('', text, () => this.closeModal(), 'SHOP.REBATE_BUTTON_CONFIRM', 'MODAL.CLOSE');
            }, 500);

        }
        // if (rebate)
        //     setTimeout(() => {
        //         this.translateService
        //             .get((rebate.altCurrency && rebate.altCurrencyExchangeRate) ? 'SHOP.REBATE_ORDER_ALT' : 'SHOP.REBATE_ORDER', rebate)
        //             .pipe(take(1))
        //             .subscribe(
        //                 (text: string) => this.dialogService.OpenModal('', text, () => this.closeModal(), 'SHOP.REBATE_BUTTON_CONFIRM', 'MODAL.CLOSE')
        //             );
        //     }, 1000);
    }

    checkAltCurrency() {
        this.contextService.getCompanyAltCurrency((altCurr: { companyId: string, altCurrency: string }) => {
            if (altCurr.altCurrency) {
                this.altCurrency = altCurr.altCurrency;

                if (this.altCurrency)
                    this.contextService.getCompanyAltCurrencyExchangeRate((altCurrExchangeRate: { companyId: string, altCurrencyExchangeRate: number }) => {
                        if (altCurrExchangeRate.altCurrencyExchangeRate)
                            this.altCurrencyExchangeRate = altCurrExchangeRate.altCurrencyExchangeRate;
                    })
            }
        })
    }
}