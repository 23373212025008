import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private app: any;

  constructor() {
      this.app = {
          owner: 'Farmasi',
          year: ((new Date()).getFullYear())
      };
  }
  
  public getAppSetting(name: string): any {
      return name ? this.app[name] : this.app;
  }
}
