<div class="wrapper" class="resetBg">
    <div class="block-center mt-xl wd-xl">
        <div class="container-fluid pt-login-wrapper">
            <div class="row fix-img-place">
                <div class="col-md-6 pt-login-left"> <img src="../../../assets/logo-white.svg" /> </div>
                <div class="col-md-6 col-xs-12 pt-login-right">
                    <img class="login-logo-right" src="../../../assets/logo-header.svg" />
                    <h4 class="text-black">{{ 'REGISTRATION.COMPLETE-REGISTRATION' | translate }}</h4>
                    <form novalidate [formGroup]="activateForm">
                        <input id="setPassword" class="form-control" type="password" [placeholder]="'REGISTRATION.PASSWORD' | translate" [formControl]="activateForm.controls['password']" maxlength="40">
                        <span class="fa fa-lock form-control-feedback text-muted"></span>
                        <span *ngIf="activateForm.controls['password'].hasError('required') && activateForm.controls['password'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                        <!-- <span *ngIf="!activateForm.controls['password'].hasError('required') && activateForm.controls['password'].hasError('minlength')" class="error">{{ 'VALIDATION.SHORT-INPUT' | translate }}</span> -->
                        <span *ngIf="!activateForm.controls['password'].hasError('required') && !activateForm.controls['password'].hasError('minlength') && activateForm.controls['password'].hasError('pattern')" class="error">{{ 'VALIDATION.PASSWORD' | translate }}</span>
                        <input id="setPasswordConfirm" class="form-control" type="password" [placeholder]="'REGISTRATION.PASSWORD-CONFIRMATION' | translate" [formControl]="activateForm.controls['confirmPassword']" maxlength="40">
                        <span class="fa fa-lock form-control-feedback text-muted"></span>
                        <span *ngIf="!activateForm.controls['password'].hasError('required') && !activateForm.controls['password'].hasError('minlength') && !activateForm.controls['password'].hasError('pattern') && activateForm.controls['confirmPassword'].hasError('equalTo')" class="error">{{ 'VALIDATION.PASSWORD-CONFIRMATION' | translate }}</span>
                        
                        <div class="pt-login-buttons">
                            <button (click)='submitForm()' [disabled]="activateForm.hasError('notSame') || activateForm.controls['password'].hasError('pattern') || activateForm.controls['password'].hasError('required') || loadingService.unfinishedRequests" class="pt-login-prijavise outline-none disable-cursor">
                                {{ 'MODAL.SUBMIT' | translate }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
