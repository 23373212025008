
<div *ngIf="companyId == 1" class="bh-modal custom-modal animate__slideInLeft animate__animated animate__faster">
    <div class="modal-header">
        <h3 class="modal-title text-center">Korisnički račun je nakon tri mjeseca neaktivnosti ugašen</h3>
    </div>
    <div class="modal-body">
            Poštovani/a, <br><br>
            Vaš korisnički račun je nakon tri mjeseca neaktivnosti ugašen. Za reaktivaciju korisničkog računa, molimo uputite zahtjev na e-mail: <br>
            <a class="text-bold text-danger" href="mailto:info@farmasi.ba">info@farmasi.ba</a>
            <br><br>
            
            Potrebno je da navedete članski broj i opcionalno  ime i prezime osobe koja Vam je preporučila članstvo (lidera) kako bismo reaktivirali nalog. <br><br>
            
            Iskreno zahvaljujemo na ukazanom povjerenju!<br><br>
            
            FARMASI BH        
    </div>
    <div class="modal-footer">
        <a class="btn btn-default" (click)="closeDisabledUserModalEvent.emit($event)">{{ 'MODAL.CLOSE' | translate }}</a>
    </div>
</div>
<div *ngIf="companyId == 3" class="hr-modal custom-modal animate__slideInLeft animate__animated animate__faster">
    <div class="modal-header">
        <h3 class="modal-title text-center">Korisnički račun je nakon šest mjeseci neaktivnosti ugašen</h3>
    </div>
    <div class="modal-body">
            Poštovani/a, <br><br>
            Vaš korisnički račun je nakon šest mjeseci neaktivnosti ugašen. Za reaktivaciju korisničkog računa, molimo uputite zahtjev na e-mail: <br>
            <a class="text-bold text-danger" href="mailto:info@farmasi.hr">info@farmasi.hr</a>
            <br><br>
            
            Potrebno je navesti članski broj i opcionalno  ime i prezime osobe koja Vam je preporučila članstvo (lidera) kako bismo reaktivirali korisnički račun.<br><br>
            
            Iskreno zahvaljujemo na ukazanom povjerenju!<br><br>
            
            Farmasi Cosmetics d.o.o.  
    </div>
    <div class="modal-footer">
        <a class="btn btn-default" (click)="closeDisabledUserModalEvent.emit($event)">{{ 'MODAL.CLOSE' | translate }}</a>
    </div>
</div>    
<div *ngIf="companyId == 2" class="sr-modal custom-modal animate__slideInLeft animate__animated animate__faster">
    <div class="modal-header">
        <h3 class="modal-title text-center">Korisnički račun je nakon šest meseci neaktivnosti ugašen</h3>
    </div>
    <div class="modal-body">
            Poštovani/a, <br><br>
            Vaš korisnički račun je nakon šest meseci neaktivnosti ugašen. Za reaktivaciju korisničkog računa, molimo uputite zahtev na e-mail: <br>
            <a class="text-bold text-danger" href="mailto:info@farmasi.rs">info@farmasi.rs</a>
            <br><br>
            
            Potrebno je da navedete članski broj i opciono ime i prezime osobe koja Vam je preporučila članstvo (lidera) kako bismo reaktivirali nalog. <br><br>
            
            Iskreno zahvaljujemo na ukazanom povjerenju!<br><br>
            
            FARMASI Srbija
    </div>
    <div class="modal-footer">
        <a class="btn btn-default" (click)="closeDisabledUserModalEvent.emit($event)">{{ 'MODAL.CLOSE' | translate }}</a>
    </div>
</div>
<div *ngIf="companyId == 5" class="mk-modal custom-modal animate__slideInLeft animate__animated animate__faster">
    <div class="modal-header">
        <h3 class="modal-title text-center">Сметката е затворена по три месеци неактивност</h3>
    </div>
    <div class="modal-body">
            Почитуван/а, <br><br>
            Вашиот кориснички профил по три месеци неактивност е изгаснат. За реактивација на корисничкиот профил, Ве молиме да испратите барање на нашиот e-mail: <br>
            <a class="text-bold text-danger" href="mailto:info@farmasi.mk">info@farmasi.mk</a>
            <br><br>
            
            Потребно е да го наведете Вашиот членски број и опционалното име и презиме на личноста која Ви препорачала членство (лидер) за да го реактивираме Вашиот налог. <br><br>
            
            Искрено Ви благодариме на укажаната доверба! <br><br>
            
            Farmasi Козметика Дооел
    </div>
    <div class="modal-footer">
        <a class="btn btn-default" (click)="closeDisabledUserModalEvent.emit($event)">{{ 'MODAL.CLOSE' | translate }}</a>
    </div>
</div>
<div *ngIf="companyId == 6" class="me-modal custom-modal animate__slideInLeft animate__animated animate__faster">
    <div class="modal-header">
        <h3 class="modal-title text-center">Korisnički račun je nakon tri mjeseca neaktivnosti ugašen</h3>
    </div>
    <div class="modal-body">
            Poštovani/a, <br><br>
            Vaš korisnički račun je nakon tri mjeseca neaktivnosti ugašen. Za reaktivaciju korisničkog računa, molimo uputite zahtjev na e-mail: <br>
            <a class="text-bold text-danger" href="mailto:info@farmasi.co.me">info@farmasi.co.me</a>
            <br><br>
            
            Potrebno je da navedete članski broj i opciono ime i prezime osobe koja Vam je preporučila članstvo (lidera) kako bismo reaktivirali nalog. <br><br>
            
            Iskreno zahvaljujemo na ukazanom povjerenju!<br><br>
            
            FARMASI Montenegro
    </div>
    <div class="modal-footer">
        <a class="btn btn-default" (click)="closeDisabledUserModalEvent.emit($event)">{{ 'MODAL.CLOSE' | translate }}</a>
    </div>
</div>
<div *ngIf="companyId == 12" class="al-modal custom-modal animate__slideInLeft animate__animated animate__faster">
    <div class="modal-header">
        <h3 class="modal-title text-center">Llogaria është mbyllur pas tre muajve pasivitetit</h3>
    </div>
    <div class="modal-body">
            Të nderuar, <br><br>
            Llogaria juaj është mbyllur për shkak se keni patur tre muaj pa bërë porosi. Për të riaktivizuar lloagarinë tuaj, ju lutem dërgoni një kërkesë në emailin <br>
            <a class="text-bold text-danger" href="mailto:info@farmasi.al">info@farmasi.al</a>
            <br><br>
            
            Ju duhet të shënoni numrin e anëtarit dhe nëse është e mundur dhe emrin e mbiemrin e personit që ju ka rekomanduar për tu anëtarësuar ( liderin) në mënyrë që ne t'ju riaktivizojmë llogarinë. <br><br>
            
             Ju falenderojmë për besimin tuaj! <br><br>
            
            FARMASI ALBANIA
    </div>
    <div class="modal-footer">
        <a class="btn btn-default" (click)="closeDisabledUserModalEvent.emit($event)">{{ 'MODAL.CLOSE' | translate }}</a>
    </div>
</div>
<div *ngIf="companyId == 13" class="kv-modal custom-modal animate__slideInLeft animate__animated animate__faster">
    <div class="modal-header">
        <h3 class="modal-title text-center">Llogaria është mbyllur pas tre muajve pasivitetit</h3>
    </div>
    <div class="modal-body">
            Të nderuar, <br><br>
            Llogaria juaj është mbyllur për shkak se keni patur tre muaj pa bërë porosi. Për të riaktivizuar lloagarinë tuaj, ju lutem dërgoni një kërkesë në emailin <br>
            <a class="text-bold text-danger" href="mailto:info@farmasi.com.al">info@farmasi.com.al</a>
            <br><br>
            
            Ju duhet të shënoni numrin e anëtarit dhe nëse është e mundur dhe emrin e mbiemrin e personit që ju ka rekomanduar për tu anëtarësuar ( liderin) në mënyrë që ne t'ju riaktivizojmë llogarinë. <br><br>
            
             Ju falenderojmë për besimin tuaj! <br><br>
            
            FARMASI KOSOVO
    </div>
    <div class="modal-footer">
        <a class="btn btn-default" (click)="closeDisabledUserModalEvent.emit($event)">{{ 'MODAL.CLOSE' | translate }}</a>
    </div>
</div>
<div *ngIf="companyId == 4" class="slo-modal custom-modal animate__slideInLeft animate__animated animate__faster">
    <div class="modal-header">
        <h3 class="modal-title text-center">Uporabniški račun je bil zaprt po dvanajstih mesecih neaktivnosti</h3>
    </div>
    <div class="modal-body">
            Spoštovani,  <br><br>
            Vaš račun je bil deaktiviran po dvanajstih mesecih nedejavnosti. Za ponovno reaktivacijo vašega uporabniškega računa, vas prosimo da posljete zahtevo na <br>
            <a class="text-bold text-danger" href="mailto:info@farmasi.si">info@farmasi.si</a>
            <br><br>
            Ob tem je potrebno navesti vašo člansko številko ter ime in priimek osebe, ki vam je priporočila članstvo (liderja). Tako vam lahko reaktiviramo uporabniški račun <br><br>
            
            Iskreno se vam zahvaljujemo na zaupanju!  <br><br>
            
            FARMASI TIM SLO
    </div>
    <div class="modal-footer">
        <a class="btn btn-default" (click)="closeDisabledUserModalEvent.emit($event)">{{ 'MODAL.CLOSE' | translate }}</a>
    </div>
</div>
<div (click)="closeDisabledUserModalEvent.emit($event)" class="close-overlay"></div>