import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AlertService, LoadingService } from 'src/app/core';
import { SettingsService } from 'src/app/core/settings.service';
import { RegisterService } from 'src/app/outside-layout/register/register.service';

@Component({
    selector: 'app-activate',
    templateUrl: './activate.component.html',
    styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {
    public activateForm: FormGroup;

    private mode: any;

    constructor(private alertService: AlertService, private router: Router, public route: ActivatedRoute, public formBuilder: FormBuilder,
        public appService: AppService, public settings: SettingsService, public registerService: RegisterService, public loadingService: LoadingService) {

        this.activateForm = formBuilder.group({
            'password': ['', [Validators.required, Validators.pattern('^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9]{6,}$')]],
            'confirmPassword': [null],
            'token': [null, Validators.required]
        }, { validator: this.checkPasswords });
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.mode = params['token'];

            if (this.mode != null)
                this.activateForm.controls['token'].setValue(this.mode);
        });
    }

    checkPasswords(group: FormGroup) {
        let pass = group.get('password').value;
        let confirmPass = group.get('confirmPassword').value;
        return pass === confirmPass ? null : { notSame: true }
    }

    ngAfterViewInit() {
        this.appService.focusElement('password');
    }

    submitForm() {
        this.registerService.activate(this.activateForm.value, () => {
            this.alertService.success('REGISTRATION.PASSWORD-SET');
            this.router.navigate(['/login']);
        }, () => {
            this.alertService.error('ERRORS.REGISTRATION-FAILED');
        });
    }

}
