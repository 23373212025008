import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService, ContextService } from '../app/core';

@Injectable()
export class InsideGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate() {
        if (!this.authService.isTokenInStorage) {            
            this.router.navigate(['login']);
            return false;
        }
        return true;
    }
}

@Injectable()
export class OutsideGuard implements CanActivate {
    constructor(private authService: AuthService, private contextService: ContextService) { }

    canActivate(route: ActivatedRouteSnapshot) {
        //console.log("Outside Guard");
        if (route.data.performRedirection != null && !route.data.performRedirection)
            this.authService.removeAuth();
        
        if (this.authService.isTokenInStorage && !this.contextService.isPublicMember()) {
            this.authService.performRedirection();

            return false;
        }

        return true;
    }
}

@Injectable()
export class RoleGuard implements CanActivate {
    constructor(private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot) {
        //console.log("Role Guard");
        if (!route.data.authorities || this.authService.hasAnyAuthority(...route.data.authorities))
            return true;

        this.authService.performRedirection();

        return false;
    }
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    canDeactivate(component: any): boolean | Observable<boolean> {
        return component.canDeactivate() ? true : component.pendingChangesDialog;
    }
}

@Injectable()
export class UserGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) { }

    canActivate() {
        //console.log("User Guard");
        if (this.authService.isTokenInStorage) { 
            if (!this.authService.checkAuthority('USER')) {
                this.router.navigate(['']);
                return false;
            }
        }
        return true; 
    }
}

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}
