import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, ExtraOptions, PreloadAllModules } from '@angular/router';

import { AppComponent } from './app.component';
import { LoginComponent } from './outside-layout/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { InsideLayoutModule } from './inside-layout/inside-layout.module';
import { NotFoundComponent } from './outside-layout/not-found/not-found.component';

import { Routes } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OutsideGuard, InsideGuard, PendingChangesGuard } from './guards';
import { RegisterComponent } from './outside-layout/register/register.component';
import { SharedModule } from './shared/shared.module';
import { ReferralComponent } from './outside-layout/referral/referral.component';
import { ReferralPublicComponent } from './outside-layout/referral-public/referral-public.component';
import { PublicOrderMessageComponent } from './outside-layout/public-order-message/public-order-message.component';
import { ResetPasswordComponent } from './outside-layout/reset-password/reset-password.component';
import { ResetPasswordMailComponent } from './outside-layout/reset-password/reset-password-mail/reset-password-mail.component';
import { ActivateComponent } from './outside-layout/activate/activate.component';
import { ActivateMComponent } from './outside-layout/activate-m/activate-m.component';
import { DisabledUserComponent } from './outside-layout/login/disabled-user/disabled-user.component';
import { EmailChangeComponent } from './outside-layout/email-change/email-change.component';
import { MaintenanceComponent } from './outside-layout/maintenance/maintenance.component';
import { MailingUnsubscribeComponent } from './outside-layout/mailing-unsubscribe/mailing-unsubscribe.component';
import { CustomPreloadingStrategy } from './customPreloadingStrategy';
import { environment } from '../environments/environment';


export const AppRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [OutsideGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [OutsideGuard]
    },
    {
        path: 'register/:token',
        component: RegisterComponent,
        canActivate: [OutsideGuard]
    },
    {
        path: 'referral',
        component: ReferralComponent,
        canActivate: [OutsideGuard]
    },
    {
        path: 'referral/:memberNumber',
        component: ReferralComponent,
        canActivate: [OutsideGuard]
    },
    {
        path: 'referral-public/:memberNumber',
        component: ReferralPublicComponent,
        canActivate: [OutsideGuard]
    },
    {
        path: 'public-message',
        component: PublicOrderMessageComponent
    },
    {
        path: 'activate/:token',
        component: ActivateComponent,
        canActivate: [OutsideGuard]
    },
    {
        path: 'activate-m/:token',
        component: ActivateMComponent,
        canActivate: [OutsideGuard]
    },
    {
        path: 'password-reset',
        component: ResetPasswordMailComponent,
        canActivate: [OutsideGuard]
    },
    {
        path: 'password-reset/:token',
        component: ResetPasswordComponent,
        canActivate: [OutsideGuard]
    },
    {
        path: 'email-change/:token',
        component: EmailChangeComponent,
        canActivate: [OutsideGuard]
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent,
        canActivate: [OutsideGuard]
    },
    {
        path: 'mailing-lists/:id/unsubscribe',
        component: MailingUnsubscribeComponent,
    },
    {
        path: '',
        loadChildren: () => import('./inside-layout/inside-layout.module').then(m => m.InsideLayoutModule)
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NotFoundComponent,
        RegisterComponent,
        ReferralComponent,
        ReferralPublicComponent,
        PublicOrderMessageComponent,
        ActivateComponent,
        ResetPasswordComponent,
        ResetPasswordMailComponent,
        ActivateMComponent,
        DisabledUserComponent,
        EmailChangeComponent,
        MaintenanceComponent,
        MailingUnsubscribeComponent
    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot(AppRoutes, 
            {
                preloadingStrategy: CustomPreloadingStrategy
            }),
        BrowserAnimationsModule,
        FormsModule, 
        ReactiveFormsModule,
        InsideLayoutModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            isolate: false
        }),
        ToastrModule.forRoot({
            maxOpened: 5     
        }),
        SharedModule
    ],
    providers: [
        InsideGuard,
        OutsideGuard,
        PendingChangesGuard,
        CustomPreloadingStrategy,
        {provide: 'googleTagManagerId',  useValue: environment.gTagsId }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
