import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/core';
import { RegisterService } from '../register/register.service';

@Component({
  selector: 'app-email-change',
  templateUrl: './email-change.component.html',
  styleUrls: ['./email-change.component.scss']
})
export class EmailChangeComponent implements OnInit {

  constructor(private alertService: AlertService, private registerService: RegisterService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
        this.registerService.changeEmail(params['token'], () => {
            this.alertService.success('EMAIL-CHANGE.SUCCESS');
            this.router.navigate(['login']); 
        }, () => {
            this.alertService.error('EMAIL-CHANGE.FAIL');
            this.router.navigate(['login']); 
        });
    });
  }

}
