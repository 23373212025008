import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderCartItemsComponent } from './header/header-cart-items/header-cart-items.component';
  
const Components = [
    LayoutComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    HeaderCartItemsComponent    
]

@NgModule({
    declarations: [Components],
    imports: [
        SharedModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        Components
    ]
})
export class InsideLayoutModule { }
