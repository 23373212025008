<div class="custom-modal">
    <div class="modal-header">
        <h3 class="modal-title">{{ 'FIRST-ORDER.TITLE' | translate }}</h3>
    </div>
    <div class="modal-body">
        <span>{{ 'FIRST-ORDER.M1' | translate }}</span>
        <br />
        <span>{{ 'FIRST-ORDER.M2' | translate }}</span>
        <br />
        <span><i>{{ 'FIRST-ORDER.M3' | translate }}</i></span>
    </div>
    <div class="modal-footer">
        <a class="btn btn-default" (click)="closeAndRedirect($event)">{{ 'MODAL.CLOSE' | translate }}</a>
    </div>
</div>
<div class="close-overlay" (click)="closeAndRedirect($event)"></div>
