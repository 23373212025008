import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


declare let $;

@Injectable({
    providedIn: 'root'
})
export class AppService {
    private createdRequests: number = 0;
    private finishedRequests: number = 0;
    public requestsOnWait: boolean = false;

    constructor(public router: Router) {}

    // public showLoader(): void {
    //     this.createdRequests++;
    //     this.requestsOnWait = true;
    // }

    // public hideLoader(): void {
    //     if (++this.finishedRequests == this.createdRequests)
    //         this.requestsOnWait = false;
    // }

    public round(value: number, numberOfDecimals: number = 2): string {
        return Number(value).toFixed(numberOfDecimals);
    }

    public createDeepCopy(object: any): any {
        return $.extend(true, {}, object);
    }

    public focusElement(elementId: string): void {
        setTimeout(() => {
            $(`#${elementId}`).focus();
        }, 100);
    }
    
    formatDateTime(date: any){
        if(date == null)
            return '';
        date = new Date(date);

        let hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
        let min = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

        return `${date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()} ${hours}:${min}`;
    }

    public formatDateToISO(date: any): string {
        if (date == null || date == '')
            return '';

        if (typeof date == 'string')
            return date.substr(6, 4) + '-' + date.substr(3, 2) + '-' + date.substr(0, 2);

        return date.toISOString();
    }

    public isOdd(number: number){
        return Math.abs(number % 2) == 1;
    }

    public formatDate(date: Date): string {
        if(date == null)
            return '';
        date = new Date(date);
        return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
    }

    // ako treba formatiranje datuma to je u utility servisu

    public isEmpty(obj: any): boolean {
        return (obj == null || Object.getOwnPropertyNames(obj).length === 0);
    }

    public getExtension(fileName: string): string {
        let extension = "";
        let j = fileName.length - 1;

        for (let i; i >= 0; i--)
            if (fileName[i] == ".") break;

        return fileName.substr(j + 1, fileName.length - 1 - j);
    }
    
    public getNumberOfProperties(obj: any): number {
        return Object.getOwnPropertyNames(obj).length;
    }

    public navigateTo(route: string): void {
        this.router.navigate([route]);
    }
}
