import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ContextService } from 'src/app/core';

@Component({
    selector: 'app-disabled-user',
    templateUrl: './disabled-user.component.html',
    styleUrls: ['./disabled-user.component.scss']
})
export class DisabledUserComponent implements OnInit {
    @Output() closeDisabledUserModalEvent = new EventEmitter();
    companyId: number = null;

    constructor(private contextService: ContextService) { }

    ngOnInit(): void {
        this.companyId = this.contextService.getCompanyId();   
    }

}
