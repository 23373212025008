<div class="first-header">
    <span class="d-sm-none logo-mobile">
        <a routerLink="/">
            <img src="../../../assets/logo-header.svg" alt="" class="shortAnim height-100p">
        </a>
    </span>

    <div class="d-inline pull-left p-sm-2">
        <span class="fs-14 fs-lg-10 text-gray ml-3 d-none d-sm-inline">
            <span class="noselect">
                {{ 'DASHBOARD.CONTACT_US' | translate }}:
            </span>
            <a class="text-bolderr"
                href="tel:{{companyInfo?.contacts?.phoneNumber}}">{{companyInfo?.contacts?.phoneNumber}}</a>
        </span>
        <div class="d-none d-lg-inline">
            <!-- web view, dole je mobile  -->
            <span *ngIf="!contextService.isPublicMember() && referralLoaded && showReferalLink" class="fs-14 noselect fs-lg-10 text-gray ml-3">
                {{ "REFERRAL.COPY" | translate }}
                <a (click)="copyReferalLink()" class="clickable hover-pink">
                    <span class="icon-span ml-2"><img src="../../../../../assets/icons/sharethis.svg"></span>
                </a>
            </span>

            <span *ngIf="referralLoaded && !showReferalLink && !this.contextService.isPublicMember()"
                class="fs-14 fs-lg-10 noselect text-gray ml-3">
                {{ "REFERRAL.NOT-QUALIFIED" | translate }}
            </span>
        </div>
    </div>
    <div class="d-none d-lg-inline pull-right p-2">
        <span *ngIf="!contextService.isTempMember()" 
              (click)='logout()'
              class="clickable hover-pink shortAnim text-lowercase mr-3">
              {{ "HEADER.LOG-OUT" | translate }}
        </span>

        <a *ngIf="contextService.isTempMember()" routerLink="/register" class="clickable hover-pink shortAnim text-lowercase mr-3">
            {{ 'LOGIN.REGISTERNOW' | translate }}
        </a>

        <a target="_blank" href="https://www.facebook.com/{{companyInfo?.contacts?.fb}}">
            <app-icons [icon]="'fb'"></app-icons>
        </a>
        <a target="_blank" href="https://www.instagram.com/{{companyInfo?.contacts?.ig}}">
            <app-icons [icon]="'ig'"></app-icons>
        </a>
    </div>
</div>
<div style="clear:both;"></div>

<header class="second-header border-top"
        [ngClass]="{'scrolled animate__slideInDown animate__animated': scrolledContent, 'styleForScrolled' : prepareHeaderStyle }">

    <nav class="navbar-expand noselect z-index-100 shortAnim">
        <div class="collapse navbar-collapse" id="navbarNav">
            <app-icons (click)="showSidebar = true" class="clickable d-inline d-lg-none p-2"
                [icon]="'toggler'"></app-icons>

            <a routerLink="/" class="nav-brand-logo ml-lg-3 d-none d-sm-inline">
                <img src="../../../assets/logo-header.svg" alt="" class="shortAnim">
            </a>

            <ul class="navbar-nav d-none d-lg-flex shortAnim">
                <li class="nav-item">
                    <a routerLink="/" routerLinkActive="active-router" [routerLinkActiveOptions]="{ exact: true }"
                       class="nav-link shortAnim py-0 clickable"
                    > 
                         {{ !contextService.isPublicMember() ? ('MENU.HOMEPAGE' | translate) : ('MENU.SHOP' | translate)}}
                    </a>
                </li>
                <li class="nav-item">
                    <!-- (click)="basketService.emptyCartIfEdit() Why was this here?--> 
                    <a [routerLink]="'/shop'" routerLinkActive="active-router"
                       class="nav-link shortAnim py-0 clickable"
                    >
                        {{ !contextService.isPublicMember() ? ('MENU.SHOP' | translate) : ('MENU.ARTICLES' | translate)}}
                    </a>
                </li>
                <li *ngIf="!this.contextService.isPublicMember()" class="nav-item">
                    <a routerLink="/club" routerLinkActive="active-router" class="nav-link shortAnim py-0 clickable">
                        {{ 'MENU.MY_CLUB' | translate }}
                    </a>
                </li>
                <li *ngIf="showFlipbook" class="nav-item">
                    <a id="catalogue" routerLink="/catalogues/preview" routerLinkActive="active-router" 
                       class="nav-link shortAnim py-0 clickable"
                    >
                       {{ 'MENU.CATALOGUE_ADD' | translate }}
                    </a>
                </li>
                <li *ngIf="showBannerFlipbook" class="nav-item">
                    <a id="catalogue" routerLink="/catalogues/special-offers" routerLinkActive="active-router" 
                       class="nav-link shortAnim py-0 clickable"  
                    >
                        {{ 'MENU.CLICKABLE_BANNER' | translate }}
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto">
                <!-- Used to be near all icons from right side, now ther request to remove it -->
                <!-- <div [ngClass]="{'public-member': contextService.isPublicMember()}" class="input-wrap condition-input d-none">
                        <mat-form-field color="warn" class="header-search mat-input-search d-inline-block max-width">
                            <input id="searchInput" type="search" [(ngModel)]="searchInput" (ngModelChange)="changedInput()" #input matInput [placeholder]="'SHOP.SEARCH_MORE' | translate ">
                        </mat-form-field>
                    </div> -->
                <li class="nav-item">
                    <a mat-icon-button (click)="showSearchInput = true; appService.focusElement('searchInput')"
                        class="clickable hover-pink">
                        <app-icons [icon]="'search'"></app-icons>
                    </a>
                </li>
                <!-- <li class="nav-item d-none d-lg-inline">
                        <a mat-icon-button class="clickable hover-pink">
                            <app-icons [icon]="'wishlist'"></app-icons>
                        </a>
                    </li> -->
                <li (mouseenter)="forceShowCartItems()" class="nav-item hover-to-open">
                    <div [ngClass]="{'visible': cartItems?.length > 0, 'public-member' : contextService.isPublicMember()}"
                        class="d-inline-block text-center shortAnim opacity0 text-bolder cart-items">
                        {{cartItems?.length}}</div>
                    <span *ngIf="router.url.includes('shop') || router.url == '/'">
                        <app-header-cart-items [ngClass]="{'height-auto': showCartItems > 0}" class="header-cart-items"
                            [cartItems]="cartItems" [mode]="mode" [minimalValue]='minimalValue'
                            [localCurr]='contextService.localCurr' (closeItemsCartEvent)="closeItemsCart($event)"
                            [orderTotal]="orderTotal" [altCurrency]="altCurrency"
                            [altCurrencyExchangeRate]="altCurrencyExchangeRate"></app-header-cart-items>
                    </span>
                    <a *ngIf="!mode" [routerLink]="['cart']" mat-icon-button class="clickable hover-pink">
                        <app-icons [icon]="'cart_1'"></app-icons>
                    </a>
                    <a *ngIf="mode" [routerLink]="['cart/edit', mode]" mat-icon-button class="clickable hover-pink">
                        <app-icons [icon]="'cart_1'"></app-icons>
                    </a>
                </li>
                <li *ngIf="!contextService.isPublicMember()" class="nav-item">
                    <!-- Loyalty program only for BiH, removed 14.03.2023. -->
                    <!-- <app-loyalty-info-dropdown *ngIf="contextService.getCompanyId() === 1"></app-loyalty-info-dropdown> -->
                    <a [routerLink]="['club/profile']" mat-icon-button class="clickable hover-pink">
                        <app-icons [icon]="'profile'"></app-icons>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</header>

<div [ngClass]="{'show-search animate__backInDown animate__animated': showSearchInput }" class="search-dropdown">
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-10 mx-auto">
                <div class="search-info">
                    <a (click)="showSearchInput = false" class="clickable close">
                        <img class="pull-right mr-3" src="../../../assets/icons/close.svg">
                    </a>
                </div>
                <div class="input-wrap">
                    <mat-form-field class="header-search mat-input-search d-inline-block">
                        <input id="searchInput" type="search" [(ngModel)]="searchInput" (ngModelChange)="changedInput()"
                            #input matInput [placeholder]="'SHOP.SEARCH' | translate">
                    </mat-form-field>
                    <a mat-icon-button (click)="changedInput()" class="ml-2 clickable d-inline-block">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Mobile main sidebar -->
<div [ngClass]="showSidebar ? 'showSidebar' : ''" class="animate__animated animate__slideInLeft animate__faster d-none">
    <!-- [ngClass]="condition1 ? 'class1' : 'class2' " -->
    <app-icons (click)="showSidebar = false" class="d-lg-none d-inline-block my-3 p-1" [icon]="'close'"></app-icons>
    <div class="text-black text-bolder fs-20 mb-5 border-bottom">
        <ul (click)="showSidebar = false" class="list-unstyled mb-5">
            
            <li class="nav-item">
                <a class="nav-link shortAnim text-black text-uppercase py-2 clickable"
                   [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active-router" routerLink="/">

                    {{ !contextService.isPublicMember() ? ('MENU.HOMEPAGE' | translate) : ('MENU.SHOP' | translate)}}
                </a>
            </li>
            <li class="nav-item">
                <a routerLink="/shop" routerLinkActive="active-router" 
                   class="nav-link shortAnim text-black text-uppercase py-2 clickable" 
                >
                    {{ !contextService.isPublicMember() ? ('MENU.SHOP' | translate) : ('MENU.ARTICLES' | translate)}}
                </a>
            </li>
            <li *ngIf="!contextService.isPublicMember()" class="nav-item">
                <a routerLink="/club" routerLinkActive="active-router" 
                   class="nav-link shortAnim text-black text-uppercase py-2 clickable" 
                >
                   {{ 'MENU.MY_CLUB' | translate }}
                </a>
            </li>
            <!-- <li *ngIf="!contextService.isTempMember()" class="nav-item">
                <a routerLink="/catalogues" routerLinkActive="active-router" 
                   class="nav-link shortAnim text-black text-uppercase py-2 clickable"
                >
                    {{ 'MENU.CATALOGUE' | translate }}
                </a>
            </li> -->
            <li *ngIf="showFlipbook" class="nav-item">
                <a routerLink="/catalogues/preview" routerLinkActive="active-router" 
                   class="nav-link shortAnim text-black text-uppercase py-2 clickable" 
                >
                    {{ 'MENU.CATALOGUE_ADD' | translate }}
                </a>
            </li>

            <li *ngIf="showBannerFlipbook" class="nav-item">
                <a routerLink="/catalogues/special-offers" routerLinkActive="active-router" 
                   class="nav-link shortAnim text-black text-uppercase py-2 clickable" 
                >
                    {{ 'MENU.CLICKABLE_BANNER' | translate }}
                </a>
            </li>
           
            <!-- <li class="nav-item">
                <a class="nav-link shortAnim text-black text-uppercase py-2 clickable" routerLinkActive="active-router" routerLink="/news">{{ 'MENU.NEWS' | translate }}</a>
            </li> -->
            <li *ngIf="contextService.isTempMember()" class="nav-item mt-5">
                <a routerLink="/register" class="nav-link shortAnim text-black text-uppercase py-2 clickable" 
                   routerLinkActive="active-router"> {{ 'LOGIN.REGISTERNOW' | translate }}</a>
            </li>
            
            <li *ngIf="!contextService.isTempMember()" class="nav-item" [ngClass]="{'mt-5': !contextService.isTempMember()}">
                <a class="nav-link shortAnim text-black text-uppercase py-2 clickable" (click)='logout()'
                    routerLinkActive="active-router">{{ "HEADER.LOG-OUT" | translate }}</a>
            </li>
        </ul>
    </div>

    <div class="d-flex flex-column d-lg-none align-items-center p-2">
        <!-- mobile view -->
        <span *ngIf="showReferalLink && !this.contextService.isPublicMember()" class="fs-14 text-gray my-1">
            {{ "REFERRAL.COPY" | translate }}
            <a (click)="copyReferalLink()" class="clickable hover-pink">
                <span class="icon-span ml-2"><img src="../../../../../assets/icons/sharethis.svg"></span>
            </a>
        </span>
        <span *ngIf="!showReferalLink && isLogged() && !this.contextService.isPublicMember()"
            class="fs-14 my-1 text-gray">
            {{ "REFERRAL.NOT-QUALIFIED" | translate }}
        </span>
        <span class="fs-14 my-1">
            <span class="noselect">
                {{ 'DASHBOARD.CONTACT_US' | translate }}:
            </span>
            <a class="text-bolderr"
                href="tel:{{companyInfo?.contacts?.phoneNumber}}">{{companyInfo?.contacts?.phoneNumber}}</a>
        </span>
    </div>
    <div class="social-link-mobile p-2">
        <a target="_blank" href="https://www.facebook.com/{{companyInfo?.contacts?.fb}}">
            <app-icons [icon]="'fb'"></app-icons>
        </a>
        <a target="_blank" href="https://www.instagram.com/{{companyInfo?.contacts?.ig}}">
            <app-icons [icon]="'ig'"></app-icons>
        </a>
    </div>
</div>

<div (click)="showSidebar = false" [ngClass]="{'showCloseDiv': showSidebar}"
     class="close-overlay animate__slideInRight animate__animated d-none">
</div>

<!-- View catalogue floating left circle -->
<a [ngStyle]="{'background': 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2)), url('+ currentCampaingImg+')' }"
    *ngIf="showFlipbook && router.url == '/'" routerLink="/catalogues/preview" class="shortAnim catalogue-floating-div">
            <div class="shortAnim content">
                    {{ 'MENU.CATALOGUE_ADD' | translate }}
                    <app-icons class="icon" [icon]="'book'"></app-icons>
            </div>
</a>

<!-- <a *ngIf="showNutriplusFlipbook && router.url == '/'" routerLink="/catalogues/nutriplus"
    class="shortAnim catalogue-floating-div" 
    [ngStyle]="{'background': 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2)), url('+currentNutriplusImg+')'}"
    [ngClass]="{'position-up' : showFlipbook}">
        <div class="shortAnim content">
                Nutriplus
            <app-icons class="icon" [icon]="'book'"></app-icons>
        </div>
</a> -->