import ArrayStore from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from "devextreme/data/data_source";

export class Context {
    locale: string
    name: string
    firstName: string
    email: string
    companies: Array<Company>
}

export class Company {
    id: number
    name: string
    maintenance: boolean
    validateZipCode: boolean
    telephoneCountryCode: string
    maxProductQuantity:number
}

export class OptionsSource {
    paginate?: boolean;
    pageSize?: number;
    group?: string;
    store: ArrayStore | CustomStore | DataSource;
}