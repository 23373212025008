import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
    name: string;
    message: string;
    heading: string;
    redirectUrl: string;
    colorBtnTextKey: 'MODAL.CONFIRM';
    neutralBtnTextKey: 'MODAL.CLOSE';

    constructor( private _mdr: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) data ) {
        this.heading = data.heading;
        this.message = data.message;
        this.redirectUrl = data.redirectUrl;

        if(data.colorBtnTextKey)
          this.colorBtnTextKey = data.colorBtnTextKey;
        if(data.neutralBtnTextKey)
          this.neutralBtnTextKey = data.neutralBtnTextKey;
    }

    closeDialog() {
        this._mdr.close(false);
    }

    confirmDialog() {
        this._mdr.close(true);
    }
}
