import { Route } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { InsideGuard } from '../guards';
import { EBookComponent } from './routes/club-layout/my-academy/e-book/e-book.component';

export const routes: Route[] = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [InsideGuard],
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },
            {
                path: '',
                loadChildren: () => import('./routes/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'shop',
                loadChildren: () => import('./routes/shop/shop.module').then(m => m.ShopModule)
            },
            {
                path: 'cart',
                loadChildren: () => import('./routes/cart/cart.module').then(m => m.CartModule)
            },
            {
                path: 'profile',
                loadChildren: () => import('./routes/cart/cart.module').then(m => m.CartModule)
            },
            {
                path: 'club',
                loadChildren: () => import('./routes/club-layout/club.module').then(m => m.ClubModule),
                data: { preloadDelay: 1000 }
            },
            {
                path: 'catalogues',
                loadChildren: () => import('./routes/catalogues/catalogues.module').then(m => m.CataloguesModule)
            },
            {
                path: 'news',
                loadChildren: () => import('./routes/news/news.module').then(m => m.NewsModule)
            },
            {
                path: 'e-book',
                component: EBookComponent,
                canActivate: [InsideGuard],
            }
        ]
    }
];