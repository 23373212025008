<div *ngIf="!contextService.isPublicMember()" class="user-info text-black text-bolder fs-15 mb-5">
    <!-- matTooltipPosition="above" [matTooltip]="'ORDER.UPLOAD-IMAGE' | translate" -->
    <img *ngIf="contextService?.profile?.profileImageUrl" [ngClass]="showPicture ? 'd-block' : 'd-none d-lg-block'" class="img-fluid user-img mx-auto clickable" [src]="contextService?.profile?.profileImageUrl" alt="Avatar">
    <p class="mb-1 text-center mt-2">
        {{ 'SIDEBAR.WELCOME' | translate }}, {{ contextService.getFirstName() }}
        <span *ngIf="profileService.mlmProgression" class="p-1" [matTooltip]="'DASHBOARD.VIPMESSAGE' | translate" >
            <app-icons [icon]="'star_red'"></app-icons>
        </span>
    </p>
    <p *ngIf="profileService.mlmLevel" class="text-center">{{ profileService.mlmLevel }}</p>
</div>