<ng-container *ngIf="memberLoyaltyProgram.length > 0">
    <button mat-icon-button class="outline-none hover-pink" [matMenuTriggerFor]="menu">
        <div (click)="getLoyaltyProgramDetails('COIN')" class="icon-wrap d-inline">
            <img src="../../../assets/icons/farmasi_coin.png" class="clickable">
        </div>
    </button>
    <button mat-icon-button class="outline-none hover-pink" [matMenuTriggerFor]="menu">
        <div (click)="getLoyaltyProgramDetails('WALLET')" class="icon-wrap d-inline">
            <img src="../../../assets/icons/farmasi_wallet.png" class="clickable">
        </div>
    </button>
    <mat-menu #menu="matMenu">
        <div class="dropdown-container">
            <h4 class="text-main">
                {{ (showType == "WALLET" ? 'LOYALTY.E_WALLET' : 'LOYALTY.COINS') | translate }}
            </h4>
            <p>
                {{ 'LOYALTY.DROPDOWN_DESCRIPTION' | translate }}:
                <span class="text-main">
                    {{ showType == "WALLET" ? (memberLoyaltyProgram[0]?.pointsBalance || 0) : (memberLoyaltyProgram[0]?.pointsEarned || 0) }}
                </span>
            </p>
        </div>
    </mat-menu>
</ng-container>
