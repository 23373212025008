import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription,  combineLatest } from 'rxjs';
import { AuthService, ContextService, AlertService } from 'src/app/core';
import { ReferralService } from '../referral/referral.service';

@Component({
  selector: 'app-referral-public',
  templateUrl: './referral-public.component.html',
  styleUrls: ['./referral-public.component.scss']
})
export class ReferralPublicComponent implements OnInit, OnDestroy {

  subscribtion: Subscription;
  public isValid = false;
  public countries: any[];
  public referral: any;
  public companyId: number;
  public isLoading: boolean;
  public memberCompanyId: number;

  public redirectTo = this.route.snapshot.queryParams['redirect'] ?? '';

  constructor(private readonly route: ActivatedRoute, 
              private readonly referralService: ReferralService, 
              private readonly router: Router, 
              private readonly authService: AuthService, 
              private readonly contextService: ContextService, 
              private readonly alertService: AlertService) { }

  ngOnInit(): void {

    if(this.redirectTo === 'shop')
      this.redirectTo = '';

    this.isValidReferral(
      (referral) => {

        //ako je korisnik logiran zapiši referral u storage i preusmjeri na shop
        if(this.authService.isTokenInStorage) {
          this.isValid = false;
          this.saveReferralToLocalStorage(referral.companyId);
          this.router.navigate([this.redirectTo]);
        }
        else 
          this.chooseCountry(this.memberCompanyId);
      }, 
      () => this.router.navigate([''])
    );
  }

  isValidReferral(onSucess: Function, onFail: Function) {
    var paramsQueryParams = combineLatest(this.route.params, this.route.queryParams,(params, query) => ({ params, query }));
    this.subscribtion = paramsQueryParams.subscribe(url => {
      this.referralService.getValidReferral(url.params.memberNumber.toUpperCase(), (response) => {

          this.referral = { referral_number: url.params.memberNumber.toUpperCase(), companyId: this.companyId, memberNumber: response.memberNumber };

          if(url.query.companyId != null && !this.authService.isTokenInStorage) {            
            this.isValid = false;
            this.logUser(url.query.companyId);
            return;
          }
          else            
            this.isValid = response.valid;
          
          this.memberCompanyId = response.companyId;

          response.valid ? onSucess(response) :  onFail();
      }, 
      (error) => onFail()
    );
    })
  }

  saveReferralToLocalStorage(companyId: number) {  
    
    if(localStorage.getItem('referral_number') != null)
      return;
    
    this.referral.companyId = companyId;
    if(this.contextService.memberNumber != this.referral.referral_number)
      localStorage.setItem('referral_number', JSON.stringify(this.referral));
  }

  chooseCountry(companyId: number) {
    if(this.isLoading)
      return
    // this.loadingService.showLoader();
    this.isLoading = true;
    
    this.goToLink("http://" + this.contextService.getCompanyUrlByIdProd(companyId) + "/referral-public/"+ this.referral.referral_number +"?companyId="+companyId+"&redirect="+this.redirectTo); //, { queryParams: { companyId: companyId, memberNumber: this.referral.referral_number  }} ); //+ "/referral?companyId=" + companyId + "&memberNumber=" + this.referral.referral_number);

  }

  goToLink(url: string){
    window.open(url, "_self");  
  }

  logUser(companyId: number){
    this.createOrLogUser("","",this.referral.referral_number,"", companyId, () => {
      this.saveReferralToLocalStorage(companyId);     
      this.router.navigate([this.redirectTo]);
    });
  }

  private createOrLogUser(memberNumber: string = '',  stateUrl: string, leaderMemberNumber: string = '', productId: string = '', companyId: number = 0, onSucess: Function) {
      this.authService.createGuestAndLogin(memberNumber, companyId, leaderMemberNumber, productId, true, (res)=>{
        if(this.authService.isTokenInStorage){          
          onSucess();
        }
      }
    ).subscribe(
        () => {
            if (this.authService.checkAuthority('USER')){
              this.alertService.success('LOGIN.WELCOME.M1');
            }            
            else {
                this.authService.removeAuth();
                this.alertService.error('ERRORS.LOGIN-FAILED');
            }
        },
        (error) => {
            this.router.navigate(['login']);
            try {
                if (error.json().error_description == 'User account is locked')
                    this.router.navigate(['password-reset']);
            }
            finally {
                this.alertService.error('ERRORS.LOGIN-FAILED');
            }
        }
    );
}
  
  ngOnDestroy(): void {
    this.subscribtion.unsubscribe();
  }
}
