import { Component, OnInit, Input } from '@angular/core';
import { BasketService } from '../basket.service';
import { Product } from 'src/app/inside-layout/routes/shop/shop.models';
import { AlertService, ContextService, UtilityService } from 'src/app/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

    @Input('product') product: Product;
    public quantity: number = 1;
    public altCurrency: string;
    public altCurrencyExchangeRate: number;

    constructor(public basketService: BasketService, public contextService: ContextService, private route: ActivatedRoute, private utilityService: UtilityService, private alertService: AlertService) { }

    ngOnInit(): void {
        this.checkAltCurrency();
    }

    addCart() {
        if (this.product.kolicina < 1) return
        this.basketService.addOrUpdateProduct(this.product, this.basketService.getOrderId(), this.quantity);
        this.quantity = 1;
    }

    generateLink() {
        let URL = window.location.origin + '/shop/product/' + this.product.productId;
        this.utilityService.copyToClipboard(URL);
    }

    checkAltCurrency() {
        this.contextService.getCompanyAltCurrency((altCurr: { companyId: string, altCurrency: string }) => {
            if (altCurr.altCurrency) {
                this.altCurrency = altCurr.altCurrency;

                if (this.altCurrency)
                    this.contextService.getCompanyAltCurrencyExchangeRate((altCurrExchangeRate: { companyId: string, altCurrencyExchangeRate: number }) => {
                        if (altCurrExchangeRate.altCurrencyExchangeRate)
                            this.altCurrencyExchangeRate = altCurrExchangeRate.altCurrencyExchangeRate;
                    })
            }
        })
    }

}
