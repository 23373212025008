<div class="container-fluid pt-login-wrapper">
    <div class="row fix-img-place">
      <div class="col-md-6 pt-login-left">
        <img src="../../../assets/logo-white.svg"/>
      </div>
      <div class="col-md-6 col-xs-12 pt-login-right">
        <img class="login-logo-right" src="../../../assets/logo-header.svg"/>
        <h4 class="text-black">{{ 'LOGIN.WELCOME.M1' | translate }}</h4>
        <form novalidate [formGroup]="loginForm" (submit)="login()">
            <input type="text" [formControl]="loginForm.controls['username']" id="username" name="username" [placeholder]="'LOGIN.ENTERUSERNAME' | translate">
            <input type="password" required [formControl]="loginForm.controls['password']" name="password" [placeholder]="'LOGIN.ENTERPASSWORD' | translate">
            <div class="pt-login-buttons">
                <button [disabled]="loadingService.unfinishedRequests" type="submit" class="pt-login-prijavise outline-none ">{{ 'LOGIN.LOGIN' | translate }}</button>
                <a *ngIf="!this.contextService.isPublicMember()" [routerLink]="['/register']" class="pt-login-registrujse  outline-none text-decoration-none">{{ 'LOGIN.REGISTERNOW' | translate }}</a>
            </div>
        </form>
        <p class="mt-2"><a class="text-decoration-none mt-2" [routerLink]="'/password-reset'">{{ 'LOGIN.FORGOTPASSWORD' | translate }}</a></p>
        <p>{{ 'LOGIN.WELCOME.M2' | translate }}</p>
        <p>{{ 'LOGIN.WELCOME.M3' | translate }}{{ 'LOGIN.WELCOME.M4' | translate }}</p>
        <!-- <p>Za detaljno uputstvo kliknite <a href="https://s3-eu-west-1.amazonaws.com/test-farmasi-bucket/manual/ordering-manual-klub.farmasi.ba.pdf">ovdje</a>.</p> -->
      </div>
    </div>
  </div>
  <!-- TODO vidjeti s ovim sta -->
  <!-- <div class="pt-login-language-selector outline-none">
    <select name="pt-language" id="pt-language">
      <option value="Bosanski">Bosanski</option>
      <option value="Crnogorski">Crnogorski</option>
      <option value="English">English</option>
      <option value="Hrvatski">Hrvatski</option>
    </select>
  </div> -->
  <app-disabled-user (closeDisabledUserModalEvent)='userDisabledModalVisible = false' *ngIf="userDisabledModalVisible" class="animate__slideInLeft animate__animated animate__faster p-3"></app-disabled-user>
