<div *ngIf="product" class="product-wrapper outline-none noselect">
    <a [routerLink]="['/shop/product', product.productId]" class="text-decoration-none">
        <div class="label-wrap text-uppercase">
            <img *ngIf="product.loyaltyProductPoints > 0" src="../../../assets/icons/farmasi_coin.png" class="mt-1 mr-1">
            <span *ngIf="product.newProduct" class="new">{{ 'SHOP.LABEL.NEW'| translate }}</span>
            <span *ngIf="product.popularProduct" class="popular">{{'SHOP.LABEL.POPULAR' | translate}}</span>
            <span *ngIf="product.saleProduct" class="sale">{{'SHOP.LABEL.SALE' | translate}}</span>
            <!-- <span *ngIf="product?.rebateFixed && product.rebateFixed > 0" class="rebate">-{{ product.rebateFixed }}%</span> -->
        </div>
        <div class="img-wrap">
            <img *ngIf="product?.images?.length > 0; else dummyImage" [src]="product.images[0]" class="product-card-img" [alt]="product.name">
        </div>
        <ng-template #dummyImage>
            <div class="img-wrap">
                <img src="../../../assets/logo-footer.svg" class="product-card-img">
            </div>
        </ng-template>
        <div class="text-wrap">
            <p class="product-card-id text-uppercase text-gray text-weight-light border-bottom py-1 m-0">
                {{ 'SHOP.PRODUCT_ID'| translate }}:
                <span class="text-bold"> {{product.productId}}</span>
                <!-- <img [title]="'SHOP.GENERATE_LINK' | translate" (click)="generateLink()" src="../../../assets/icon-share.svg" alt="share" class="pull-right p-1"> -->
            </p>
            <!-- <p class="text-gray fs-14 m-0 mt-1"> kategorija ?</p> -->
            <p class="text-bolder product-card-name text-black mt-2"> {{product.name}} </p>
        </div>
    </a>
    <img [title]="'SHOP.GENERATE_LINK' | translate" (click)="generateLink()" src="../../../assets/icon-share.svg" alt="share" class="clickable share-product">
        <div class="btn-price-wrap clickable">
            <div (click)='addCart()' [ngClass]="{'in-cart' : product.isInCart, 'not-in-cart': !product.isInCart, 'no-quantity': product.kolicina < 1}" class="icon-wrap shortAnim d-inline-block">
                <app-icons class="text-white cart-icon" [icon]="'cart_1'"></app-icons>
                <div class="add-to-cart text-uppercase d-inline shortAnim">{{ product.kolicina > 1 ? ('SHOP.CART.ADD' | translate) : ('SHOP.OUT_OF_STOCK' | translate) }}</div>
            </div>
            <div class="price-wrap d-inline-block">
                <div class="d-flex flex-column">
                    <span class="price shortAnim text-black"> {{product.price | number:'1.2-2'}} {{this.contextService.localCurr}} </span>
                    <span *ngIf="altCurrency && altCurrencyExchangeRate" class="alt-price shortAnim text-black"> {{(product.price / altCurrencyExchangeRate) | number:'1.2-2'}} {{this.altCurrency}} </span>
                </div>
            </div>
        </div>
</div>
