import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatMenuModule} from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';



import { CoreModule } from '../core/core.module';
import { CroppieModule } from 'angular-croppie-module';

import { CarouselComponent } from '../inside-layout/carousel/carousel.component';
import { ProductCardComponent } from '../shared/product-card/product-card.component';
import { IconsComponent } from '../shared/icons/icons.component';
import { PaginatorComponent } from '../shared/paginator/paginator.component';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { InputDialogComponent } from '../shared/dialog/input-dialog/input-dialog.component';
import { SurveyDialogComponent } from '../shared/dialog/survey-dialog/survey-dialog.component';
import { UserBlockComponent } from '../shared/user-block/user-block.component';
import { OrderPreviewComponent } from '../inside-layout/routes/cart/order-preview/order-preview.component';
import { PictureUploadWrapComponent } from '../shared/picture-upload-wrap/picture-upload-wrap.component';
import { PictureUploadComponent } from '../shared/picture-upload-wrap/picture-upload/picture-upload.component';
import { FirstOrderSubmitModalComponent } from '../inside-layout/routes/cart/order-preview/first-order-submit-modal/first-order-submit-modal.component';
import { ContractModalComponent } from '../inside-layout/routes/cart/order-preview/contract-modal/contract-modal.component';
import { ProductSingleComponent } from '../inside-layout/routes/shop/product-single/product-single.component';
import { BannersComponent } from '../shared/banners/banners.component';
import { LoyaltyInfoComponent } from './loyalty-info/loyalty-info.component';
import { LoyaltyInfoDropdownComponent } from './loyalty-info-dropdown/loyalty-info-dropdown.component';
import { CataloguePreviewComponent } from '../inside-layout/routes/catalogues/catalogue-preview/catalogue-preview.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

const MatDesign = [
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatSnackBarModule,
    MatMenuModule,
    MatExpansionModule,
    MatTabsModule
];

const SharedComponents = [
    CarouselComponent, 
    ProductCardComponent, 
    IconsComponent,
    PaginatorComponent,
    DialogComponent,
    OrderPreviewComponent,
    UserBlockComponent,
    PictureUploadWrapComponent,
    PictureUploadComponent,
    FirstOrderSubmitModalComponent,
    ContractModalComponent,
    ProductSingleComponent,
    BannersComponent,
    LoyaltyInfoComponent,
    LoyaltyInfoDropdownComponent,
    CataloguePreviewComponent,
    InputDialogComponent,
    SurveyDialogComponent
];

@NgModule({
    declarations: [SharedComponents],
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            extend: true,
            isolate: false
        }),
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatDesign,
        CroppieModule
    ],
    providers: [
    ],
    exports: [
        CommonModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        SharedComponents,
        MatDesign,
        CroppieModule
    ]
})
export class SharedModule { }
