<div class="p-3">
    <img (click)="closeItemsCartEvent.emit()" [title]="'MODAL.CLOSE' | translate" class="clickable pull-right" src="../../../../assets/icons/close.svg" alt="close">
    <div class="items-wrapper">
        <span class="text-bolder mt-3 text-black fs-18 my-3">{{ mode ? ('ORDER.EDITTITLE' | translate) + ' # '+mode : ('SHOP.CART.MAIN' | translate) }}</span>
        <div class="row py-2 border-bottom" *ngFor="let product of cartItems">
            <div class="col-2 p-0">
                <div class="img-wrap">
                    <img *ngIf="product?.images?.length > 0; else dummyImage" [src]="product.images[0]" class="max-height-50 d-block m-auto" [alt]="product.name">
                </div>
                <ng-template #dummyImage>
                    <img src="../../../../assets/logo-footer.png" class="max-height-50 d-block m-auto">
                </ng-template>
            </div>
            <div class="col-8 m-auto fs-14 name-wrap">
                <!-- <div class="name-wrap">{{product.name}}</div> -->
                <a [routerLink]="['/shop/product', product.productId]" [title]="product.name">{{product.name}}</a>
                
                <div class="text-gray fs-12">{{ product.quantity }} x 
                    <span class="text-pink text-bolder">{{ product.price | number:'1.2-2'}} {{contextService.localCurr}}</span>
                    <span *ngIf="altCurrency && altCurrencyExchangeRate" class="ml-2 text-gray fs-10">{{ product.price / altCurrencyExchangeRate | number:'1.2-2'}} {{altCurrency}}</span>
                </div>
            </div>
            <div class="col-2 text-left text-bold m-auto fs-14">
                <app-icons [icon]="'remove'" class="hover-pink" (click)="basketService.removeProduct(product.productId)"></app-icons>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-12">
            <span class="text-bolder mr-1 mt-2 text-black fs-18">{{ 'ORDER.TOTAL' | translate }} {{orderTotal | number: '1.2-2'}} {{contextService.localCurr}}</span>
            <span *ngIf="altCurrency && altCurrencyExchangeRate" class="ml-1 text-gray fs-10">{{ orderTotal / altCurrencyExchangeRate | number:'1.2-2'}} {{altCurrency}}</span>

        </div>
        <div *ngIf="!contextService.isTempMember()" class="col-12 fs-12">
            {{ 'ORDER.CUITEMS.MINIMUMVALUE' | translate }}: {{ minimalValue }}{{ localCurr }}
            <span *ngIf="altCurrency && altCurrencyExchangeRate" class="ml-1 text-pink fs-10">{{ minimalValue / altCurrencyExchangeRate | number:'1.2-2'}} {{altCurrency}}</span>

        </div>
        <!-- <button routerLink="/cart" class="col-10 mx-auto mt-3 pink-black-btn" mat-flat-button>
            Naprijed na plačanje*
        </button> -->
        <button *ngIf="!mode" routerLink="/cart" class="col-10 mx-auto mt-3 pink-black-btn" mat-flat-button>
            <span>{{ 'SHOP.CART.MAIN' | translate }}</span>
        </button>
        <button *ngIf="mode" [routerLink]="['cart/edit', mode]" class="col-10 mx-auto mt-3 pink-black-btn" mat-flat-button>
            <span>{{ 'SHOP.CART.MAIN' | translate }}</span>
        </button>
    </div>
</div>