import { Injectable } from '@angular/core';

import { HttpRequestType, RequestService } from '../../core/request.service'
import { TranslatorService } from '../../core/index';
import { TranslateService } from '@ngx-translate/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class RegisterService {
    public companiesData: Array<any> = [];
    public companies: Array<any> = [{ value: null, label: '' }];
    public places: Array<any> = [{ value: null, label: '' }];
    public currentCompany: any;

    public collectableProfileData: any =
        [
            {
                "companyId": 1,
                "registrationFields": [
                    {
                        "field": "firstName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "lastName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "email",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "address",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "addressNo",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "place",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "zipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "gender",
                        "visible": false,
                        "default": "OTHER"
                    },
                    {
                        "field": "phoneMobile",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "name",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthDate",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "birthPlace",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "idNumber",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "personalIdentificationNumber",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "parentName",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "defaultLocale",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "recommendation",
                        "visible": true,
                        "default": null
                    }
                ]
            },
            {
                "companyId": 2,
                "registrationFields": [
                    {
                        "field": "firstName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "lastName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "email",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "address",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "addressNo",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "place",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "zipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "gender",
                        "visible": false,
                        "default": "OTHER"
                    },
                    {
                        "field": "phoneMobile",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "name",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthDate",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "birthPlace",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "idNumber",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "personalIdentificationNumber",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "parentName",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "defaultLocale",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "recommendation",
                        "visible": true,
                        "default": null
                    }
                ]
            },
            {
                "companyId": 3,
                "registrationFields": [
                    {
                        "field": "firstName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "lastName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "email",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "address",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "addressNo",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "place",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "zipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "gender",
                        "visible": false,
                        "default": "FEMALE"
                    },
                    {
                        "field": "phoneMobile",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "name",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthDate",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "birthPlace",
                        "visible": false,
                        "default": "unknown"
                    },
                    {
                        "field": "idNumber",
                        "visible": false,
                        "default": "00000000"
                    },
                    {
                        "field": "personalIdentificationNumber",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "parentName",
                        "visible": false,
                        "default": "unknown"
                    },
                    {
                        "field": "defaultLocale",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "recommendation",
                        "visible": true,
                        "default": null
                    }
                ]
            },
            {
                "companyId": 4,
                "registrationFields": [
                    {
                        "field": "firstName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "lastName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "email",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "address",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "addressNo",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "place",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "zipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "gender",
                        "visible": false,
                        "default": "OTHER"
                    },
                    {
                        "field": "phoneMobile",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "name",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthDate",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "birthPlace",
                        "visible": false,
                        "default": "unknown"
                    },
                    {
                        "field": "idNumber",
                        "visible": false,
                        "default": "00000000"
                    },
                    {
                        "field": "personalIdentificationNumber",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "parentName",
                        "visible": false,
                        "default": "unknown"
                    },
                    {
                        "field": "defaultLocale",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "recommendation",
                        "visible": true,
                        "default": null
                    }
                ]
            },
            {
                "companyId": 5,
                "registrationFields": [
                    {
                        "field": "firstName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "lastName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "email",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "address",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "addressNo",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "place",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "zipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "gender",
                        "visible": false,
                        "default": "OTHER"
                    },
                    {
                        "field": "phoneMobile",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "name",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthDate",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "birthPlace",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "idNumber",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "personalIdentificationNumber",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "parentName",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "defaultLocale",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "recommendation",
                        "visible": true,
                        "default": null
                    }
                ]
            },
            {
                "companyId": 6,
                "registrationFields": [
                    {
                        "field": "firstName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "lastName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "email",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "address",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "addressNo",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "place",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "zipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "gender",
                        "visible": false,
                        "default": "OTHER"
                    },
                    {
                        "field": "phoneMobile",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "name",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthDate",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "birthPlace",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "idNumber",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "personalIdentificationNumber",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "parentName",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "defaultLocale",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "recommendation",
                        "visible": true,
                        "default": null
                    }
                ]
            },
            {
                "companyId": 12,
                "registrationFields": [
                    {
                        "field": "firstName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "lastName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "email",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "address",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "addressNo",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "place",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "zipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "gender",
                        "visible": false,
                        "default": "OTHER"
                    },
                    {
                        "field": "phoneMobile",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "name",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthDate",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "birthPlace",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "idNumber",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "personalIdentificationNumber",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "parentName",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "defaultLocale",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "recommendation",
                        "visible": true,
                        "default": null
                    }
                ]
            },
            {
                "companyId": 13,
                "registrationFields": [
                    {
                        "field": "firstName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "lastName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "email",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "address",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "addressNo",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "place",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "zipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "gender",
                        "visible": false,
                        "default": "OTHER"
                    },
                    {
                        "field": "phoneMobile",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "name",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthDate",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "birthPlace",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "idNumber",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "personalIdentificationNumber",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "parentName",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "defaultLocale",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "recommendation",
                        "visible": true,
                        "default": null
                    }
                ]
            }
        ];

    constructor(private requestService: RequestService, private translatorService: TranslatorService, private translateService: TranslateService) { }

    public registerUser(user: any, onSuccess: Function, onFail: Function = null): void {
        this.requestService.createRequest(HttpRequestType.Post, '/mlm/api/v1/members/register', user, this.generateHeaders(), () => {
            onSuccess();
        });
    }

    public getInvitationData(token: string, onSuccess: Function, onFail: Function): void {
        this.requestService.createRequest(HttpRequestType.Get, `/mlm/api/v1/members/invitation/${token}`, null, this.generateHeaders(), (response) => {
            onSuccess(response);
        }, () => {
            onFail();
        });
    }

    public loadCompanyForRegistrationByDomain(domain: string, callback): void {

        domain = domain == 'localhost' || domain.includes('192.168') ? 'kb.eurobit.ba' : domain;
        // domain = domain == 'localhost' ? 'ba.new.kb.eurobit.ba' : domain;

        if(this.currentCompany)
            callback(this.currentCompany);
        else
            this.requestService.createRequest(HttpRequestType.Post, `/codebooks/api/v1/companies/url`, { url: domain }, this.generateHeaders(), (response) => {
                this.currentCompany = response;
                callback(response);
            });
    }

    public loadCompanyForRegistration(principalId: number): void {
        this.companies = [{ value: null, label: '' }];

        this.requestService.createRequest(HttpRequestType.Get, `/codebooks/api/v1/companies/registration/${principalId}`, null, this.generateHeaders(), (response) => {
            this.companiesData = response;

            for (let company of response)
                this.companies.push({ value: company.id, label: company.name })
        });
    }

    public loadZipCodesForCompany(companyId: number): void {
        this.requestService.createRequest(HttpRequestType.Get, `/codebooks/api/v1/zip-codes/${companyId}`, null, this.generateHeaders(), (response) => {
            for (let place of response)
                this.places.push({ 
                    value: { place: place.place, zipCode: place.code },
                    label: place.place + ' - ' + place.code })
        });
    }

    public resetPasswordEmail(email: string, onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Post, '/codebooks/api/v1/password-reset', { email: email }, this.generateHeaders(), () => {
            onSuccess();
        });
    }

    public resetPasswordNewPassword(password: any, onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Put, '/codebooks/api/v1/password-reset', password, this.generateHeaders(), () => {
            onSuccess();
        });
    }

    public activate(user: any, onSuccess: Function, onFail: Function): void {
        this.requestService.createRequest(HttpRequestType.Post, '/mlm/api/v1/members/activate', user, this.generateHeaders(), () => {
            onSuccess();
        }, () => {
            onFail();
        });
    }

    public changeEmail(token: string, onSuccess: Function, onFail: Function): void {
        this.requestService.createRequest(HttpRequestType.Post, `/mlm/api/v1/members/email-change/${token}`, null, this.generateHeaders(), () => {
            onSuccess();
        }, () => {
            onFail();
        });
    }

    private generateHeaders(): HttpHeaders {
        return new HttpHeaders({ 'Content-Type': 'application/json', 'Accept-Language': this.translatorService.selectedLanguage });
    }

    public getGenderOptions(): Array<any> {
        let options = [];
        let values = ['FEMALE', 'MALE', 'OTHER'];

        for (let value of values)
            this.translateService.get(`GENERAL.GENDER-${value}`).subscribe((translation: string) => {
                options.push({ label: translation, value: value });
            });

        return options;
    }
}
