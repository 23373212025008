import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextService, HttpRequestType, RequestService } from 'src/app/core';
import { Profile } from '../../profile/profile.model';

@Component({
  selector: 'app-e-book',
  templateUrl: './e-book.component.html',
  styleUrls: ['./e-book.component.scss']
})
export class EBookComponent implements OnInit {

  constructor(
    private contextService: ContextService,
    private requestService: RequestService,
    private router: Router
    ) { }

  ngOnInit(): void {
    if(this.contextService.getCompanyId() !== 1)
      this.router.navigate(['/']);

    this.requestService.createRequest(HttpRequestType.Get, '/mlm/api/v1/members/details', null, null, (profile: Profile) => {
      if(parseInt(profile.mlmLevelCode) === 0)
        this.router.navigate(['/']);
  });
  }

}
