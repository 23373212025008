import { Component, AfterViewInit } from '@angular/core';
import { RegisterService } from '../register/register.service';
import { Router } from '@angular/router';
import { ContextService } from 'src/app/core';


@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements AfterViewInit {
    companyId: number = null;

  constructor(private registerService: RegisterService, private router: Router, private contextService: ContextService) { }

  ngAfterViewInit() {
      if(this.registerService.currentCompany!=null){
          let maintenance: number = this.registerService.currentCompany.maintenance;
          if (maintenance == 0 || maintenance == 5)
              this.router.navigate(['/']);
      }else
          this.router.navigate(['/']);
        this.companyId = this.contextService.getCompanyId();
  }

  showImageByDomain(){
      switch (window.location.hostname) {
          case 'localhost':
              return "maintenance_BA";
          case 'kb.eurobit.ba':
              return "maintenance_BA";
          case 'klub.farmasi.ba':
              return "maintenance_BA";
          case 'klub.farmasi.rs':
              return "maintenance_SR";
          case 'klub.farmasi.hr':
              return "maintenance_HR";
          case 'klub.farmasi.si':
              return "maintenance_SL";
          case 'klub.farmasi.mk':
              return "maintenance_MK";
          case 'klub.farmasi.co.me':
              return "maintenance_CG";
          case 'klub.farmasi.al':
              return "maintenance_AL";
          case 'klub.farmasi.com.al':
              return "maintenance_KO";
      }
  }

}
