import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild, NgZone, OnChanges, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { inAnimation, inOutAnimation } from 'src/app/shared/animations';
import * as iPaperJsApi from '../../../../../assets/scripts/ipaper.js';
import { CataloguesService } from '../catalogues.service.js';

@Component({
  selector: 'app-catalogue-preview',
  templateUrl: './catalogue-preview.component.html',
  styleUrls: ['./catalogue-preview.component.scss'],
  animations: [inOutAnimation, inAnimation]
})

export class CataloguePreviewComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("routerContainer") routerContainer!: ElementRef;
  @Input('pdfSource') pdfSource: string;
  public subscribtion: Subscription;
  public isRouterActive: Observable<boolean>;

  public iFrameSource: SafeHtml;
  public catalogueOrder: string;

  constructor(private sanitized: DomSanitizer, 
              public router: Router, 
              private route: ActivatedRoute, 
              private renderer: Renderer2, 
              private ngZone: NgZone,
              private catalogueService: CataloguesService
             ) {}

  ngOnInit(): void {
    if(this.pdfSource) // using for loyalty catalogue / myAcademy guide
      this.iFrameSource = this.sanitized.bypassSecurityTrustResourceUrl(this.pdfSource);
    else // using for main or nutriplus catalogue
      this.generateSourceUrl();
  
    // Product single popup control
    this.isRouterActive = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map(() => {
        const urlArray = this.router.url.split('/');

        if (this.catalogueOrder != this.route.snapshot.params['catalogueOrder']) {
          this.iFrameSource = null;
          setTimeout(() => this.generateSourceUrl(), 100);
        }
        return urlArray[2] == 'nutriplus' && urlArray[3] != null ||
               urlArray[2] == 'special-offers' && urlArray[3] != null || 
               urlArray[2] == "preview" && urlArray[3] != null;
      })
    );
  }

  ngOnChanges() {
    if(this.pdfSource)
      this.iFrameSource = this.sanitized.bypassSecurityTrustResourceUrl(this.pdfSource);
  }

  ngOnDestroy() {
    if (this.subscribtion)
      this.subscribtion.unsubscribe();
  }

  onIframeLoad(e: any) {
    let instance = iPaperJsApi(e.target, 3);
    instance.publication.onPageElementClick((result) => {
        this.ngZone.run(() => {
          this.handleOnItemAdd(result);
        });
    });
  }

  public generateSourceUrl() {
    const type = this.route.snapshot.url[0].path;

    this.catalogueService.getIpaperCatalogues((res: any[]) =>  { 
      let source;
      if(type === 'preview') 
        source = res.find(obj => obj.type === 'CAMPAIGN')?.iPaperSourceUrl;
      else if(type === 'nutriplus')
        source = res.find(obj => obj.type === 'NUTRIPLUS')?.iPaperSourceUrl;
      
      if(source)
        this.iFrameSource = this.sanitized.bypassSecurityTrustResourceUrl(source);
      else 
        this.router.navigate['/dashbord'];
    });
  }

  private handleOnItemAdd(data) {
    let prodId = data.data.productID;
    if (prodId) {
      this.router.navigate([`${prodId}`], { relativeTo: this.route });
      setTimeout(() => { // set container height just for smoother animations\
        if (this.routerContainer?.nativeElement) {
          let height = this.routerContainer.nativeElement.offsetHeight;
          this.renderer.setStyle(this.routerContainer.nativeElement, "minHeight", height + 'px');
        }
      }, 1000);
    }
  }

  goBack() {
    window.history.back();
  }
}