import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product } from '../../routes/shop/shop.models';
import { BasketService } from 'src/app/shared/basket.service';
import { ContextService } from 'src/app/core';

@Component({
  selector: 'app-header-cart-items',
  templateUrl: './header-cart-items.component.html',
  styleUrls: ['./header-cart-items.component.scss']
})
export class HeaderCartItemsComponent implements OnInit {
  @Input('cartItems') cartItems: Product[];
  @Input('orderTotal') orderTotal: number;
  @Input('mode') mode: number;
  @Input('minimalValue') minimalValue: number;
  @Input('localCurr') localCurr: string;
  @Input('altCurrency') altCurrency?: string;
  @Input('altCurrencyExchangeRate') altCurrencyExchangeRate?: string;

  @Output() closeItemsCartEvent = new EventEmitter();

  constructor(public basketService: BasketService, public contextService: ContextService) { }

  ngOnInit(): void {
  }

}
