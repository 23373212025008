import { Component, OnInit, Optional } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { SettingsService } from './core/settings.service';
import { ContextService, LoadingService } from './core';
import { RegisterService } from './outside-layout/register/register.service';
import { environment } from 'src/environments/environment';
import { AppService } from './app.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    title = 'FARMASI';

    constructor(public appService: AppService,
        private router: Router,
        public settings: SettingsService,
        private contextService: ContextService,
        public registerService: RegisterService, 
        public loadingService: LoadingService,
        private gtmService: GoogleTagManagerService
    ) {}

           
    ngOnInit() {
        let currentVersion = localStorage.getItem('club_appVersion');
        if (currentVersion != environment.appVersion) {
            localStorage.setItem('club_appVersion', environment.appVersion);
            window.location.reload(true);
        }
        $(document).on('click', '[href="#"]', e => e.preventDefault());

        this.loadCompany();

        if(this.contextService.getCompanyId() === 1) 
            this.setupTagBrodcast();
    }

    loadCompany(){
        this.registerService.loadCompanyForRegistrationByDomain(window.location.hostname, (response) => {

            let maintenance: number = response.maintenance;

            if (maintenance == 5 && this.contextService.isPublicMember()) //maintenance for public users
                this.router.navigate(['login']);

            if (maintenance > 0 && maintenance < 5)//if maintenance is not for public users 5 or 0
                this.router.navigate(['maintenance']);

            if ((maintenance == 0 || maintenance == 5) && this.router.url == "/maintenance")
                this.router.navigate(['/']);
        });
    }

    private setupTagBrodcast(): void {
        this.router.events.forEach(item => {
            if (item instanceof NavigationEnd) {
              const gtmTag = {
                event: 'page',
                pageName: item.url
              };

              setTimeout(() => this.gtmService.pushTag(gtmTag), 300);
            }
        });
    }
}
