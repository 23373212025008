export class Constants {
    public static readonly auth = '/erp-auth/';
    public static readonly codebooks = '';
    public static readonly mlm = '/mlm/api/v1/';
    public static readonly resources = '/';
    public static readonly versionStorageName = 'club_appVersion';
}

export class SearchOperations {
    public static readonly EQUALITY = '::';
    public static readonly NEGATION = '!!';

    public static readonly GREATER_THAN = '>>';
    public static readonly GREATER_THAN_OR_EQUAL = '~:';
    public static readonly LESS_THAN = '<<';
    public static readonly LESS_THAN_OR_EQUAL = ':~';

    public static readonly LENGTH = '~;';
    public static readonly LIKE = '~~';
    public static readonly NOT_LIKE = '~!';
    public static readonly START_WITH = '~<';
    public static readonly END_WITH = '~>';

    public static readonly IN = '><';

    public static readonly SPLIT_JOIN_OPERATOR = '__';
    public static readonly SPLIT_SINGLE_OPERATION = ';;';

    public static readonly LEFT_PARENTHESES_READABLE = '((';
    public static readonly RIGHT_PARENTHESES_READABLE = '))';

    public static readonly KEY_AND = '-AND-';
    public static readonly KEY_OR = '-OR-';
    public static readonly KEY_NULL = 'NULL';
    public static readonly KEY_SEARCH = 'search';
}

