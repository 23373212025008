import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { HttpRequestType, RequestService } from '../../../../core/request.service';

// import { SelectItem } from 'primeng/primeng';
import { Profile, MemberLoyaltyProgram } from './profile.model';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    public places: Array<any> = [];
    public mlmProgression: boolean;
    public mlmLevel: string;
    public mlmLevelCode: string;
    memberLoyaltyProgram: MemberLoyaltyProgram[];

    public visibleProfileData: any =
        [
            {
                "companyId": 1,
                "profileFields": [
                    {
                        "field": "email",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "dateRegistered",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "defaultLocale",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "memberNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "mlmLevel",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "leader",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "hasAccession",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "hasContract",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "firstName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "parentName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "lastName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "gender",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthDate",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthPlace",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "personalIdentificationNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "idNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "passportNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "bankAccount",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "bankAccountParty",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "place",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "zipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "address",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneMobile",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneHome",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneWork",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryCity",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryZipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryStreet",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryStreetNo",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryFloor",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryFlatNo",
                        "visible": true,
                        "default": null
                    }
                ]
            },
            {
                "companyId": 2,
                "profileFields": [
                    {
                        "field": "email",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "dateRegistered",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "defaultLocale",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "memberNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "mlmLevel",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "leader",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "hasAccession",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "hasContract",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "firstName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "parentName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "lastName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "gender",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthDate",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthPlace",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "personalIdentificationNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "idNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "passportNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "bankAccount",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "bankAccountParty",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "place",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "zipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "address",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneMobile",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneHome",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneWork",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryCity",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryZipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryStreet",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryStreetNo",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryFloor",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryFlatNo",
                        "visible": true,
                        "default": null
                    }
                ]
            },
            {
                "companyId": 3,
                "profileFields": [
                    {
                        "field": "email",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "dateRegistered",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "defaultLocale",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "memberNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "mlmLevel",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "leader",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "hasAccession",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "hasContract",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "firstName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "parentName",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "lastName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "gender",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "birthDate",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthPlace",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "personalIdentificationNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "idNumber",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "passportNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "bankAccount",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "bankAccountParty",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "place",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "zipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "address",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneMobile",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneHome",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneWork",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryCity",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryZipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryStreet",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryStreetNo",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryFloor",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryFlatNo",
                        "visible": true,
                        "default": null
                    }
                ]
            },
            {
                "companyId": 4,
                "profileFields": [
                    {
                        "field": "email",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "dateRegistered",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "defaultLocale",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "memberNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "mlmLevel",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "leader",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "hasAccession",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "hasContract",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "firstName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "parentName",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "lastName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "gender",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "birthDate",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthPlace",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "personalIdentificationNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "idNumber",
                        "visible": false,
                        "default": null
                    },
                    {
                        "field": "passportNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "bankAccount",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "bankAccountParty",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "place",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "zipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "address",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneMobile",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneHome",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneWork",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryCity",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryZipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryStreet",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryStreetNo",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryFloor",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryFlatNo",
                        "visible": true,
                        "default": null
                    }
                ]
            },
            {
                "companyId": 5,
                "profileFields": [
                    {
                        "field": "email",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "dateRegistered",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "defaultLocale",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "memberNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "mlmLevel",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "leader",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "hasAccession",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "hasContract",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "firstName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "parentName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "lastName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "gender",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthDate",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthPlace",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "personalIdentificationNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "idNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "passportNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "bankAccount",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "bankAccountParty",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "place",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "zipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "address",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneMobile",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneHome",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneWork",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryCity",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryZipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryStreet",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryStreetNo",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryFloor",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryFlatNo",
                        "visible": true,
                        "default": null
                    }
                ]
            },
            {
                "companyId": 6,
                "profileFields": [
                    {
                        "field": "email",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "dateRegistered",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "defaultLocale",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "memberNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "mlmLevel",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "leader",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "hasAccession",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "hasContract",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "firstName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "parentName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "lastName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "gender",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthDate",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthPlace",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "personalIdentificationNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "idNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "passportNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "bankAccount",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "bankAccountParty",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "place",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "zipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "address",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneMobile",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneHome",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneWork",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryCity",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryZipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryStreet",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryStreetNo",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryFloor",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryFlatNo",
                        "visible": true,
                        "default": null
                    }
                ]
            },
            {
                "companyId": 12,
                "profileFields": [
                    {
                        "field": "email",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "dateRegistered",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "defaultLocale",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "memberNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "mlmLevel",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "leader",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "hasAccession",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "hasContract",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "firstName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "parentName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "lastName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "gender",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthDate",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthPlace",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "personalIdentificationNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "idNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "passportNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "bankAccount",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "bankAccountParty",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "place",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "zipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "address",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneMobile",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneHome",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneWork",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryCity",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryZipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryStreet",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryStreetNo",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryFloor",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryFlatNo",
                        "visible": true,
                        "default": null
                    }
                ]
            },
            {
                "companyId": 13,
                "profileFields": [
                    {
                        "field": "email",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "dateRegistered",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "defaultLocale",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "memberNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "mlmLevel",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "leader",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "hasAccession",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "hasContract",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "firstName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "parentName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "lastName",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "gender",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthDate",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "birthPlace",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "personalIdentificationNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "idNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "passportNumber",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "bankAccount",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "bankAccountParty",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "place",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "zipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "address",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneMobile",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneHome",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "phoneWork",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryCity",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryZipCode",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryStreet",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryStreetNo",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryFloor",
                        "visible": true,
                        "default": null
                    },
                    {
                        "field": "deliveryFlatNo",
                        "visible": true,
                        "default": null
                    }
                ]
            }
        ];

    private readonly url: string = '/mlm/api/v1/members';
    private readonly vipUrl: string = '/codebooks/api/v1/companies';

    constructor(private requestService: RequestService, private translateService: TranslateService) { }

    public getProfileDetails(onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Get, this.url + '/details', null, null, (profile: Profile) => {
            onSuccess(profile);
            this.mlmProgression = profile.mlmProgression; // ovo je VIP
            this.mlmLevel = profile.mlmLevel;
            this.mlmLevelCode = profile.mlmLevelCode;
        });
    }

    public getVip(companyId: number, onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Get, `${this.vipUrl}/${companyId}/configuration/SEND_VIP_INIVTATION`, null, null, (res: any) => {
            onSuccess(res);
        });
    }

    public getMlmLevelReferral(companyId: number, onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Get, `${this.vipUrl}/${companyId}/configuration/MLM_LEVEL_REFERRAL`, null, null, (res: any) => {
            onSuccess(res);
        });
    }

    public updateProfileDetails(profile: Profile, onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Put, this.url + '/details', profile, null, () => {
            onSuccess();
        });
    }

    public resetPassword(email: string, onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Post, '/codebooks/api/v1/password-reset', { email: email }, null, () => {
            onSuccess();
        });
    }

    public getLoyaltyProgramDetails(onSuccess: Function): void {
        if(this.memberLoyaltyProgram)
            onSuccess(this.memberLoyaltyProgram);
        else
            this.requestService.createRequest(HttpRequestType.Get, this.url + '/loyalty-programs/calculation', null, null, (res: MemberLoyaltyProgram[]) => {
                this.memberLoyaltyProgram = res;
                onSuccess(res);
            });
    }

    public loadZipCodes(companyId: number, onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Get, `/codebooks/api/v1/zip-codes/${companyId}`, null, null, (places: any) => {
            this.places.push({ value: null, label: '' });

            for (let place of places)
                this.places.push({ value: place.code, label: place.place + ' - ' + place.code });

            onSuccess();
        });
    }
}
