import { Component, OnInit, Input } from '@angular/core';
import { Pager } from '../../inside-layout/routes/shop/shop.models';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
    @Input('pagination') pagination: Pager;

    constructor() { }

    ngOnInit(): void {
    }

}
