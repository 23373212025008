<div class="mat-typography">
  <ng-container *ngIf="showFirstQuestion">
    <h2 mat-dialog-title>{{ 'SHOP.SURVEY_MODAL_QUESTION' | translate }}</h2>
  </ng-container>
  <ng-container *ngIf="!showFirstQuestion">
    <h2 mat-dialog-title>{{ data.message }}</h2>

    <mat-dialog-content class="mat-typography mx-auto px-0">
      <div class="row my-2">
        <div class="d-flex border-bottom py-1 flex-sm-row flex-column pl-4" *ngFor="let product of data.options">
          <div class="d-flex justify-content-start align-items-center fs-8">
            <mat-checkbox (change)="checkBoxChange($event, product)" class="py-2" #checkbox [color]="'accent'">
              <span>
                {{ product.productName }} ( {{ product.priceCatalogue }} {{ contextService.localCurr }}
                <span *ngIf="altCurrency && altCurrencyExchangeRate" class="alt-price shortAnim text-pink">
                  / {{(product.priceCatalogue / altCurrencyExchangeRate) | number:'1.2-2'}} {{this.altCurrency}}
                </span> )
              </span>

            </mat-checkbox>
          </div>
          <div class="ml-3" [@inAnimation]>
            <input *ngIf="checkbox.checked" #inputValue="ngModel" type="number" class="form-control"
              [placeholder]="'ORDER.ENTER-QUANTITY' | translate" [(ngModel)]="product.selectedQuantity">
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </ng-container>

  <div mat-dialog-actions align="end">
    <button type="button" mat-stroked-button class="pink-black-btn neutral smaller-mobile" (click)="closeDialog()">
      {{ 'MODAL.CLOSE' | translate}}
    </button>
    <button type="button" mat-stroked-button class="pink-black-btn smaller-mobile" (click)="confirmDialog()">
      {{ 'MODAL.CONFIRM' | translate}}
    </button>
  </div>

</div>