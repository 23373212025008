<div class="container korpica-modal-02 drop-shadow">
    <div class="row">
        <div class="col-md-4 mt-4">
            <h2 class="m-0">{{ 'ORDER.ORDER' | translate }} {{orderDc.id}}</h2>
        </div>
        <div class="col-md-8 mt-4 korpica-modal-02-buttons">
            <a mat-flat-button (click)="closeModal()" class="btn">{{ orderDc.status == 'DRAFT' && orderDc.campaign ==
                contextService.profile.currentCampaignId ? ('MODAL.CANCEL' | translate) : ('MODAL.CLOSE' | translate)
                }}</a>
            <button *ngIf="orderDc.status == 'DRAFT' && orderDc.campaign == contextService.profile.currentCampaignId"
                mat-flat-button color='accent'
                [disabled]="displayContract && !acceptContract || loadingService.unfinishedRequests"
                (click)="submitOrder()" class="btn btn-nastavi shortAnim text-center noselect">{{ 'MODAL.SUBMIT_ORDER' |
                translate }}</button>
        </div>
    </div>
    <!-- <div class="row">
      <div class="animate__animated animate__fadeIn animate__faster bg-pink col-12 mb-1 py-1 text-center text-white">
        <span class="d-block" *ngIf="rebateMessage">
          {{ rebateMessage }}
        </span>
        <span class="d-block" *ngIf="orderDc.status == 'DRAFT'">
          {{ 'SHOP.REBATE_ORDER_ADDITIONAL_MESSAGE' | translate }}
        </span>
      </div>
    </div> -->
    <div class="row">
        <div class="animate__animated animate__fadeIn animate__faster bg-pink col-12 mb-1 py-1 text-center text-white">
            <span class="d-block" *ngIf="rebateMessage">
                {{ rebateMessage }}
            </span>
            <span class="d-block" *ngIf="!contextService.isTempMember() && orderDc.status == 'DRAFT'">
                {{ 'SHOP.REBATE_ORDER_ADDITIONAL_MESSAGE' | translate }}
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <!-- data row -->
            <div class="korpica-modal-02-data-row">
                <div class="korpica-modal-02-data-row-title">
                    {{ 'ORDER.SHIPPINGNAME' | translate }}:
                </div>
                <div class="korpica-modal-02-data-row-data">
                    {{orderDc.shippingName}}
                </div>
            </div>
            <!-- data row END -->
            <!-- data row -->
            <div class="korpica-modal-02-data-row">
                <div class="korpica-modal-02-data-row-title">
                    {{ 'ORDER.SHIPPINGADDRESS' | translate }}:
                </div>
                <div class="korpica-modal-02-data-row-data">
                    {{orderDc.shippingAddress}} {{orderDc.shippingAddressNo}}
                </div>
            </div>
            <!-- data row END -->
            <!-- data row -->
            <div class="korpica-modal-02-data-row">
                <div class="korpica-modal-02-data-row-title">
                    {{ 'ORDER.SHIPPINGCITY' | translate }}:
                </div>
                <div class="korpica-modal-02-data-row-data">
                    {{orderDc.shippingCity}}, <span
                        clss="korpica-modal-02-data-row-data-postal-code">{{orderDc.shippingZip}}</span>
                </div>
            </div>
            <!-- data row END -->
            <!-- data row -->
            <div class="korpica-modal-02-data-row">
                <div class="korpica-modal-02-data-row-title">
                    {{ 'ORDER.PAYMENTTYPE' | translate }}:
                </div>
                <div class="korpica-modal-02-data-row-data">
                    <div [ngClass]="{'pouzecem': orderDc.paymentType.toUpperCase() == 'POUZECEM', 'ziralno': orderDc.paymentType.toUpperCase() == 'ZIRALNO'}"
                        class="korpica-modal-02-data-row-data-nacin-placanja-value">
                        {{ 'PAYMENTTYPE.' + orderDc.paymentType.toUpperCase() | translate }}
                    </div>
                </div>
            </div>
            <ng-container *ngIf="orderDc.deliveryProviderId">
                <div class="korpica-modal-02-data-row">
                    <div class="korpica-modal-02-data-row-title">
                        {{ 'ORDER.DELIVERY-PROVIDER' | translate }}:
                    </div>
                    <div class="korpica-modal-02-data-row-data">
                        <div class="korpica-modal-02-data-row-data-kurirska-sluzba-value">
                            {{ findDeliveryProviderTitle(orderDc.deliveryProviderId) }}
                        </div>
                    </div>
                </div>
                <div *ngIf="orderDc.deliveryProviderParcelMachineId" class="korpica-modal-02-data-row">
                    <div class="korpica-modal-02-data-row-title">
                        {{ 'ORDER.PARCEL-MACHINE' | translate }}:
                    </div>
                    <div class="korpica-modal-02-data-row-data">
                        <div class="korpica-modal-02-data-row-data-paketomat-value">
                            {{ findDeliveryProviderParcelMachineDescription(orderDc.deliveryProviderId,
                            orderDc.deliveryProviderParcelMachineId) }}
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- data row END -->
            <!-- data row -->
            <div class="korpica-modal-02-data-row korpica-modal-02-data-row-napomena">
                <div class="korpica-modal-02-data-row-title">
                    {{ 'ORDER.NOTE' | translate }}:
                </div>
                <div class="korpica-modal-02-data-row-data">
                    {{orderDc.note}}
                </div>
            </div>
            <!-- data row END -->
        </div>
        <div class="col-lg-6">
            <!-- data row -->
            <div class="korpica-modal-02-data-row">
                <div class="korpica-modal-02-data-row-title">
                    {{ 'ORDER.RETAILVALUE' | translate }}:
                </div>
                <div class="korpica-modal-02-data-row-data">
                    {{ orderDc.retailValue | number:'1.2-2' }} {{this.contextService.localCurr}}
                    <span *ngIf="altCurrency && altCurrencyExchangeRate" class="">({{ (orderDc.retailValue /
                        altCurrencyExchangeRate) | number: '1.2-2' }} {{altCurrency}})</span>
                </div>
            </div>
            <!-- data row END -->
            <!-- data row -->
            <div class="korpica-modal-02-data-row">
                <div class="korpica-modal-02-data-row-title">
                    {{ 'ORDER.REBATEVALUE' | translate }}:
                </div>
                <div class="korpica-modal-02-data-row-data">
                    {{ orderDc.rebateValue | number:'1.2-2' }} {{this.contextService.localCurr}}
                    <span *ngIf="altCurrency && altCurrencyExchangeRate" class="">({{ (orderDc.rebateValue /
                        altCurrencyExchangeRate) | number: '1.2-2' }} {{altCurrency}})</span>
                </div>
            </div>
            <!-- data row END -->
            <!-- data row -->
            <div class="korpica-modal-02-data-row">
                <div class="korpica-modal-02-data-row-title">
                    {{ 'ORDER.INVOICEVALUE' | translate }}:
                </div>
                <div class="korpica-modal-02-data-row-data korpica-modal-02-data-row-data-fakturna-vrijednost-value">
                    {{ orderDc.invoiceValue | number:'1.2-2' }} {{this.contextService.localCurr}}
                    <span *ngIf="altCurrency && altCurrencyExchangeRate" class="alt-price-total">({{
                        (orderDc.invoiceValue / altCurrencyExchangeRate) | number: '1.2-2' }} {{altCurrency}})</span>
                </div>
            </div>
            <!-- data row END -->
            <!-- data row -->
            <div class="korpica-modal-02-data-row">
                <div class="korpica-modal-02-data-row-title">
                    {{ 'ORDER.SERVICEITEMS' | translate }}:
                </div>
                <div class="korpica-modal-02-data-row-data">
                    {{ orderDc.serviceValue | number:'1.2-2' }} {{this.contextService.localCurr}}
                    <span *ngIf="altCurrency && altCurrencyExchangeRate" class="">({{ (orderDc.serviceValue /
                        altCurrencyExchangeRate) | number: '1.2-2' }} {{altCurrency}})</span>
                </div>
            </div>
            <!-- data row END -->
            <!-- data row -->
            <div class="korpica-modal-02-data-row">
                <div class="korpica-modal-02-data-row-title">
                    {{ 'ORDER.SERVICEVAT' | translate }}:
                </div>
                <div class="korpica-modal-02-data-row-data">
                    {{ orderDc.serviceVAT | number:'1.2-2' }} {{this.contextService.localCurr}}
                    <span *ngIf="altCurrency && altCurrencyExchangeRate" class="">({{ (orderDc.serviceVAT /
                        altCurrencyExchangeRate) | number: '1.2-2' }} {{altCurrency}})</span>
                </div>
            </div>
            <!-- data row END -->
            <!-- data row -->
            <div class="korpica-modal-02-data-row">
                <div class="korpica-modal-02-data-row-title">
                    {{ 'ORDER.SERVICETOTAL' | translate }}:
                </div>
                <div class="korpica-modal-02-data-row-data korpica-modal-02-data-row-data-troskovi-ukupno-value">
                    {{ orderDc.serviceTotal | number:'1.2-2' }} {{this.contextService.localCurr}}
                    <span *ngIf="altCurrency && altCurrencyExchangeRate" class="alt-price-total">({{
                        (orderDc.serviceTotal / altCurrencyExchangeRate) | number: '1.2-2' }} {{altCurrency}})</span>
                </div>
            </div>
            <!-- data row END -->
            <!-- data row -->
            <div class="korpica-modal-02-data-row">
                <div class="korpica-modal-02-data-row-title">
                    {{ 'ORDER.TOTAL_VAT' | translate }}:
                </div>
                <div class="korpica-modal-02-data-row-data korpica-modal-02-data-row-data-ukupno-value">
                    <strong>{{ orderDc.total | number:'1.2-2' }} {{this.contextService.localCurr}}</strong>
                    <span *ngIf="altCurrency && altCurrencyExchangeRate" class="alt-price-total">({{ (orderDc.total /
                        altCurrencyExchangeRate) | number: '1.2-2' }} {{altCurrency}})</span>
                </div>
            </div>
            <!-- data row END -->
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <hr>
            <h5>{{ 'ORDER.ITEMS' | translate }}</h5>
            <div class="table-responsive">
                <table class="table table-striped table-farmacy">
                    <thead>
                        <tr>
                            <th>{{ 'ORDER.CUITEMS.PRODUCTID' | translate }}</th>
                            <th>{{ 'ORDER.CUITEMS.NAME' | translate }}</th>
                            <th>Brend</th>
                            <th>{{ 'ORDER.CUITEMS.PRICE' | translate }}</th>
                            <th>{{ 'ORDER.CUITEMS.QUANTITY' | translate }}</th>
                            <th>{{ 'ORDER.CUITEMS.REBATEPERCENTE' | translate }}</th>
                            <th>{{ 'ORDER.CUITEMS.INVOICEPRICE' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of orderDc?.items">
                            <td>{{ item.productId }}</td>
                            <td>{{ item.productName }}</td>
                            <td>{{ item.productBrand }}</td>
                            <td>
                                {{ item.retailPrice }} {{ contextService.localCurr }}
                                <span *ngIf="altCurrency && altCurrencyExchangeRate" class="">({{ (item.retailPrice /
                                    altCurrencyExchangeRate) | number: '1.2-2' }} {{altCurrency}})</span>
                            </td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ item.rebatePercent }}%</td>
                            <td>
                                {{ (item.invoicePrice * item.quantity) | number:'1.2-2' }} {{ contextService.localCurr
                                }}
                                <span *ngIf="altCurrency && altCurrencyExchangeRate" class="">({{ (item.invoicePrice *
                                    item.quantity / altCurrencyExchangeRate) | number: '1.2-2' }}
                                    {{altCurrency}})</span>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ 'GENERAL.FOOTER-TOTAL' | translate }}: {{ orderDc?.items?.length }}</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <h5>{{ 'ORDER.SERVICEITEMS' | translate }}</h5>
            <div class="table-responsive">
                <table class="table table-striped table-farmacy">
                    <thead>
                        <tr>
                            <th>{{ 'ORDER.CUITEMS.PRODUCTID' | translate }}</th>
                            <th>{{ 'ORDER.CUITEMS.NAME' | translate }}</th>
                            <th>{{ 'ORDER.CUITEMS.PRICE' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of orderDc.serviceItems">
                            <td>{{ item.serviceId }}</td>
                            <td>{{ item.description }}</td>
                            <td>
                                {{ item.price | number:'1.2-2' }} {{ contextService.localCurr }}
                                <span *ngIf="altCurrency && altCurrencyExchangeRate" class="">({{ (item.price /
                                    altCurrencyExchangeRate) | number: '1.2-2' }} {{altCurrency}})</span>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ 'GENERAL.FOOTER-TOTAL' | translate }}: {{ orderDc?.serviceItems?.length }}</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <hr>
    <div *ngIf="showOrderNotice" class="order-notice">
        {{ 'MODAL.ORDER_NOTICE' | translate }}<br>
        {{ 'MODAL.ORDER_NOTICE_2' | translate }}<br>
    </div>
    <div class="row">
        <div *ngIf="displayContract" class="pull-left" style="margin-bottom: 15px">
            {{ 'ORDER.ACCEPT-CONTRACT' | translate }}
            <mat-checkbox [(ngModel)]="acceptContract" [color]="'accent'"></mat-checkbox>
            <span (click)="openContractModal()" style="margin-left: 5px; cursor: pointer;"><u>({{ 'ORDER.VIEW-CONTRACT'
                    | translate }})</u></span>
        </div>
    </div>
</div>
<app-first-order-submit-modal *ngIf="showFirstOrderModal" class="animate__slideInLeft animate__animated animate__faster"
    (closeFirstOrderModalEvent)="showFirstOrderModal = false"></app-first-order-submit-modal>
<app-contract-modal *ngIf="displayContractModal" class="animate__slideInLeft animate__animated animate__faster"
    (closeContractModalEvent)="displayContractModal = false" [orderDc]="orderDc"></app-contract-modal>

<app-banners *ngIf="orderDc.status == 'DRAFT' && orderDc.campaign == contextService.profile.currentCampaignId"
    [promoBannerLocationType]="PromoBannerLocationType[PromoBannerLocationType.ORDER_VIEW]"></app-banners>
<app-first-order-public-modal *ngIf="showFirstOrderModalForPublic"
    class="animate__slideInLeft animate__animated animate__faster"
    (closeFirstOrderPublicModalEvent)="showFirstOrderModalForPublic = false"></app-first-order-public-modal>