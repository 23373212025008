import { Injectable } from '@angular/core';
import { HttpRequestType, RequestService } from './request.service';
import { Context } from './core.models';
import { TranslatorService } from './translator.service';
import { Observer, Observable } from 'rxjs';
import { CompanyInfo, RebateScale, RebateScaleTranslation } from '../inside-layout/routes/cart/cart.model';
import { AuthService } from './auth.service';
import { LoadingService } from './loading.service';

@Injectable({
    providedIn: 'root'
})
export class ContextService {
    public context: Context;
    public profile: any = {};
    public contextObservable: Observable<any>;
    public profileObservable: Observable<any>;
    public curr = {  // currency
        loc: '',
        iso: ''
    };
    public localCurr: string;
    public altCurr: string;
    public altCurrExchangeRate: number;
    public companyId: number;
    public memberNumber: string;
    private contextObserver: Observer<any>;
    private profileObserver: Observer<any>;
    public rebateScales: RebateScale[];
    public publicMember: boolean;
    public mlmLevelCodeReferral: any;

    public companyInfo: CompanyInfo[] = [
        {
            companyId: 1,
            contacts: {
                fb: 'farmasibih',
                ig: 'farmasibh',
                phoneNumber: '033/714-800'
            }
        },
        {
            companyId: 2,
            contacts: {
                fb: 'farmasirs',
                ig: 'farmasirs',
                phoneNumber: '381/11-2603-280'
            }
        },
        {
            companyId: 6,
            contacts: {
                fb: 'farmasime',
                ig: 'farmasime',
                phoneNumber: '+382 (0) 20 223-863'
            }
        },
        {
            companyId: 3,
            contacts: {
                fb: 'farmasihr',
                ig: 'farmasihr',
                phoneNumber: '+385 91 1888 060'
            }
        },
        {
            companyId: 12,
            contacts: {
                fb: 'farmasialb',
                ig: 'farmasialbania',
                phoneNumber: '+355 69 7560831'
            }
        },
        {
            companyId: 13,
            contacts: {
                fb: 'farmasikosovo',
                ig: 'farmasikosovo',
                phoneNumber: '+383 49 410 331'
            }
        },
        {
            companyId: 5,
            contacts: {
                fb: 'FarmasiMK',
                ig: 'farmasimacedonia',
                phoneNumber: '078/443-865'
            }
        },
        {
            companyId: 4,
            contacts: {
                fb: 'farmasi.si',
                ig: 'farmasislovenia',
                phoneNumber: '+386 8 20 16 448'
            },
            details: {
                name: 'FARMASI d.o.o.',
                address: 'Ulica 1. junija',
                addressNo: '4',
                zip: '1420',
                place: 'Trbovlje',
                country: 'Slovenija',
                vatId: 'SI49623095',
                registrationAuthority: 'Okrožno sodišče Kranj, vpsina št. 2014/57636',
                registrationNo: '6747531000',
                iban: 'SI56 6100 0001 7693 147',
                phone: '08 201 64 48',
                mobile: '041 354 361',
                email: 'info@farmasi.si',
                url: 'www.farmasi.si'
            }
        }
    ]

    private readonly url: string = '/codebooks/api/v1/context';
    private readonly urlProfile: string = '/mlm/api/v1/profile';
    private readonly rebateUrl: string = '/mlm/api/v1/companies/';
    private readonly ALT_CURRENCY_KEY: string = 'altCurrency'
    private readonly ALT_CURRENCY_EXCHANGE_RATE_KEY: string = 'altCurrencyExchangeRate'

    constructor(private requestService: RequestService, private translatorService: TranslatorService, private authService: AuthService, public loadingService: LoadingService) {
        this.contextObservable = new Observable(contextObserver => this.contextObserver = contextObserver);
        this.profileObservable = new Observable(profileObserver => this.profileObserver = profileObserver);

        this.getCompanyId();
        if (this.authService.isTokenSet())
            this.getFromLocal();
    }

    public getContext(): void {
        this.requestService.createRequest(HttpRequestType.Get, this.url, null, null, (context: Context) => {
            this.context = context;
            this.translatorService.useLanguage(context.locale);

            if (this.contextObserver != null) {
                this.contextObserver.next(this.context);
                this.contextObserver.complete();
            }

            if(!localStorage.getItem("member_type"))
              this.setMemberType(context.firstName);

        });
    }

    public formatDateISO(date: any): string {
        if (date == null || date == '')
            return '';

        if (typeof date == 'string')
            return date.substr(6, 4) + '-' + date.substr(3, 2) + '-' + date.substr(0, 2);

        return date.toISOString();
    }

    public getProfile(onSuccess: Function = null): void {
        this.requestService.createRequest(HttpRequestType.Get, this.urlProfile, null, null, (profile: any) => {
            this.profile.currentCampaignInfo = profile.currentCampaignInfo;
            this.profile = profile;
            this.profile.profileImageUrl = this.profile.profileImageUrl ? this.profile.profileImageUrl : 'assets/images/user.png';

            localStorage.setItem("local_currency", profile.localCurrencyCode);
            this.localCurr = profile.localCurrencyCode;

            localStorage.setItem("member_number", profile.memberNumber);
            this.memberNumber = profile.memberNumber;

            if(profile.memberType === 'PUBLIC_TEMP')
              profile.userProfile.firstName = "Public- TEMP";

            this.setMemberType(profile.userProfile.firstName);
            this.profileObservable = new Observable(profileObservable => this.profileObserver = profileObservable);

            if (this.profileObserver != null) {
                this.profileObserver.next(this.profile);
                this.profileObserver.complete();
            }

            if (onSuccess != null)
                onSuccess(profile);
        });
    }

    private setMemberType(name: any) {
        if (name === "Public")
            localStorage.setItem("member_type", "public");
        else if (name === "Public- TEMP")
            localStorage.setItem("member_type", "public-temp");
        else
            localStorage.setItem("member_type", "private");
    }

    public getRebateScales(productBrand: string, userType: string): Promise<RebateScale[]> {
        // uradjeno bez registerservice za companyId
        const url = this.rebateUrl + this.getCompanyId() + `/rebate-scale`;
        return new Promise((resolve, reject) => {
            if (this.rebateScales?.length > 0)
                resolve(this.rebateScales.filter(sc => sc.productBrand === productBrand && sc.userTypeRabate === userType))
            else
                this.requestService.createRequest(HttpRequestType.Get, url, null, null, (scales: any) => {
                    this.rebateScales = scales['items'];
                    resolve(this.rebateScales.filter(sc => sc.productBrand === productBrand && sc.userTypeRabate === userType));
                });
        })
    }

    public getRebateMessage(total: number, mlmLevelCode: string, productBrand = "FARMASI"): Promise<RebateScaleTranslation | null> {
        return new Promise(async resolve => {
            const userType = this.isPublicMember() ? "PUBLIC" 
                                                   : (mlmLevelCode === "0" ? "MEMBER" 
                                                                           : mlmLevelCode === "10" ? "JUNIOR_LEADER"
                                                                                                   : "LEADER");

            
            let rs = await this.getRebateScales(productBrand, userType);
            console.log(rs);
            for (let rebate of rs) {
                if (total < rebate.valueTo && total > rebate.valueFrom)
                    for (let rebate1 of rs)
                        if (rebate1.valueFrom == rebate.valueTo + 0.01) {
                            let zeroValueFrom = rs.find(r => r.valueFrom == 0 || r.valueFrom == 0.01);
                            let numberOfSameRebateValue = rs.filter(rs => rs.rebate == rebate1.rebate).length;
                            if (
                                // ne prikazati bilo kojem korisnuku ako je uvijek u skalama isti rabat i veci je od pocetnog
                                numberOfSameRebateValue == rs.length - 1 && total > zeroValueFrom.valueTo
                            ) {
                                resolve(null)
                                return;
                            }

                            let response: RebateScaleTranslation = {
                                orderMore: (rebate1.valueFrom - total).toFixed(2),
                                curr: localStorage.getItem("local_currency") ?? '',
                                rebate: rebate1.rebate
                            }

                            if (this.altCurr && this.altCurrExchangeRate) {
                                response.altCurrency = this.altCurr;
                                response.altCurrencyExchangeRate = this.altCurrExchangeRate;
                                response.altOrderMore = ((rebate1.valueFrom - total) / this.altCurrExchangeRate).toFixed(2);
                            }

                            resolve(response)
                        }
            }
            resolve(null);
        })
}

    public isPublicMember(): boolean {
        const member = localStorage.getItem("member_type");
        return member != null && (member == 'public' || member == 'public-temp');
    }

    public isTempMember(): boolean {
        const member = localStorage.getItem("member_type");
        return member != null && member == 'public-temp';
    }

    public clearData(): void {
        this.context = null;
        this.profile = {};
    }

    public getName(): string {
        return this.context != null ? this.context.name : '';
    }

    public getFirstName(): string {
        return this.context != null ? this.context.firstName : '';
    }

    public getFromLocal() {
        let localCurrLocal = localStorage.getItem("local_currency");
        if (localCurrLocal != null)
            this.localCurr = localCurrLocal;
        else
            this.getProfile();

        let memberNumberLocal = localStorage.getItem("member_number");
        if (memberNumberLocal != null)
            this.memberNumber = memberNumberLocal;
        else
            this.getProfile();
    }

    public getCompanyInfo() {
        let index = this.companyInfo.findIndex(el => el.companyId === this.getCompanyId());
        return this.companyInfo[index];
    }

    public getmlmLevelCodeReferral(onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Get, `/codebooks/api/v1/companies/${this.getCompanyId()}/configuration/MLM_LEVEL_REFERRAL`, null, null, (res: any) => {
            onSuccess(res);
        });
    }

    public getMlmLevelReferral(onSuccess: Function): void {
        this.requestService.createRequest(HttpRequestType.Get, `/codebooks/api/v1/companies/${this.getCompanyId()}/configuration/MLM_LEVEL_REFERRAL`, null, null, (res: any) => {
            onSuccess(res);
        });
    }

    public getCompanyAltCurrency(onSuccess: Function): void {
        // this.altCurr = 'EUR';
        // onSuccess({ companyId: this.getCompanyId(), altCurrency: 'EUR' })
        // return;


        if (this.altCurr) {
            onSuccess({ companyId: this.getCompanyId(), altCurrency: this.altCurr });
            return;
        }

        if (!!localStorage.getItem(this.ALT_CURRENCY_KEY)) {
            let altCurr = JSON.parse(localStorage.getItem(this.ALT_CURRENCY_KEY));

            if (altCurr.companyId === this.getCompanyId()) {
                this.altCurr = altCurr.altCurrency;
                onSuccess(altCurr);
                return;
            }
        }

        this.requestService.createRequest(HttpRequestType.Get, `/codebooks/api/v1/companies/${this.getCompanyId()}/configuration/ALT_CURRENCY`, null, null, (res: any) => {
            let altCurr = { companyId: this.getCompanyId(), altCurrency: res.value };
            localStorage.setItem(this.ALT_CURRENCY_KEY, JSON.stringify(altCurr))
            this.altCurr = altCurr.altCurrency;
            onSuccess(altCurr);
        }, (err) => {
            let altCurr = { companyId: this.getCompanyId(), altCurrency: null };
            localStorage.setItem(this.ALT_CURRENCY_KEY, JSON.stringify(altCurr))
            this.altCurr = altCurr.altCurrency;
            onSuccess(altCurr);
        });
    }

    public getCompanyAltCurrencyExchangeRate(onSuccess: Function): void {
        // this.altCurrExchangeRate = 1.96
        // onSuccess({ companyId: this.getCompanyId(), altCurrencyExchangeRate: 1.96 })
        // return;

        if (this.altCurrExchangeRate) {
            onSuccess({ companyId: this.getCompanyId(), altCurrencyExchangeRate: this.altCurrExchangeRate })
            return;
        }

        if (!!localStorage.getItem(this.ALT_CURRENCY_EXCHANGE_RATE_KEY)) {
            let altCurr = JSON.parse(localStorage.getItem(this.ALT_CURRENCY_EXCHANGE_RATE_KEY));

            if (altCurr.companyId === this.getCompanyId()) {
                this.altCurrExchangeRate = altCurr.altCurrencyExchangeRate;
                onSuccess(altCurr);
                return;
            }
        }

        this.requestService.createRequest(HttpRequestType.Get, `/codebooks/api/v1/companies/${this.getCompanyId()}/configuration/ALT_CURRENCY_EXCH_RATE`, null, null, (res: any) => {
            let altCurr = { companyId: this.getCompanyId(), altCurrencyExchangeRate: parseFloat(res.value) };
            localStorage.setItem(this.ALT_CURRENCY_EXCHANGE_RATE_KEY, JSON.stringify(altCurr))
            this.altCurrExchangeRate = altCurr.altCurrencyExchangeRate;
            onSuccess(altCurr);
        }, (err) => {
            let altCurr = { companyId: this.getCompanyId(), altCurrencyExchangeRate: null };
            localStorage.setItem(this.ALT_CURRENCY_EXCHANGE_RATE_KEY, JSON.stringify(altCurr))
            this.altCurrExchangeRate = altCurr.altCurrencyExchangeRate;
            onSuccess(altCurr);
        });
    }


    public getCompanyId() {
        switch (window.location.hostname) {
            case 'localhost':
            case 'ba.kb.eurobit.ba':
            case 'kb.eurobit.ba':
            case 'klub.farmasi.ba':
                return this.companyId = 1;

            case 'klub.farmasi.rs':
            case 'rs.kb.eurobit.ba':
                return this.companyId = 2;
            case 'klub.farmasi.hr':
            case 'hr.kb.eurobit.ba':
                return this.companyId = 3;
            case 'klub.farmasi.si':
            case 'si.kb.eurobit.ba':
                return this.companyId = 4;
            case 'mk.kb.eurobit.ba':
            case 'klub.farmasi.mk':
                return this.companyId = 5;
            case 'klub.farmasi.co.me':
            case 'me.kb.eurobit.ba':
                return this.companyId = 6;
            case 'klub.farmasi.al':
            case 'al.kb.eurobit.ba':
                return this.companyId = 12;
            case 'klub.farmasi.com.al':
            case 'ko.kb.eurobit.ba':
                return this.companyId = 13;
        }
    }

    public getCompanyUrlByIdProd(id: number): string {

        if (window.location.hostname.includes("kb"))
            return this.getCompanyUrlByIdDevEnv(id);

        if (window.location.hostname.includes("localhost") || window.location.hostname.includes("farmasi-webshop"))
            return window.location.origin.replace("http://", "");

        switch (id) {
            case 12:
                return 'klub.farmasi.al';
            case 1:
                return 'klub.farmasi.ba';
            case 3:
                return 'klub.farmasi.hr';
            case 13:
                return 'klub.farmasi.com.al';
            case 6:
                return 'klub.farmasi.co.me';
            case 5:
                return 'klub.farmasi.mk';
            case 2:
                return 'klub.farmasi.rs';
            case 4:
                return 'klub.farmasi.si';
        }
    }

    public getCompanyUrlByIdDevEnv(id: number): string {
        switch (id) {
            case 12:
                return 'al.new.kb.eurobit.ba';
            case 1:
                return 'kb.eurobit.ba';
            case 3:
                return 'hr.new.kb.eurobit.ba';
            case 13:
                return 'ko.new.kb.eurobit.ba';
            case 6:
                return 'me.new.kb.eurobit.ba';
            case 5:
                return 'mk.new.kb.eurobit.ba';
            case 2:
                return 'rs.new.kb.eurobit.ba';
            case 4:
                return 'si.new.kb.eurobit.ba';
        }
    }

}