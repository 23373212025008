import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { ShopService } from '../shop.service';
import { Product } from '../shop.models';
import { AlertService, ContextService } from 'src/app/core';
import { BasketService } from 'src/app/shared/basket.service';
import { inOutAnimation } from 'src/app/shared/animations';

@Component({
    selector: 'app-product-single',
    templateUrl: './product-single.component.html',
    styleUrls: ['./product-single.component.scss'],
    animations: [inOutAnimation]
})
export class ProductSingleComponent implements OnInit, OnDestroy {
    quantity: number = 1;
    product: any;
    edit: boolean;
    showIngredients: boolean = false;
    productId: string;
    // mode: number;
    quantityInBasket: number;
    orderId: any = null;
    subscribtion: Subscription;
    showAddedToCartText: boolean = false;
    imageLoaded = false;
    altCurrency: string;
    altCurrencyExchangeRate: number;
    showQuantityPerUnitPrice = false;

    constructor(
        private route: ActivatedRoute,
        private shopService: ShopService,
        private alertService: AlertService,
        public basketService: BasketService,
        public contextService: ContextService,
        public router: Router
    ) { }

    ngOnInit(): void {
        this.subscribtion = this.route.params.subscribe((params: Params) => {
            this.productId = params.productId;
            this.shopService.getProduct(this.productId, (response: Product) => {
                this.product = response;

                if(response.doppak !== undefined && response.doppak !== '' && response.doppak !== 'KOM')
                  this.showQuantityPerUnitPrice = true;

                this.quantityInBasket = this.basketService.getQuantityForProduct(this.product.productId);
                if (!this.router.url.includes('/catalogues/'))
                    window.scrollTo(0, 0);
            });
        });
        this.checkAltCurrency();
    }

    ngOnDestroy() {
        this.subscribtion.unsubscribe();
        // this.mode = null;
    }

    addToBasket() {
        if (this.product.kolicina < 1)
            return
        this.basketService.addOrUpdateProduct(this.product, this.basketService.getOrderId(), this.quantity);
        this.quantity = 1;
        this.quantityInBasket = this.basketService.getQuantityForProduct(this.product.productId);
        this.showAddedToCartText = true;
        setTimeout(() => {
            if (this.router.url.includes('catalogues/preview'))
                this.router.navigate(['catalogues/preview']);
            else if(this.router.url.includes('catalogues/nutriplus'))
                this.router.navigate(['catalogues/nutriplus']);
            else if(this.router.url.includes('catalogues/special-offers'))
                this.router.navigate(['catalogues/special-offers']);
            this.showAddedToCartText = false;
        }, 1500);
    }

    plus() {
        if (this.product.quantityOrderMax <= this.quantity) {
            this.alertService.error('SHOP.QUANTITY_ORDER_MAX');
            return;
        }
        this.quantity = this.quantity + 1;
    }

    minus() {
        if (this.quantity > 1)
            this.quantity = this.quantity - 1;
    }

    onKey() {
        if (this.product.quantityOrderMax < this.quantity) {
            this.quantity = this.product.quantityOrderMax;
            this.alertService.error('SHOP.QUANTITY_ORDER_MAX');
            return;
        }
        if (this.quantity < 0)
            this.quantity = 0;
    }
    goBack() {
        // this.currentUrl = this.router.url;
        // this.router.events.subscribe(event => {
        //     console.log(event);

        // if (event instanceof NavigationEnd) {  
        //     console.log(event);

        // };
        // });
        // TODO ovo ne radi kad se direkt ode na product single
        window.history.back();
    }

    checkAltCurrency() {
        this.contextService.getCompanyAltCurrency((altCurr: { companyId: string, altCurrency: string }) => {
            if (altCurr.altCurrency) {
                this.altCurrency = altCurr.altCurrency;

                console.log('getCompanyAltCurrency', altCurr)

                if (this.altCurrency)
                    this.contextService.getCompanyAltCurrencyExchangeRate((altCurrExchangeRate: { companyId: string, altCurrencyExchangeRate: number }) => {

                        console.log('getCompanyAltCurrencyExchangeRate', altCurrExchangeRate)
                        if (altCurrExchangeRate.altCurrencyExchangeRate)
                            this.altCurrencyExchangeRate = altCurrExchangeRate.altCurrencyExchangeRate;
                    })
            }
        })
    }
}
