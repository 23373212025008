import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';


declare let $: any;

@Injectable()
export class AlertService {
    constructor(private translateService: TranslateService, private toastr: ToastrService) { }
    
    public success(message: string, time: number = null): void {
        if (time == null) {
            if(message.length < 100)
                time = 3000;
            else
                time = message.length * 40;
        }
        this.toastr.success(this.translateService.instant(message), '', {
            timeOut: time
        });
    }

    public error(message: any, json: boolean = false, time: number = null, field = null): void {
        let detail = (json ? JSON.parse(message._body).error_description : message);
        if (time == null) {
            if(detail.length < 60)
                time = 3000;
            else
                time = detail.length * 60;
        }

        if(field != null){
            this.toastr.error(this.translateService.instant(message)+ ' ' + this.translateService.instant(field), '', {
                timeOut: time
            });
        }
        else{
            this.toastr.error(this.translateService.instant(message), '', {
                timeOut: time
            });
        }

       
    }

}
