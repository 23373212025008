import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { FormBuilder, FormGroup } from '@angular/forms';

import { CroppieOptions } from 'croppie';
import { CroppieDirective } from 'angular-croppie-module';
import { UserBlockService } from '../../user-block/user-block.service';
import { AppService } from 'src/app/app.service';
import { ContextService } from 'src/app/core';

@Component({
    selector: 'app-picture-upload',
    templateUrl: './picture-upload.component.html',
    styleUrls: ['./picture-upload.component.scss']
})
export class PictureUploadComponent implements OnInit {
    @Input('forma') editPictureForm: FormGroup;
    @Output() closeModalEvent = new EventEmitter();
    public editProfileImage: any;
    user: any;
    public profileTouched: boolean = false;
    public profileImg: string;
    public base64textStringProfile: string = "";
    public typeProfile: string = "";

    @ViewChild('croppie', {static: true}) croppieDirective: CroppieDirective;

    public croppieOptions: CroppieOptions = {
        boundary: { height: 300, width: 500 },
        viewport: { height: 300, width: 300, type: 'circle' },
        enforceBoundary: true,
        enableExif: false
    };

    modalRef: any;


    constructor(public userBlockService: UserBlockService, public appService: AppService, public formBuilder: FormBuilder, public contextService: ContextService) {
        this.user = {
            picture: 'assets/img/user.png'
        };
    }

    ngOnInit(): void {
        this.setEditPictureFormGroup();
        this.initializeCroppie();
        this.profileImg = this.contextService.profile.profileImageUrl == 'assets/img/user.png' ? '' : this.contextService.profile.profileImageUrl;        
    }

    userBlockIsVisible() {
        return this.userBlockService.getVisibility();
    }

    public setEditPictureFormGroup(): void {
        this.editPictureForm = this.formBuilder.group({
            'currentProfileImageURL': '',
            'editProfileImage': '',
        });

        this.editProfileImage = this.editPictureForm.controls['editProfileImage'];
    }

    _handleReaderLoadedProfile(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64textStringProfile = btoa(binaryString).toString();
    }

    changeListnerProfile(evt) {
        this.profileTouched = true;
        var files = evt.target.files;
        var file = files[0];

        if (files && file) {
            var readerURL = new FileReader();
            var readerBinary = new FileReader();

            readerURL.onload = (evt: any) => {
                this.editPictureForm.controls['currentProfileImageURL'].setValue(evt.target.result);
                this.profileImg = evt.target.result;
                this.updateCroppie();
            }
            readerBinary.onload = this._handleReaderLoadedProfile.bind(this);
            this.typeProfile = this.appService.getExtension(file.name);
            readerURL.readAsDataURL(file);
            readerBinary.readAsBinaryString(file);
        }
    }

    public blobToFile(theBlob: Blob, fileName:string): File {
        var b: any = theBlob;
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        b.lastModifiedDate = new Date();
        b.name = fileName;
        b.__proto__ = File.prototype
    
        //Cast to a File() type
        return <File>b;
    }

    public submitEditPictureForm(): void {
        if (this.profileTouched){
            this.croppieDirective.croppie.result({type: "blob"}).then((e) => {
                if(e){
                    let formData: FormData = new FormData();
                    formData.append('file',<Blob>e,"my-image.png");
                    this.userBlockService.updateProfileImage(formData, this.closeModalAndRefresh.bind(this));
                }
            });
        }
        this.base64textStringProfile = "";
    }

    closeModalAndRefresh(){
        this.contextService.getProfile();
        this.closeModalEvent.emit('close');
    }

    public updateCroppie() {
        let options = { url: this.profileImg, zoom: 0 };
        this.croppieDirective.croppie.bind(options);
    }

    public handleUpdate(data) { 
        
    }

    public initializeCroppie() {        
        this.croppieDirective.croppieOptions = this.croppieOptions;
    }

}
