import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-picture-upload-wrap',
    templateUrl: './picture-upload-wrap.component.html',
    styleUrls: ['./picture-upload-wrap.component.scss']
})
export class PictureUploadWrapComponent implements OnInit {
    @Output() showEditPictureModalEvent = new EventEmitter();

    public editPictureForm: FormGroup;
    public editProfileImage: any;

    constructor(public formBuilder: FormBuilder) { }

    ngOnInit(): void {
        this.setEditPictureFormGroup();
    }

    public setEditPictureFormGroup(): void {
        this.editPictureForm = this.formBuilder.group({
            'currentProfileImageURL': '',
            'editProfileImage': '',
        });
        this.editProfileImage = this.editPictureForm.controls['editProfileImage'];
    }

    public closeModal($event) {
        this.showEditPictureModalEvent.emit($event);
    }
}
