import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService, ContextService } from 'src/app/core';
import { BasketService } from 'src/app/shared/basket.service';

@Component({
  selector: 'app-public-order-message',
  templateUrl: './public-order-message.component.html',
  styleUrls: ['./public-order-message.component.scss']
})
export class PublicOrderMessageComponent implements OnInit, OnDestroy {
  companyId: number = null;

  private unsubscriber = new Subject<void>();

  constructor(public router: Router, 
              private basketService: BasketService, 
              private contextService: ContextService, 
              private authService: AuthService) { }

  ngOnInit(): void {
    this.companyId = this.contextService.getCompanyId();
    if(this.contextService.isPublicMember()) {
      this.basketService.clearItems();
 
    setTimeout(()=>{
      if(this.router.url.includes("public-message")) {
        this.navigateToLogin();
        this.authService.removeAuth();
      }
    }, 20000);
      
    }
    else
      this.navigateToHome();
    
    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
        takeUntil(this.unsubscriber)
    ).subscribe((_) => {
        history.pushState(null, '');
    });
  }
  
  navigateToLogin(){
    this.router.navigate(['/login']);
  }

  navigateToHome(){
    this.router.navigate(['']);
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
