// export class ProductCategory { // TODO brisati
//     name: string;
//     categoryId?: number;
//     imageUrl: string;
//     categoryUrl: string;
//     subCategories?: ProductCategory[];
// }

export class Category {
    id: string;
    name: string;
    group: object[];
}

export class Pager{
    totalPages: number[];
    displayPages: number[];
    curPage: number;
    lastPage: boolean;
    firstPage: boolean;
    totalElements: number;


    constructor(totalPages: number[], displayPages: number[], curPage: number, lastPage: boolean, firstPage: boolean, totalElements: number) {
        this.totalPages = totalPages;
        this.displayPages = displayPages;
        this.curPage = curPage;
        this.lastPage = lastPage;
        this.firstPage = firstPage;
        this.totalElements = totalElements;
    }
};

export class Product {
    description: string;
    excluded: excluded;
    productId: string;
    images: string[];
    isMotivationalProduct: boolean;
    kolicina: number;
    name: string;
    price: number;
    productClassification: string;
    quantityOrderMax?: number; 
    shortDescription?: string;
    quantity?: number;
    isInCart?: boolean;
    saleProduct?: boolean;
    newProduct?: boolean;
    popularProduct?: boolean;
    loyaltyProductPoints?: number;
    rebateFixed?: number;
    productBrand: string;
    odnospak: number;
    doppak: string;

    constructor(productId: string, name: string, productBrand: string, description: string, images: string[], price: number, isMotivationalProduct: boolean, productClassification: string, kolicina: number, excluded: excluded, odnospak: number, doppak: string, shortDescription: string = '', quantityOrderMax: number = null, saleProduct: boolean = false, newProduct: boolean = false, popularProduct: boolean = false, quantity: number = 0, loyaltyProductPoints: number = 0, rebateFixed: number = 0,  isInCart: boolean = false) {
        this.productId = productId;
        this.name = name;
        this.productBrand = productBrand;
        this.description = description;
        this.images = images;
        this.price = price;
        this.isMotivationalProduct = isMotivationalProduct;
        this.productClassification = productClassification;
        this.kolicina = kolicina;
        this.excluded = excluded;
        this.shortDescription = shortDescription;
        this.quantityOrderMax = quantityOrderMax;
        this.quantity = quantity;
        this.isInCart = isInCart;
        this.saleProduct = saleProduct;
        this.newProduct = newProduct;
        this.popularProduct = popularProduct;
        this.loyaltyProductPoints = loyaltyProductPoints;
        this.rebateFixed = rebateFixed;
        this.odnospak = odnospak;
        this.doppak = doppak;
    }

}

export enum excluded {    
    REGULAR_ARTICLE = 'REGULAR_ARTICLE',
    ADVERTISING_MATERIAL = 'ADVERTISING_MATERIAL',
    SALE = 'SALE'    
}

export class CartLocal {
    orderId: number;
    campaigId: number;
    items: Array<Product>;
    time: Date;
}