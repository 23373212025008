<div [@inAnimation] *ngIf="iFrameSource" class="row fix-width">
    <div class="col-12 catalogue-position">
        <div style="height: 100%; width: 100%; overflow: hidden">
            <iframe *ngIf="iFrameSource" [@inOutAnimation] id="iframe-flipbook" [src]="iFrameSource" allow="fullscreen"
                allowfullscreen webkitallowfullscreen mozallowfullscreen (load)="onIframeLoad($event)"></iframe>
        </div>
    </div>
</div>
<!-- product single popup -->
<ng-container *ngIf="isRouterActive | async">
    <div #routerContainer [@inOutAnimation] class="router-container">
        <router-outlet #myOutlet="outlet"></router-outlet>
    </div>
    <div [@inOutAnimation] (click)='goBack()' class="close-overlay"></div>
</ng-container>