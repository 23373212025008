import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, ContextService } from 'src/app/core';
import { inAnimation } from '../../animations';
import { CatalogueArtikal } from '../../banners/banners.service';
import { SurveyDialogData } from '../../shared.models';

@Component({
  selector: 'app-survey-dialog',
  templateUrl: './survey-dialog.component.html',
  styleUrls: ['./survey-dialog.component.scss'],
  animations: [inAnimation]
})
export class SurveyDialogComponent implements OnInit {
  private selectedCampaignsAndCatalogueArtical: string = 'slcpandcatartical';
  showFirstQuestion: boolean = false;
  currentCampaing: string;
  altCurrency: string;
  altCurrencyExchangeRate: number;

  constructor(
    public _mdr: MatDialogRef<SurveyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SurveyDialogData,
    public contextService: ContextService,
    private alertService: AlertService
  ) {
  }

  ngOnInit() {
    this.currentCampaing = this.contextService.profile.currentCampaign;
    let choosenCapaigns: string[] = JSON.parse(localStorage.getItem(this.selectedCampaignsAndCatalogueArtical)) || [];
    this.showFirstQuestion = choosenCapaigns.includes(this.currentCampaing);
    this.checkAltCurrency();
  }

  checkBoxChange(event: MatCheckboxChange, product: CatalogueArtikal) {
    setTimeout(() => {
      this.data.options.find((item1: CatalogueArtikal) => product.productId == item1.productId).selectedQuantity = event.checked ? 1 : null;
    }, 10);
  }

  closeDialog() {
    this._mdr.close(false);
  }

  confirmDialog() {
    if (this.showFirstQuestion) {
      this.showFirstQuestion = false;
      return
    }

    this.data.selectedArtikals = this.data.options.filter((item: CatalogueArtikal) => item.selectedQuantity > 0);
    this._mdr.close(this.data);

    if (this.data.selectedArtikals && this.data.selectedArtikals.length > 0)
      this.alertService.success('SHOP.SURVEY_MODAL_CONFIRMATION')

    let choosenCapaigns: string[] = JSON.parse(localStorage.getItem(this.selectedCampaignsAndCatalogueArtical)) || [];
    localStorage.setItem(this.selectedCampaignsAndCatalogueArtical, JSON.stringify([...choosenCapaigns, this.currentCampaing]));
  }

  checkAltCurrency() {
    this.contextService.getCompanyAltCurrency((altCurr: { companyId: string, altCurrency: string }) => {
      if (altCurr.altCurrency) {
        this.altCurrency = altCurr.altCurrency;

        if (this.altCurrency)
          this.contextService.getCompanyAltCurrencyExchangeRate((altCurrExchangeRate: { companyId: string, altCurrencyExchangeRate: number }) => {
            if (altCurrExchangeRate.altCurrencyExchangeRate)
              this.altCurrencyExchangeRate = altCurrExchangeRate.altCurrencyExchangeRate;
          })
      }
    })
  }
}