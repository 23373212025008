<div style="width: min(50vw, 500px)">
  <h2 mat-dialog-title>{{ heading | translate }}</h2>
  <div class="pt-2 pb-3 px-5" mat-dialog-content>{{ message | translate }}</div>
  <div mat-dialog-actions align="end">
    <span>
      <button type="button" mat-stroked-button class="pink-black-btn neutral smaller-mobile" (click)="closeDialog()">
        {{ neutralBtnTextKey | translate }}
      </button>
      <button type="button" mat-stroked-button class="pink-black-btn smaller-mobile" (click)="confirmDialog()">
        {{ colorBtnTextKey | translate }}
      </button>
    </span>
  </div>
</div>