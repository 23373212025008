import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Banner } from 'src/app/inside-layout/routes/club-layout/club.model';
import { inOutAnimation } from '../animations';
import { PromoBannerLocationType } from './banners.models';
import { BannersService } from './banners.service';
@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
  animations: [ inOutAnimation ]
})
export class BannersComponent implements OnInit {
  @Input('promoBannerLocationType') promoBannerLocationType: string;

  public imageLoaded: boolean = false;
  private banners: Banner[] = [];
  public bannerToShow: Banner;

  private readonly lastBannersSeen: string = 'banners_last_seen';

  public AWSBucket = window.location.hostname.includes("localhost") || window.location.hostname.includes("eurobit") ? 'https://s3-eu-west-1.amazonaws.com/test-farmasi-bucket/' : 'https://s3-eu-west-1.amazonaws.com/test-farmasi-bucket/';

  constructor(private bannersService: BannersService, private router: Router, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.bannersService.getBanners(this.promoBannerLocationType, (banners: Banner[]) => {
      this.banners = banners;
      this.shouldDisplayBanner();
    });
  }

  private shouldDisplayBanner() {
    let seenBanners: Banner[] = localStorage.getItem(this.lastBannersSeen) ? JSON.parse(localStorage.getItem(this.lastBannersSeen)) : [];
    for(let bn of this.banners) {
      let seenBanner = seenBanners.find(seenBn => bn.id == seenBn.id);
      if(seenBanner && new Date().getTime() - new Date(seenBanner.lastShownTime).getTime() >= bn.timedRepeatInterval * 1000) {
        bn.lastShownTime = new Date();
        this.show(bn, seenBanners);
        return
      }else if(seenBanner?.id != bn.id) {
        bn.lastShownTime = new Date();
        this.show(bn, seenBanners);
        return
      }
    }
  }

  public show(banner: Banner, seenBanners: Banner[] = []) {
    this.bannerToShow = banner;
    let sbIndex = seenBanners.findIndex(sb => sb.id == banner.id);
    sbIndex > -1 ? seenBanners[sbIndex] = banner : seenBanners.push(banner);
    localStorage.setItem(this.lastBannersSeen, JSON.stringify(seenBanners));
  }

  public hideBanner(e): void {
    this.bannerToShow = null;
    this.imageLoaded = false;
    setTimeout(() => { this.shouldDisplayBanner() }, 3000);
  }


  public handleLink(){
    if(!this.bannerToShow?.link || this.promoBannerLocationType == PromoBannerLocationType[PromoBannerLocationType.MOTIVATIONAL] || this.promoBannerLocationType == PromoBannerLocationType[PromoBannerLocationType.ORDER_VIEW]) return;
    
    if (this.bannerToShow.link.includes('www.') || this.bannerToShow.link.includes('https'))
        this.document.location.href = this.bannerToShow.link; // if external link
    else
        this.router.navigate([this.bannerToShow.link]); // if route is in this project
  }
}