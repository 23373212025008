import { excluded } from "../shop/shop.models";

export class Order {

    id: number
    externalReference: number
    campaignId: string
    campaign: number
    shippingAddress: string
    shippingName: string
    shippingFirstName: string
    shippingLastName: string
    shippingCity: string
    shippingAddressNo: string
    shippingZip: string
    shippingFloor: string
    deliveryContactPhoneNumber: string
    note: string
    items: Array<OrderProduct>
    serviceItems: Array<ServiceItem>
    status: string
    version: number
    userId: number
    userName: string
    retailValue: number
    preorders: Array<number>
    dateCreated: string
    serviceValue: number
    serviceVAT: number
    serviceTotal: number
    rebateValue: number
    invoiceValue: number
    total: number
    shippingFlatNo: string
    paymentType: string
    deliveryProviderId: number
    deliveryProviderParcelMachineId: number
    deliveryLocalOffice: boolean
    additionalNote: string
    memberName: string
    memberNumber: string
}

export class RebateScale {
    id: number
    valueFrom: number
    valueTo: number
    rebate: number;
    userTypeRabate: string;
    productBrand: string;
}
export class RebateScaleTranslation {
    orderMore: string
    curr: string
    rebate: string | number
    altOrderMore?: string
    altCurrency?: string
    altCurrencyExchangeRate?: string | number
}

export class ServiceItem {
    description: string
    price: number
    serviceId: string
    vatRate: number
}

export class CompanyInfo {
    companyId: number;
    contacts: ContactDetails;
    details?: CompanyDetails;
}

export class ContactDetails {
    fb: string;
    ig: string;
    phoneNumber: string;
}

export class CompanyDetails {
    name?: string;
    address?: string;
    addressNo?: string;
    zip?: string;
    place?: string;
    country?: string;
    vatId?: string;
    registrationAuthority?: string;
    registrationNo?: string;
    iban?: string;
    phone?: string;
    mobile?: string;
    email?: string;
    url?: string;
}

export class ShipingDetails {
    shippingAddress: string
    shippingName: string
    shippingCity: string
    shippingAddressNo: string
    shippingZip: string
    shippingFloor: string
    shippingFlatNo: string
    deliveryContactPhoneNumber: string
}

export class ShipingDetailsPublic {
    shippingAddress: string
    shippingName: string
    shippingCity: string
    shippingAddressNo: string
    shippingZip: string
    deliveryContactPhoneNumber: string
    email: string
    firstName: string
    lastName: string
}

export class OrderProduct {
    approvedQuantity: number;
    deliveredQuantity: number;
    excluded: excluded;
    id: number;
    invoicePrice: number;
    invoiceValue: number;
    isMotivationalProduct: false;
    orderedQuantity: number;
    points: number;
    productId: string;
    productName: string;
    productBrand: string
    productOrderedQuantity: number;
    productStockQuantity: number;
    quantity: number;
    rebatePercent: number;
    rebateRuleApplied: string;
    rebateValue: number;
    retailPrice: number;
    salePrice: number;
    vat: number;
    vatCode: string;
    vatPercent: number;
    version: number;
}

export class Complaint {
    orderId: number;
    note: string;
    itemId: number;
    itemReadable: string;
    type: ComplaintType;
}

export enum ComplaintType {
    ORDER, ORDER_ITEM
}

export class MemberRegistrationIn {
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    addressNo: string;
    place: string;
    zipCode: string;
    companyId: number;
    gender: string;
    type: string;
    recommendation: string;
    defaultLocale: string;
    phoneMobile: string;
    invitationToken: string;
    name: string;
    mlmProgression: string;
    birthDate: Date;
    birtPlace: string;
    idNumber: string;
    personalIdentificationNumber: string;
    parentName: string;
    referral: string;
}