<div class="wrapper" class="resetBg">
    <div class="block-center mt-xl wd-xl">
        <div class="container-fluid pt-login-wrapper">
            <div class="row fix-img-place">
                <div class="col-md-6 pt-login-left"> <img src="../../../assets/logo-white.svg" /> </div>
                <div class="col-md-6 col-xs-12 pt-login-right">
                    <img class="login-logo-right" src="../../../assets/logo-header.svg" />
                    <h4 class="text-black">{{ 'REGISTRATION.NEWPASSWORD' | translate }}</h4> 
                    <form novalidate [formGroup]="passwordForm" (ngSubmit)="submitForm()">
                        <input autocomplete="off" id="emailResetPassword" type="password" required [placeholder]="'REGISTRATION.PASSWORD' | translate" [formControl]="passwordForm.controls['password']">
                        <span *ngIf="passwordForm.controls['password'].hasError('required') && passwordForm.controls['password'].touched" class="error">{{ 'VALIDATION.REQUIRED' | translate }}</span>
                        <!-- <span *ngIf="!passwordForm.controls['password'].hasError('required') && passwordForm.controls['password'].hasError('minlength')" class="error">{{ 'VALIDATION.SHORT-INPUT' | translate }}</span> -->
                        <span *ngIf="!passwordForm.controls['password'].hasError('required') && !passwordForm.controls['password'].hasError('minlength') && passwordForm.controls['password'].hasError('pattern')" class="error">{{ 'VALIDATION.PASSWORD' | translate }}</span>
                        <input autocomplete="off" type="password" required [placeholder]="'REGISTRATION.PASSWORD-CONFIRMATION' | translate" [formControl]="passwordForm.controls['confirmPassword']" >
                        <span *ngIf="!passwordForm.controls['password'].hasError('required') && !passwordForm.controls['password'].hasError('minlength') && !passwordForm.controls['password'].hasError('pattern') && passwordForm.controls['confirmPassword'].hasError('equalTo')" class="error">{{ 'VALIDATION.PASSWORD-CONFIRMATION' | translate }}</span>
                        <span class="error" *ngIf="passwordForm.hasError('notSame')">{{ 'VALIDATION.PASSWORD-CONFIRMATION' | translate }}</span>
                        <div class="pt-login-buttons">
                            <button type="submit" [disabled]="passwordForm.hasError('notSame') || passwordForm.controls['password'].hasError('required') || passwordForm.controls['password'].hasError('pattern') || passwordForm.controls['password'].hasError('minlength') || loadingService.unfinishedRequests" class="pt-login-prijavise outline-none disable-cursor">{{ 'MODAL.SUBMIT' | translate }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>