<svg *ngIf="icon =='search'" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect width="24" height="24" fill="transparent"/><circle cx="11" cy="11" r="10.2" stroke="currentColor" stroke-width="1.6"/><path d="M23 23L18 18" stroke="currentColor" stroke-width="1.6"/></svg>
<svg *ngIf="icon =='cart_1'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" fill="transparent" /><path d="M2.79196 8.11314L2.8 8.05685V8C2.8 7.33726 3.33726 6.8 4 6.8H20C20.6627 6.8 21.2 7.33726 21.2 8V8.05685L21.208 8.11314L23.199 22.0498C23.1729 22.6894 22.6461 23.2 22 23.2H2C1.35393 23.2 0.827105 22.6894 0.801013 22.0498L2.79196 8.11314Z" stroke="currentColor" stroke-width="1.6" /><path d="M17 10C17 5.02944 14.7614 1 12 1C9.23858 1 7 5.02944 7 10" stroke="currentColor" stroke-width="1.6" /></svg>
<svg *ngIf="icon =='wishlist'" width="24" height="24" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path fill="currentColor" d="M6.9,2.6c1.4,0,2.7,0.6,3.8,1.6l0.2,0.2L12,5.6l1.1-1.1l0.2-0.2c1-1,2.3-1.6,3.8-1.6s2.8,0.6,3.8,1.6c2.1,2.1,2.1,5.6,0,7.7L12,20.7l-8.9-8.9C1,9.7,1,6.2,3.1,4.1C4.2,3.2,5.5,2.6,6.9,2.6z M6.9,1C5.1,1,3.3,1.7,2,3.1c-2.7,2.7-2.7,7.2,0,9.9l10,10l10-9.9c2.7-2.8,2.7-7.3,0-10c-1.4-1.4-3.1-2-4.9-2c-1.8,0-3.6,0.7-4.9,2L12,3.3l-0.2-0.2C10.4,1.7,8.7,1,6.9,1z"/></svg>
<svg *ngIf="icon =='profile'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" fill="transparent"/><path d="M2.82644 23.2C3.18517 17.8073 7.14887 13.8 12 13.8C16.8511 13.8 20.8148 17.8073 21.1736 23.2L2.82644 23.2Z" stroke="currentColor" stroke-width="1.6"/><circle cx="12" cy="6" r="5.2" stroke="currentColor" stroke-width="1.6"/></svg>
<svg *ngIf="icon =='star_red'" width="15" height="15" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.52413 0.475528C4.67315 0.0134866 5.32685 0.0134856 5.47586 0.475527L6.22814 2.80811C6.29487 3.01504 6.48767 3.15512 6.70509 3.15464L9.15598 3.14929C9.64145 3.14823 9.84346 3.76994 9.45008 4.05444L7.46412 5.4907C7.28795 5.61811 7.2143 5.84476 7.28194 6.05139L8.0444 8.38067C8.19543 8.84205 7.66657 9.2263 7.27444 8.94008L5.29478 7.49515C5.11916 7.36697 4.88084 7.36697 4.70522 7.49515L2.72557 8.94008C2.33343 9.22629 1.80457 8.84206 1.9556 8.38067L2.71806 6.05139C2.7857 5.84476 2.71205 5.61811 2.53588 5.4907L0.549921 4.05444C0.15654 3.76994 0.358546 3.14823 0.844021 3.14929L3.29491 3.15464C3.51233 3.15512 3.70513 3.01504 3.77186 2.80811L4.52413 0.475528Z" fill="#E12C43"/></svg>
<svg *ngIf="icon =='fb'" fill="none" width="18" height="19" viewBox="0 0 11 18" class="shortAnim mr-3" id="icon-social_icon_facebook"><path d="M6.91 5.045c0-.516.103-.932.309-1.248.217-.317.687-.475 1.41-.475l1.838-.017V.14A23.92 23.92 0 0 0 7.786 0a5.6 5.6 0 0 0-1.822.28 3.713 3.713 0 0 0-1.427.826c-.39.375-.693.844-.91 1.407C3.409 3.076 3.3 3.738 3.3 4.5v2.25H0v3.375h3.3L3.317 18H6.91v-7.875H9.9L11 6.75H6.91V5.045z" fill="currentColor"></path></svg>
<svg *ngIf="icon =='ig'" fill="none" width="18" height="19" viewBox="0 0 18 18" class="shortAnim mr-3" id="icon-social_icon_instagram"><path d="M9 1.622c2.403 0 2.688.009 3.637.052.877.04 1.354.187 1.67.31.392.144.745.374 1.036.673.299.29.529.644.673 1.035.123.317.27.794.31 1.671.043.95.052 1.234.052 3.637s-.009 2.688-.052 3.637c-.04.877-.187 1.354-.31 1.671a2.982 2.982 0 0 1-1.708 1.708c-.317.124-.794.27-1.671.31-.95.043-1.234.053-3.637.053s-2.688-.01-3.637-.053c-.877-.04-1.354-.187-1.671-.31a2.788 2.788 0 0 1-1.035-.673 2.788 2.788 0 0 1-.673-1.035c-.123-.317-.27-.793-.31-1.671-.043-.949-.052-1.234-.052-3.637s.009-2.688.052-3.637c.04-.877.187-1.354.31-1.67.144-.392.374-.745.673-1.036a2.78 2.78 0 0 1 1.035-.673c.317-.123.794-.27 1.671-.31.95-.043 1.234-.052 3.637-.052zM9 0C6.556 0 6.25.01 5.29.054S3.676.25 3.104.473c-.6.225-1.145.58-1.594 1.038-.458.45-.813.993-1.039 1.594C.25 3.677.098 4.33.054 5.289.01 6.25 0 6.556 0 9s.01 2.75.054 3.71c.044.959.196 1.613.419 2.185.226.6.58 1.145 1.038 1.594.45.458.993.813 1.594 1.038.572.223 1.227.375 2.184.419C6.25 17.99 6.556 18 9 18s2.75-.01 3.71-.054c.959-.044 1.613-.196 2.185-.419a4.6 4.6 0 0 0 2.633-2.632c.222-.572.374-1.226.418-2.184C17.99 11.75 18 11.444 18 9s-.01-2.75-.054-3.71c-.044-.959-.196-1.613-.419-2.185A4.411 4.411 0 0 0 16.49 1.51 4.412 4.412 0 0 0 14.895.472C14.323.25 13.67.098 12.711.054 11.75.01 11.444 0 9 0z" fill="currentColor"></path><path d="M9 4.378a4.622 4.622 0 1 0 0 9.244 4.622 4.622 0 0 0 0-9.244zM9 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm4.804-6.724a1.08 1.08 0 1 0 0-2.16 1.08 1.08 0 0 0 0 2.16z" fill="currentColor"></path></svg>
<svg *ngIf="icon =='remove'" class="shortAnim"  width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M5.75432 23.2L4.84844 7.8H19.1516L18.2457 23.2H5.75432Z" stroke="currentColor" stroke-width="1.6"/><path d="M2 4H22" stroke="currentColor" stroke-width="1.6"/><path d="M10 1H14" stroke="currentColor" stroke-width="1.6"/></svg>
<svg *ngIf="icon =='angle_left'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17" viewBox="0 0 17 17"><g></g><path d="M5.207 8.471l7.146 7.147-0.707 0.707-7.853-7.854 7.854-7.853 0.707 0.707-7.147 7.146z" fill="#000000" /></svg>
<svg *ngIf="icon =='angle_right'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17" viewBox="0 0 17 17"><g></g><path d="M13.207 8.472l-7.854 7.854-0.707-0.707 7.146-7.146-7.146-7.148 0.707-0.707 7.854 7.854z" fill="#000000" /></svg>
<svg *ngIf="icon =='close'"version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17" viewBox="0 0 17 17"><g></g><path d="M9.207 8.5l6.646 6.646-0.707 0.707-6.646-6.646-6.646 6.646-0.707-0.707 6.646-6.646-6.647-6.646 0.707-0.707 6.647 6.646 6.646-6.646 0.707 0.707-6.646 6.646z" fill="#000000" /></svg>
<svg *ngIf="icon =='toggler'" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M0 6H24" stroke="currentColor" stroke-width="1.6"/><path d="M0 12H16" stroke="currentColor" stroke-width="1.6"/><path d="M0 18H24" stroke="currentColor" stroke-width="1.6"/></svg>
<svg *ngIf="icon =='minus'" width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.78894 1H10.5467H0.78894Z" fill="black"/><path d="M0.78894 1H10.5467" stroke="black" stroke-width="1.6"/></svg>
<svg *ngIf="icon =='plus'" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M0.0172119 4.99999H9.77499H0.0172119ZM4.8961 0.108795V9.89118V0.108795Z" /><path stroke="currentColor" d="M4.8961 0.108795V9.89118M0.0172119 4.99999H9.77499H0.0172119Z" stroke-width="1.6"/></svg>
<svg *ngIf="icon =='filter'" width="15" height="15" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve"><path fill="currentColor" d="M9,24l0-12L1,5V0h22v5l-8,7v8L9,24z M2.6,4.3l8,7V21l2.8-1.9v-7.9l8-7V1.6H2.6V4.3z"/></svg>
<svg *ngIf="icon =='2cols'" width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 0.5H10.5V11.5H0.5V0.5Z" stroke="#000000"/><path d="M12.5 0.5H22.5V11.5H12.5V0.5Z" stroke="#000000"/></svg>
<svg *ngIf="icon =='3cols'" width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 0.5H6.5V11.5H0.5V0.5Z" stroke="#000000"/><path d="M8.5 0.5H14.5V11.5H8.5V0.5Z" stroke="#000000"/><path d="M16.5 0.5H22.5V11.5H16.5V0.5Z" stroke="#000000"/></svg>
<svg *ngIf="icon =='4cols'" width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 0.5H4.5V11.5H0.5V0.5Z" stroke="#000000"/><path d="M6.5 0.5H10.5V11.5H6.5V0.5Z" stroke="#000000"/><path d="M12.5 0.5H16.5V11.5H12.5V0.5Z" stroke="#000000"/><path d="M18.5 0.5H22.5V11.5H18.5V0.5Z" stroke="#000000"/></svg>
<svg *ngIf="icon =='edit'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17" viewBox="0 0 17 17"><g></g><path d="M11.667 0h-8.651v1.984h-0.516c-0.827 0-1.5 0.673-1.5 1.5v8.588l2.521 4.956 2.464-4.959v-8.585c0-0.827-0.673-1.5-1.5-1.5h-0.469v-0.984h6.984v5h5v10h-11.5v1h12.5v-11.692l-5.333-5.308zM3.908 14.002h-0.804l-1.104-2.17v-5.848h1v6.027h1v-6.027h0.984v5.851l-1.076 2.167zM4.984 3.484v1.5h-2.984v-1.5c0-0.275 0.225-0.5 0.5-0.5h1.984c0.276 0 0.5 0.225 0.5 0.5zM12 1.742l3.273 3.258h-3.273v-3.258z" fill="#000000" /></svg>
<svg *ngIf="icon =='view'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17" viewBox="0 0 17 17"><g></g><path d="M16.965 8.817c-1.284-3.267-4.687-5.463-8.465-5.463s-7.181 2.196-8.465 5.463c-0.046 0.117-0.046 0.248 0 0.365 1.285 3.268 4.687 5.464 8.465 5.464s7.18-2.195 8.465-5.463c0.047-0.118 0.047-0.248 0-0.366zM8.5 13.646c-3.298 0-6.269-1.859-7.459-4.646 1.189-2.787 4.16-4.646 7.459-4.646s6.27 1.859 7.459 4.646c-1.19 2.786-4.161 4.646-7.459 4.646zM8.5 5.357c-2.009 0-3.643 1.634-3.643 3.643s1.634 3.643 3.644 3.643c2.008 0 3.643-1.634 3.643-3.643s-1.635-3.643-3.644-3.643zM8.5 11.643c-1.458 0-2.644-1.186-2.644-2.643s1.187-2.643 2.644-2.643c1.457 0 2.643 1.186 2.643 2.643s-1.185 2.643-2.643 2.643zM8.5 7.643c-0.748 0-1.357 0.609-1.357 1.357s0.609 1.357 1.357 1.357 1.357-0.609 1.357-1.357-0.609-1.357-1.357-1.357zM8.5 9.357c-0.197 0-0.357-0.16-0.357-0.357s0.16-0.357 0.357-0.357 0.357 0.16 0.357 0.357-0.16 0.357-0.357 0.357z" fill="#000000" /></svg>
<svg *ngIf="icon =='delete'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17" viewBox="0 0 17 17"><g> </g><path d="M10.935 2.016c-0.218-0.869-0.999-1.516-1.935-1.516-0.932 0-1.71 0.643-1.931 1.516h-3.569v1h11v-1h-3.565zM9 1.5c0.382 0 0.705 0.221 0.875 0.516h-1.733c0.172-0.303 0.485-0.516 0.858-0.516zM13 4h1v10.516c0 0.827-0.673 1.5-1.5 1.5h-7c-0.827 0-1.5-0.673-1.5-1.5v-10.516h1v10.516c0 0.275 0.224 0.5 0.5 0.5h7c0.276 0 0.5-0.225 0.5-0.5v-10.516zM8 5v8h-1v-8h1zM11 5v8h-1v-8h1z" fill="#000000" /> </svg>
<svg *ngIf="icon =='cart'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17" viewBox="0 0 17 17"><g></g><path d="M2.75 12.5c-0.965 0-1.75 0.785-1.75 1.75s0.785 1.75 1.75 1.75 1.75-0.785 1.75-1.75-0.785-1.75-1.75-1.75zM2.75 15c-0.414 0-0.75-0.337-0.75-0.75s0.336-0.75 0.75-0.75 0.75 0.337 0.75 0.75-0.336 0.75-0.75 0.75zM11.25 12.5c-0.965 0-1.75 0.785-1.75 1.75s0.785 1.75 1.75 1.75 1.75-0.785 1.75-1.75-0.785-1.75-1.75-1.75zM11.25 15c-0.414 0-0.75-0.337-0.75-0.75s0.336-0.75 0.75-0.75 0.75 0.337 0.75 0.75-0.336 0.75-0.75 0.75zM13.371 2l-0.302 2h-13.143l1.118 8.036h11.913l1.038-7.463 0.236-1.573h2.769v-1h-3.629zM12.926 5l-0.139 1h-11.574l-0.139-1h11.852zM1.914 11.036l-0.561-4.036h11.295l-0.561 4.036h-10.173z" fill="#000000" /></svg>
<svg *ngIf="icon =='info'" height="18" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9"><defs><style>.cls-1{fill:#ed1a5e;}.cls-2{fill:#fff;}</style></defs><path class="cls-1" d="M4.5,9A4.5,4.5,0,1,0,0,4.5,4.49,4.49,0,0,0,4.5,9Z" transform="translate(0)"/><path class="cls-2" d="M3.16,4.11a.34.34,0,0,1,.28-.39,4.62,4.62,0,0,1,.71-.18c.76-.06,1.11.36.94,1.1C5,5,4.88,5.4,4.78,5.78a3.56,3.56,0,0,0-.1.47c0,.32.08.46.41.44a2.66,2.66,0,0,0,.39-.07A.33.33,0,0,1,5.21,7a2,2,0,0,1-.89.18c-.64-.06-.92-.41-.78-1s.26-1,.38-1.49-.07-.73-.61-.57Z" transform="translate(0)"/><path class="cls-2" d="M5.59,2.31a.7.7,0,0,1-1.39,0,.7.7,0,0,1,1.39,0Z" transform="translate(0)"/></svg>
<svg *ngIf="icon =='arrow_down'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17" viewBox="0 0 17 17"><g></g>	<path d="M15.854 8.854l-7.354 7.353-7.354-7.353 0.707-0.707 6.147 6.146v-13.293h1v13.293l6.146-6.146 0.708 0.707z" fill="#000000" /></svg>
<svg *ngIf="icon =='arrow_up'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17" viewBox="0 0 17 17"><g></g>	<path d="M15.146 8.854l-6.146-6.147v13.293h-1v-13.293l-6.146 6.147-0.708-0.708 7.354-7.353 7.354 7.354-0.708 0.707z" fill="#000000" /></svg>
<svg *ngIf="icon =='save'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17" viewBox="0 0 17 17"><g></g>	<path d="M14.164 0h-12.664c-0.827 0-1.5 0.673-1.5 1.5v14c0 0.827 0.673 1.5 1.5 1.5h14c0.827 0 1.5-0.673 1.5-1.5v-12.724l-2.836-2.776zM8 1v4h3v-4h1v5h-8v-5h4zM3 16v-6h11v6h-11zM16 15.5c0 0.275-0.225 0.5-0.5 0.5h-0.5v-7h-13v7h-0.5c-0.276 0-0.5-0.225-0.5-0.5v-14c0-0.275 0.224-0.5 0.5-0.5h1.5v6h10v-6h0.756l2.244 2.196v12.304z" fill="#000000" /></svg>
<svg *ngIf="icon =='check_box'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17" viewBox="0 0 17 17"><g></g>	<path d="M14 5.761h1v10.239h-14v-14h8.393v1h-7.393v12h12v-9.239zM4.854 8.146l-0.708 0.708 3.434 3.434 7.587-11.512-0.835-0.551-6.912 10.488-2.566-2.567z" fill="#000000" /></svg>
<svg *ngIf="icon =='hamburger_red'" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><defs><style>.hamburger-1{fill:#ec205f;}.hamburger-2{fill:#fff;}</style></defs><rect class="hamburger-1" width="40" height="40"/><circle class="hamburger-2" cx="19.62" cy="10.8" r="3.19"/><circle class="hamburger-2" cx="19.62" cy="20.41" r="3.19"/><circle class="hamburger-2" cx="19.62" cy="30.02" r="3.19"/></svg>
<svg *ngIf="icon =='exclamation'"  height="15" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation" class="svg-inline--fa fa-exclamation fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z"></path></svg>
<svg *ngIf="icon =='book'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17" viewBox="0 0 17 17"><g></g><path d="M16.592 1.152c-0.053-0.010-1.321-0.244-2.981-0.244-2.105 0-3.828 0.366-5.125 1.088-1.518-0.765-3.264-1.152-5.196-1.152-1.681 0-2.866 0.302-2.915 0.315l-0.375 0.098 0.001 13.906 0.624-0.161c0.011-0.002 1.12-0.283 2.665-0.283 1.447 0 2.771 0.24 3.96 0.703v0.828h2.5v-0.856c1.281-0.488 2.747-0.611 3.86-0.611 1.562 0 2.786 0.225 2.798 0.227l0.592 0.11v-13.891l-0.408-0.077zM1 13.907v-11.858c0.451-0.084 1.277-0.205 2.29-0.205 1.761 0 3.339 0.36 4.71 1.044v11.776c-1.403-0.617-2.977-0.945-4.71-0.945-0.969 0-1.773 0.101-2.29 0.188zM16 13.938c-0.536-0.070-1.393-0.154-2.39-0.154-1.848 0-3.381 0.298-4.61 0.855v-11.773c1.422-0.78 3.271-0.958 4.61-0.958 1.023 0 1.902 0.097 2.39 0.164v11.866z" fill="currentColor" />