<div class="mat-typography input-dialog">
  <h3 mat-dialog-title>{{ data.message }}</h3>

  <mat-dialog-content class="mat-typography mx-auto">
    <input [(ngModel)]="data.inputValue" maxlength="80">
  </mat-dialog-content>

  <div mat-dialog-actions align="end">
    <button type="button" mat-stroked-button class="pink-black-btn neutral smaller-mobile" (click)="closeDialog()">
      {{ 'MODAL.CLOSE' | translate}}
    </button>
    <button type="button" mat-stroked-button class="pink-black-btn smaller-mobile" (click)="confirmDialog()">
      {{ 'MODAL.CONFIRM' | translate}}
    </button>
  </div>

</div>