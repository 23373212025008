import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AlertService, LoadingService } from 'src/app/core';
import { SettingsService } from 'src/app/core/settings.service';
import { RegisterService } from '../register/register.service';

@Component({
    selector: 'app-activate-m',
    templateUrl: './activate-m.component.html',
    styleUrls: ['./activate-m.component.scss']
})
export class ActivateMComponent implements OnInit, AfterViewInit {
    public pdfURL = '';
    public activateForm = this.formBuilder.group({
        'password': ['', [Validators.required, Validators.pattern('^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9]{6,}$')]],
        'confirmPassword': '',
        'token': [null, Validators.required],
        'subscribeNewsLetter': false,
        'touAccepted': false
    }, { validator: this.checkPasswords });

    private mode: any;

    constructor(private alertService: AlertService, private router: Router, public route: ActivatedRoute, public formBuilder: FormBuilder,
        public appService: AppService, public settings: SettingsService, public registerService: RegisterService, public loadingService: LoadingService) { }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.mode = params['token'];

            if (this.mode != null)
                this.activateForm.controls['token'].setValue(this.mode);
        });

        this.pdfURL = "assets/docs/" + window.location.hostname + ".pdf";
    }

    ngAfterViewInit() {
        this.appService.focusElement('setPassword');
    }

    checkPasswords(group: FormGroup) {
        let pass = group.get('password').value;
        let confirmPass = group.get('confirmPassword').value;
        return pass === confirmPass ? null : { notSame: true }
    }

    submitForm() {
        this.registerService.activate(this.activateForm.value, () => {
            this.alertService.error('REGISTRATION.PASSWORD-SET');
            this.router.navigate(['/login']);
        }, () => {
            this.alertService.error('ERRORS.REGISTRATION-FAILED');
        });
    }

}
