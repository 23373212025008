import { Injectable } from '@angular/core';
import { HttpRequestType, RequestService } from '../core';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(private requestService: RequestService) { }
  
  public getAll(url: string, onSuccess: Function): void {
    this.requestService.createRequest(HttpRequestType.Get, url, null, null, (response: any) => {
        onSuccess(response);
    });
}

public getById(url: string, id: number | string, onSuccess: Function, onFail: Function = null): void {
    this.requestService.createRequest(HttpRequestType.Get, `${url}/${id}`, null, null, (response: any) => {
        onSuccess(response);
    }, () => {
        if (onFail != null)
            onFail();
    });
}

public create(url: string, formData: any, onSuccess: Function, onFail: Function = null): void {
    this.requestService.createRequest(HttpRequestType.Post, url, formData, null, (response?: any) => {
        response != null ? onSuccess(response) : onSuccess();
    }, (error: any) => {
        if(onFail != null)
            onFail(error);
        else 
            this.requestService.handleError(error);
    });
}

public update(url: string, formData: any, onSuccess: Function, onFail: Function = null): void {
    this.requestService.createRequest(HttpRequestType.Put, url, formData, null, (response?: any) => {
        response != null ? onSuccess(response) : onSuccess();
    }, onFail != null ? (error: any) => { onFail(error) } : (error) => { this.requestService.handleError(error); });
}

public delete(url: string, id: number | string, onSuccess: Function = null, onFail: Function = null): void {
    this.requestService.createRequest(HttpRequestType.Delete, `${url}/${id}`, null, null, () => {
        if(onSuccess != null)
            onSuccess();
    }, (error) => {
        if(onFail != null)
            onFail(error);
        else 
            this.requestService.handleError(error);
    });
}
}
