<div class="container animate__slideInLeft animate__animated animate__faster pt-public-message-wrapper">
  <div class="row">
    <div class="col-lg-6 mx-auto text-center">
      <img class="pt-logo" src="../../../assets/logo-footer.svg" />

      <div *ngIf="companyId == 1">
        <div class="modal-body">
          Poštovani, <br><br>
          Hvala na ukazanom povjerenju!<br><br>
          Uskoro ćete biti obaviješteni o statusu Vaše narudžbe putem email-a.
          <br>
          Ukoliko želite nastaviti uživati u pogodnostima kupovine FARMASI proizvoda, molimo da potvrdite e-mailu adresu
          koju ste predhodno unijeli i kreirajte šifru. <br><br>

          FARMASI BH
        </div>
        
      </div>
      <div *ngIf="companyId == 3">
        <div class="modal-body">
          Poštovani, <br><br>
          Hvala na ukazanom povjerenju!<br><br>
          Uskoro ćete biti obaviješteni o statusu Vaše narudžbe putem email-a.
          <br>
          Ukoliko želite nastaviti uživati u pogodnostima kupovine FARMASI proizvoda, molimo da potvrdite e-mailu adresu
          koju ste predhodno unijeli i kreirajte šifru. <br><br>

          FARMASI HR
        </div>
        
      </div>
      <div *ngIf="companyId == 2">
        <div class="modal-body">
          Poštovani, <br><br>
          Hvala na ukazanom povjerenju!<br><br>
          Uskoro ćete biti obaviješteni o statusu Vaše narudžbe putem email-a.
          <br>
          Ukoliko želite nastaviti uživati u pogodnostima kupovine FARMASI proizvoda, molimo da potvrdite e-mailu adresu
          koju ste predhodno unijeli i kreirajte šifru. <br><br>

          Vaš FARMASI Srbija
        </div>
        
      </div>
      <div *ngIf="companyId == 5">
        <div class="modal-body">
          Почитувани, <br><br>
          Ви благодариме на укажаната довреба!<br><br>
          Наскоро ќе добиете известување за статусот на Вашата порачка на Вашата email адреса.
          <br>
          Доколку сакате да продолжите да уживате во придобивките од купувањето на производите на FARMASI, Ве молиме
          потврдете ја Вашата e-mail адреса што претходно ја внесовте и креирајте лозинка.<br><br>

          Вашата Farmasi Козметика Дооел, Скопје
        </div>
        
      </div>
      <div *ngIf="companyId == 6">
        <div class="modal-body">
          Poštovani, <br><br>
          Hvala na ukazanom povjerenju!<br><br>
          Uskoro ćete biti obaviješteni o statusu Vaše narudžbe putem email-a.
          <br>
          Ukoliko želite nastaviti uživati u pogodnostima kupovine FARMASI proizvoda, molimo da potvrdite e-mailu adresu
          koju ste predhodno unijeli i kreirajte šifru. <br><br>

          Vaš FARMASI Montenegro
        </div>
        
      </div>
      <div *ngIf="companyId == 12">
        <div class="modal-body">
          I/E Dashur, <br><br>
          Faleminderit për besimin tuaj!<br><br>
          Do të njoftoheni me email në lidhje me statusin e porosisë tuaj.
          <br>
          Nëse dëshironi të vazhdoni të shijoni benefitet e blerjes së produkteve FARMASI, ju lutem ri-konfirmoni
          adresën e emailit që vendosët më pare dhe krijoni fjalëkalimin tuaj.<br><br>

          E juaja FARMASI ALB
        </div>
        
      </div>
      <div *ngIf="companyId == 13">
        <div class="modal-body">
          I/E Dashur, <br><br>
          Faleminderit për besimin tuaj!<br><br>
          Do të njoftoheni me email në lidhje me statusin e porosisë tuaj.
          <br>
          Nëse dëshironi të vazhdoni të shijoni benefitet e blerjes së produkteve FARMASI, ju lutem ri-konfirmoni
          adresën e emailit që vendosët më pare dhe krijoni fjalëkalimin tuaj.<br><br>

          E juaja FARMASI KO
        </div>
      </div>
      <div *ngIf="companyId == 4">
        <div class="modal-body">
          Spoštovani, <br><br>
          Hvala za zaupanje!<br><br>
          V kratkem boste po e-pošti obveščeni o statusu Vašega naročila.
          <br>
          V kolikor želite še naprej uživati v prednostih nakupovanja FARMASI izdelkov, prosimo, da potrdite e-poštni
          naslov, ki ste ga predhodno vnesli in ustvarite geslo. <br><br>

          Vaš FARMASI Slovenija
        </div>
      </div>
      <!-- <button (click)="this.router.navigate(['login'])" class="pink-black-btn pt-login-prijavise outline-none">{{'LOGIN.LOGIN' | translate }}</button> -->
    </div>
  </div>
</div>