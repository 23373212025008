import { Injectable } from '@angular/core';
import { Campaign } from './catalogues.models';
import { HttpRequestType, RequestService } from '../../../core/request.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CataloguesService {
  public campaigns: Array<Campaign> = [];
  public campaign: Campaign;
  public campaignOptions: Array<any> = [];

  private readonly url: string = '/mlm/api/v1/campaigns';
  private readonly urlCampaign: string = '/mlm/api/v1/campaign';

  constructor(private requestService: RequestService, private http: HttpClient) { }
  
  public getAllCampaigns(callback = null): void {
    this.requestService.createRequest(HttpRequestType.Get, this.url + '/active', null, null, (campaigns: Array<Campaign>) => {
      
      this.campaigns = campaigns;
        this.campaignOptions = [];
        
        for (let campaign of campaigns)
            this.campaignOptions.push({ campaignId: campaign.campaignId });

        if(callback)
            callback();
    });
}

public getAllCampaignsWithoutInActive(callback = null): void {
    this.requestService.createRequest(HttpRequestType.Get, this.url + '/active', null, null, (campaigns: Array<Campaign>) => {
        this.campaigns = campaigns;
        this.campaignOptions = [];

        for (let campaign of campaigns)
            this.campaignOptions.push({ campaignId: campaign.campaignId });

        if(callback)
            callback();
    });
}

public checkIsIframeUrlOk(url: string): Observable<Object> {
  return this.http.get("/farmasi-eastern-europe" + url, { headers: { 'Accept': 'text/html' }, responseType: 'text/html' as 'json' })
                  .pipe(take(1));
}

public getIpaperCatalogues(onSuccess: Function) {
  this.requestService.createRequest(HttpRequestType.Get, '/mlm/api/v1/campaign-catalogues/valid', null, null, (response) => {
    let AWSBucket = window.location.hostname.includes("localhost") || window.location.hostname.includes("eurobit") ? 'dev-farmasi-bucket' : 'test-farmasi-bucket';
    if(response)
        for(let catalogue of response)
            catalogue.imageUrl = 'https://s3-eu-west-1.amazonaws.com/'+AWSBucket+'/'+catalogue.imageUrl;
    onSuccess(response)
  }); 
}
  

public clearData(): void {
    this.campaigns = [];
    this.campaignOptions = [];
}

public getCurrentCampaign(callback = null): void {
  if(this.campaign)
    callback()
  else
    this.requestService.createRequest(HttpRequestType.Get, this.urlCampaign, null, null, (campaign: Campaign) => {
        this.campaign = campaign;

        if(callback)
            callback();
    });
  }
}
