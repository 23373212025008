import { Component, OnInit, Input } from '@angular/core';
import { take } from 'rxjs/operators';
import { MemberLoyaltyProgram, MemberLoyaltyProgramItem } from 'src/app/inside-layout/routes/club-layout/profile/profile.model';
import { ContextService } from '../../core/context-service';
import { ProfileService } from '../../inside-layout/routes/club-layout/profile/profile.service';
import { inOutAnimation } from '../animations';

@Component({
  selector: 'app-loyalty-info',
  templateUrl: './loyalty-info.component.html',
  styleUrls: ['./loyalty-info.component.scss'],
  animations: [inOutAnimation]
})
export class LoyaltyInfoComponent implements OnInit {
  @Input('class') class: string;
  memberLoyaltyProgram: MemberLoyaltyProgram[] = [];
  showNonCalculatedDropdown: boolean = false;
  currentCumulativeOrderValue: number;

  constructor(
    public contextService: ContextService,
    public profileService: ProfileService
  ) { }

  ngOnInit(): void {
    this.getLoyaltyProgramDetails();
  }

  getLoyaltyProgramDetails() {
    this.profileService.getLoyaltyProgramDetails((res: MemberLoyaltyProgram[]) => {
      res.forEach((item: MemberLoyaltyProgram) => item.items.sort((x: MemberLoyaltyProgramItem, y: MemberLoyaltyProgramItem) => x.campaign - y.campaign))
      this.memberLoyaltyProgram = res;

      if (this.contextService.profile.currentCampaign)
        this.showNonCalculatedDropdown = res.some(res => res.items.some(item => item.campaignDescription !== this.contextService.profile.currentCampaign && !item.calculated ));
      else
        this.contextService.profileObservable.pipe(take(1)).subscribe(() => {
          this.showNonCalculatedDropdown = res.some(res => res.items.some(item => item.campaignDescription !== this.contextService.profile.currentCampaign && !item.calculated ));
        });
      this.currentCumulativeOrderValue =
        res[0].items
          .filter((item: MemberLoyaltyProgramItem) => !item.calculated)
          .map((item: MemberLoyaltyProgramItem)=> item.orderedValue)
          .reduce((sum, a) => sum + a, 0);
    })
  }

}
