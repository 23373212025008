<div class="contract-modal">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="pull-left">
                    <h2>UGOVOR O PRODAJI NA DALJINU</h2>
                </div>
                <div (click)="this.closeContractModalEvent.emit()" class="pull-right">
                    <img [title]="'MODAL.CLOSE' | translate" class="clickable" src="../../../../../../assets/icons/close.svg" alt="close">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <hr>
            </div>
        </div>
    </div>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="container-fluid">
                        Ovaj Ugovor o prodaji na daljinu broj {{orderDc.id}} (“<b>Ugovor</b>”) je zaključen u Beogradu
                        dana {{ displayDate() }} godine između: <br>

                        &emsp;1. <b>FARMASI Cosmetics doo Beograd - Voždovac,</b> privredno društvo registrovano u
                        Beogradu, sa adresom sedišta Bulevar oslobođenja 111, sa matičnim brojem: 21038121, PIB:
                        108633456 („<b>Prodavac</b>“), koga zastupa direktor Leonard Marković i <br>
                        &emsp;2. {{ contextService.getName() }}, iz {{ orderDc.shippingCity }}, ulica
                        {{ orderDc.shippingAddress }}, („<b>Kupac</b>“)<br>
                        kako sledi: <br>
                        <br>
                        <b>1. Predmet Ugovora</b> <br>
                        1.1. Na osnovu ovog Ugovora, Prodavac prodaje, a Kupac kupuje: <br>

                        <!-- STARO pocetak -->

                        <hr>

                        <div class="row zoom7">
                            <div class="col-lg-6">
                                <!-- data row -->
                                <div class="korpica-modal-02-data-row">
                                    <div class="korpica-modal-02-data-row-title">
                                        {{ 'ORDER.SHIPPINGNAME' | translate }}:
                                    </div>
                                    <div class="korpica-modal-02-data-row-data">
                                        {{orderDc.shippingName}}
                                    </div>
                                </div>
                                <!-- data row END -->
                                <!-- data row -->
                                <div class="korpica-modal-02-data-row">
                                    <div class="korpica-modal-02-data-row-title">
                                        {{ 'ORDER.SHIPPINGADDRESS' | translate }}:
                                    </div>
                                    <div class="korpica-modal-02-data-row-data">
                                        {{orderDc.shippingAddress}} {{orderDc.shippingAddressNo}}
                                    </div>
                                </div>
                                <!-- data row END -->
                                <!-- data row -->
                                <div class="korpica-modal-02-data-row">
                                    <div class="korpica-modal-02-data-row-title">
                                        {{ 'ORDER.SHIPPINGCITY' | translate }}:
                                    </div>
                                    <div class="korpica-modal-02-data-row-data">
                                        {{orderDc.shippingCity}}, <span
                                            clss="korpica-modal-02-data-row-data-postal-code">{{orderDc.shippingZip}}</span>
                                    </div>
                                </div>
                                <!-- data row END -->
                                <!-- data row -->
                                <div class="korpica-modal-02-data-row">
                                    <div class="korpica-modal-02-data-row-title">
                                        {{ 'ORDER.PAYMENTTYPE' | translate }}:
                                    </div>
                                    <div class="korpica-modal-02-data-row-data">
                                        <div [ngClass]="{'pouzecem': orderDc.paymentType.toUpperCase() == 'POUZECEM', 'ziralno': orderDc.paymentType.toUpperCase() == 'ZIRALNO'}"
                                            class="korpica-modal-02-data-row-data-nacin-placanja-value">
                                            {{ 'PAYMENTTYPE.' + orderDc.paymentType.toUpperCase() | translate }}
                                        </div>
                                    </div>
                                </div>
                                <!-- data row END -->
                                <!-- data row -->
                                <div class="korpica-modal-02-data-row korpica-modal-02-data-row-napomena">
                                    <div class="korpica-modal-02-data-row-title">
                                        {{ 'ORDER.NOTE' | translate }}:
                                    </div>
                                    <div class="korpica-modal-02-data-row-data">
                                        {{orderDc.note}}
                                    </div>
                                </div>
                                <!-- data row END -->
                            </div>
                            <div class="col-lg-6">
                                <!-- data row -->
                                <div class="korpica-modal-02-data-row">
                                    <div class="korpica-modal-02-data-row-title">
                                        {{ 'ORDER.RETAILVALUE' | translate }}:
                                    </div>
                                    <div class="korpica-modal-02-data-row-data">
                                        {{ orderDc.retailValue | number:'1.2-2' }} {{this.contextService.localCurr}}
                                    </div>
                                </div>
                                <!-- data row END -->
                                <!-- data row -->
                                <div class="korpica-modal-02-data-row">
                                    <div class="korpica-modal-02-data-row-title">
                                        {{ 'ORDER.REBATEVALUE' | translate }}:
                                    </div>
                                    <div class="korpica-modal-02-data-row-data">
                                        {{ orderDc.rebateValue | number:'1.2-2' }} {{this.contextService.localCurr}}
                                    </div>
                                </div>
                                <!-- data row END -->
                                <!-- data row -->
                                <div class="korpica-modal-02-data-row">
                                    <div class="korpica-modal-02-data-row-title">
                                        {{ 'ORDER.INVOICEVALUE' | translate }}:
                                    </div>
                                    <div
                                        class="korpica-modal-02-data-row-data korpica-modal-02-data-row-data-fakturna-vrijednost-value">
                                        {{ orderDc.invoiceValue | number:'1.2-2' }} {{this.contextService.localCurr}}
                                    </div>
                                </div>
                                <!-- data row END -->
                                <!-- data row -->
                                <div class="korpica-modal-02-data-row">
                                    <div class="korpica-modal-02-data-row-title">
                                        {{ 'ORDER.SERVICEITEMS' | translate }}:
                                    </div>
                                    <div class="korpica-modal-02-data-row-data">
                                        {{ orderDc.serviceValue | number:'1.2-2' }} {{this.contextService.localCurr}}
                                    </div>
                                </div>
                                <!-- data row END -->
                                <!-- data row -->
                                <div class="korpica-modal-02-data-row">
                                    <div class="korpica-modal-02-data-row-title">
                                        {{ 'ORDER.SERVICEVAT' | translate }}:
                                    </div>
                                    <div class="korpica-modal-02-data-row-data">
                                        {{ orderDc.serviceVAT | number:'1.2-2' }} {{this.contextService.localCurr}}
                                    </div>
                                </div>
                                <!-- data row END -->
                                <!-- data row -->
                                <div class="korpica-modal-02-data-row">
                                    <div class="korpica-modal-02-data-row-title">
                                        {{ 'ORDER.SERVICETOTAL' | translate }}:
                                    </div>
                                    <div
                                        class="korpica-modal-02-data-row-data korpica-modal-02-data-row-data-troskovi-ukupno-value">
                                        {{ orderDc.serviceTotal | number:'1.2-2' }} {{this.contextService.localCurr}}
                                    </div>
                                </div>
                                <!-- data row END -->
                                <!-- data row -->
                                <div class="korpica-modal-02-data-row">
                                    <div class="korpica-modal-02-data-row-title">
                                        {{ 'ORDER.TOTAL' | translate }}:
                                    </div>
                                    <div
                                        class="korpica-modal-02-data-row-data korpica-modal-02-data-row-data-ukupno-value">
                                        {{ orderDc.total | number:'1.2-2' }} {{this.contextService.localCurr}}
                                    </div>
                                </div>
                                <!-- data row END -->
                            </div>
                        </div>
                        <div class="row zoom7">
                            <div class="col-lg-12">
                                <hr>
                                <h5>{{ 'ORDER.ITEMS' | translate }}</h5>
                                <div class="table-responsive">
                                    <table class="table table-striped table-farmacy">
                                        <thead>
                                            <tr>
                                                <th>{{ 'ORDER.CUITEMS.PRODUCTID' | translate }}</th>
                                                <th>{{ 'ORDER.CUITEMS.NAME' | translate }}</th>
                                                <th>{{ 'ORDER.CUITEMS.PRICE' | translate }}</th>
                                                <th>{{ 'ORDER.CUITEMS.QUANTITY' | translate }}</th>
                                                <th>{{ 'ORDER.CUITEMS.REBATEPERCENTE' | translate }}</th>
                                                <th>{{ 'ORDER.CUITEMS.INVOICEPRICE' | translate }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of orderDc?.items">
                                                <td>{{ item.id }}</td>
                                                <td>{{ item.productName }}</td>
                                                <td>{{ item.retailPrice }}</td>
                                                <td>{{ item.quantity }}</td>
                                                <td>{{ item.rebatePercent }}%</td>
                                                <td>{{ (item.invoicePrice * item.quantity) | number:'1.2-2' }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ 'GENERAL.FOOTER-TOTAL' | translate }}:
                                                    {{ orderDc?.items?.length }}</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row zoom7">
                            <div class="col-lg-12">
                                <h5>{{ 'ORDER.SERVICEITEMS' | translate }}</h5>
                                <div class="table-responsive">
                                    <table class="table table-striped table-farmacy">
                                        <thead>
                                            <tr>
                                                <th>{{ 'ORDER.CUITEMS.PRODUCTID' | translate }}</th>
                                                <th>{{ 'ORDER.CUITEMS.NAME' | translate }}</th>
                                                <th>{{ 'ORDER.CUITEMS.PRICE' | translate }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of orderDc.serviceItems">
                                                <td>{{ item.serviceId }}</td>
                                                <td>{{ item.description }}</td>
                                                <td>{{ item.price | number:'1.2-2' }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ 'GENERAL.FOOTER-TOTAL' | translate }}:
                                                    {{ orderDc?.serviceItems?.length }}</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <hr>

                        <!-- STARO kraj -->
                        <!-- Moze li orderDc.paymentType umjesto order.paymentType -->
                        <span *ngIf="orderDc.paymentType == 'ZIRALNO'">1.2. Kupac se obavezuje da za Proizvode koji su
                            predmet ovog Ugovora, kao i troškove dostave Proizvoda, isplati Prodavcu iznos od <b>RSD
                                {{ orderDc.total | number:'1.2-2' }}</b>, na račun broj: <b>{{ accountNumber }}</b> u
                            roku od <b>{{ paymentDeadline }}</b> dana, računajući od datuma zaključenja fakture.
                            <br></span>
                        <span *ngIf="orderDc.paymentType != 'ZIRALNO'">1.2. Kupac se obavezuje da za Proizvode koji su
                            predmet ovog Ugovora, kao i troškove dostave Proizvoda, isplati Prodavcu iznos od <b>RSD
                                {{ orderDc.total | number:'1.2-2' }}</b>, kurirskoj službi prilikom dostave. <br></span>
                        <br>
                        <b>2. Položaj Kupca</b><br>
                        2.1. Kupac potpisom na ovom Ugovoru ili korišćenjem Proizvoda potvrđuje da ga je Prodavac
                        obavestio o sledećem: <br>
                        &emsp;(<b>i</b>) Osnovnim obeležjima robe; <br>
                        &emsp;(<b>ii</b>) Poslovnom imenu, matičnom broju, adresi i drugim podacima koji su od značaja
                        za utvrđivanje identiteta Prodavca; <br>
                        &emsp;(<b>iii</b>) Prodajnoj ceni Proizvoda i načinu na koji se obračunava prodajna cena
                        Proizvoda;<br>
                        &emsp;(<b>iv</b>) Dodatnim poštanskim troškovima i troškovima transporta i isporuke i svim
                        troškovima koji se stavljaju na teret Kupca;<br>
                        &emsp;(<b>v</b>) Načinu plaćanja; <br>
                        &emsp;(<b>vi</b>) Načinu i roku isporuke i načinu vršenja drugih ugovornih obaveza; <br>
                        &emsp;(<b>vii</b>) Postojanju odgovornosti za saobraznost Proizvoda; <br>
                        &emsp;(<b>viii</b>) Načinu izjavljivanja reklamacije, mestu prijema i načinu postupanja sa
                        izjavljenim reklamacijama i uslovima koji se odnose na ostvarivanje prava potrošača po osnovu
                        saobraznosti; <br>
                        &emsp;(<b>ix</b>) Uslovima, vremenskom roku i postupku za ostvarivanje prava na odustanak od
                        ugovora; <br>
                        &emsp;(<b>x</b>) Obavezi Kupca da snosi troškove vraćanja robe u slučaju odustanka od Ugovora i
                        obavezi da plati Prodavcu razumne troškove zbog odustanka; <br>
                        &emsp;(<b>xi</b>) O mogućnosti vansudskog rešavanja sporova posredstvom tela nadležnih za
                        rešavanje potrošačkih sporova mirnim putem koja se nalaze na listi Ministarstva trgovine,
                        turizma i telekomunikacija. <br>
                        2.2. Kupac je odgovoran za štetu koja nastane propuštanjem da preuzme Proizvode koje mu je
                        poslao Prodavac. Kupac odgovara i za troškove koje je Prodavac imao zbog tog propuštanja Kupca
                        (npr. troškovi čuvanja, prepakivanja, vraćanja Proizvoda i sl.). <br>
                        2.3. Kupac snosi troškove isporuke Proizvoda u iznosu iskazanom u računu, uplatom na tekući
                        račun Prodavca ili plaćanjem u gotovom novcu kuriru prilikom preuzimanja Proizvoda <br>
                        <br>
                        <b>3. Položaj Prodavca</b><br>
                        3.1. Prodavac je dužan: <br>
                        &emsp;(<b>i</b>) da obavesti Kupca o svim bitnim obeležjima Proizvoda, o ceni Proizvoda, o
                        načinu slanja Proizvoda, o pravu na odustanak od Ugovora, o pravu na izjavljivanje reklamacije
                        zbog nesaobraznosti, kao i svim drugim činjenicama koje su bitne za zaključenje i realizaciju
                        Ugovora. <br>
                        &emsp;(<b>ii</b>) da Kupcu isporuči robu u roku od 30 dana od dana poručivanja robe na adresu
                        koju je Kupac naznačio prilikom popunjavanja porudžbenice, putem kurirske službe neposredno
                        Kupcu; <br>
                        &emsp;(<b>iii</b>) da omogući Kupcu odustanak od Ugovora; i <br>
                        &emsp;(<b>iv</b>) da primi i odluči o reklamaciji Kupca.<br>
                        <br>
                        <b>4. Pravo na odustanak od Ugovora</b><br>
                        4.1. Kupac ima pravo da odustane od Ugovora bez navođenja razloga u roku od 14 dana od prijema
                        Proizvoda („<b>Odustanak od Ugovora</b>“). <br>
                        4.2. Kupac izjavu o odustanku od Ugovora daje na Obrascu za odustanak od Ugovora na daljinu
                        („<b>Obrazac za odustanak</b>“), koji čini prilog ovog Ugovora. <br>
                        4.3. Prodavac je dužan da nakon prijema Obrasca za odustanak i dokaza da je Kupac poslao
                        Proizvode bez odlaganja Kupcu vrati sve uplate, uključujući troškove isporuke. <br>
                        4.4. Prodavac može da odloži povraćaj sredstava Kupcu dok ne dobije robu koja se vraća, ili dok
                        Kupac ne dostavi dokaz da je poslao robu Prodavcu, u zavisnosti od toga šta nastupa prvo. <br>
                        4.5. Kupac je dužan da Prodavcu vrati Proizvode bez odlaganja, a najkasnije u roku od 14 dana od
                        dana kada je poslao Obrazac za odustanak. Troškove vraćanja Proizvoda snosi Kupac. <br>
                        4.6. Kupac je isključivo odgovoran za umanjenu vrednost Proizvoda, koja nastane kao posledica
                        rukovanja Proizvodima na način na koji nije adekvatan, odnosno prevazilazi ono što je neophodno
                        da bi se ustanovili priroda, karakteristike i funkcionalnost robe. <br>
                        4.7. Kupac nema pravo na Odustanak od Ugovora u sledećim slučajevima: <br>
                        &emsp;1) kod isporuke Proizvoda koji je podložan pogoršanju kvaliteta ili ima kratak rok
                        trajanja; <br>
                        &emsp;2) kod isporuke zapečaćenog Proizvoda koji se ne može vratiti zbog zaštite zdravlja ili
                        higijenskih razloga i koji je otpečaćen nakon isporuke; <br>
                        &emsp;3) kod isporuke Proizvoda, koji se, nakon isporuke, zbog svoje prirode neodvojivo meša sa
                        drugim Proizvodima. <br>
                        <br>
                        <b>5. Nesaobraznost Proizvoda</b><br>
                        5.1. Kupac je dužan da prilikom prijema Proizvoda pregleda Proizvode i proveri da li su
                        saobrazni sa onima koji su poručeni i da uoči nedostatke koje može uočiti pogledom. <br>
                        5.2 Za skrivene nedostatke Prodavac odgovara u roku od 2 godine od predaje Proizvoda, uz
                        pretpostavku da su ti nedostaci postojali u momentu prelaska rizika na Kupca. <br>
                        5.3 Ako isporučeni Proizvodi nisu saobrazni Ugovoru, Kupac je dužan da obavesti Prodavca o
                        nesaobrazosti i izjavi Reklamaciju u zakonskom roku tako što će popuniti reklamacioni list koji
                        se nalazi na internet stranici Prodavca i poslati e-mail na adresu u info@farmasi.rs.
                        Obaveštenje o načinu i mestu prijema reklamacije i postupanju sa izjavljenom reklamacijom se
                        nalazi na internet stranici Prodavca. <br>
                        5.4. Ako nesaobraznost nastane u roku od šest meseci od dana prelaska rizika na Kupca,
                        pretpostavlja se da je nesaobraznost postojala u trenutku prelaska rizika, osim ako je to u
                        suprotnosti sa prirodom Proizvoda i prirodom određene nesaobraznosti. Po isteku roka od šest
                        meseci nesaobraznost Proizvoda u trenutku prelaska rizika dokazuje Kupac. <br>
                        <br>
                        <b>6. Merodavno pravo, rešavanje sporova i broj primeraka</b><br>
                        6.1. Ovaj Ugovor je sačinjen i ima se tumačiti u skladu sa materijalnim pravom Republike Srbije.
                        <br>
                        6.2. Ovaj Ugovor predstavlja ugovor na daljinu jer je zaključen između Prodavca i Kupca bez
                        istovremenog fizičkog prisustva trgovca i potrošača, upotrebom interneta kao sredstva
                        komunikacije na daljinu.<br>
                        6.3. Kupac ima pravo na Odustanak od Ugovora, odnosno kupovine u na način i u skladu sa članom
                        4. ovog Ugovora.<br>
                        6.4. Ugovorne strane su saglasne da će sva ostala pitanja i eventualne sporove rešavati
                        sporazumno i mirnim putem.<br>
                        6.5. Ovaj Ugovor je sačinjen u 2 (dva) istovetna primerka, po 1 (jedan) za svaku Ugovornu
                        stranu.<br>
                        6.6. Pošto su Ugovor pažljivo pročitale i potpuno razumele, Ugovorne strane svojim potpisom
                        potvrđuju da isti predstavlja njihovu saglasno izraženu volju.<br>

                        <br>

                        <div class="pull-left">
                            Prodavac<br>
                            <b>Leonard Marković, direktor</b>
                        </div>
                        <div class="pull-right">
                            Kupac<br>
                            <b>{{ contextService.getName() }}</b>
                        </div>
                        <br>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>