  <div *ngIf="isValid && !isLoading" class="container pt-izbor-jezika-wrapper">
    <div class="row">
      <div class="col-md-6 pt-izbor-jezika-left animate__animated animate__fadeInLeftBig">
        <img src="../../../assets/logo-white.svg" />
      </div>
      <div class="col-md-6 pt-izbor-jezika-right animate__animated animate__fadeIn animate_faster">
        <img class="izbor-jezika-logo-right" src="../../../assets/logo-header.svg" />
        <h4>{{ 'REFERRAL.CHOOSE-COUNTRY' | translate }}</h4>
        <div class="izbor-jezika-icons text-center" >
          <button *ngFor="let country of countries"  [ngClass]="{'transparent-btn-selected' :  country.companyId == this.memberCompanyId }" class="clickable transparent-btn" [disabled]="this.isLoading" ><img src="{{ country.img }}" alt="" (click)="chooseCountry(country.companyId)"  ></button>
        </div>
      </div>
    </div>
</div>
