import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AlertService, LoadingService } from 'src/app/core';
import { RegisterService } from '../../register/register.service';

@Component({
    selector: 'app-reset-password-mail',
    templateUrl: './reset-password-mail.component.html',
    styleUrls: ['./reset-password-mail.component.scss']
})
export class ResetPasswordMailComponent implements AfterViewInit {
    public emailMemberNumber: string;

    constructor(private alertService: AlertService, public formBuilder: FormBuilder, private registerService: RegisterService, public router: Router, public appService: AppService, public loadingService: LoadingService) { }

    ngAfterViewInit() {
        this.appService.focusElement('emailResetPassword');
    }

    public submitForm(): void {
        this.registerService.resetPasswordEmail((this.emailMemberNumber || ''), () => {
            this.alertService.success('RESET-PASSWORD.SUCCESS')
            this.router.navigate(['login']);
        });
    }
}