<div class="custom-modal">
    <form novalidate [formGroup]="editPictureForm" (ngSubmit)="picupl.submitEditPictureForm()">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">{{ 'SIDEBAR.CHANGEPICTURE' | translate }}</h3>
            </div>
            <div class="modal-body">
                <app-picture-upload #picupl [(forma)]="editPictureForm" (closeModalEvent)="closeModal($event)"></app-picture-upload>
            </div>
            <div class="modal-footer">
                <a class="btn btn-default" (click)="closeModal($event)">{{ 'MODAL.CLOSE' | translate }}</a>
                <input type="submit" class="pink-black-btn" [value]="'MODAL.CONFIRM' | translate">
            </div>
        </div>
    </form>
</div>
<div (click)="closeModal($event)" class="close-overlay animate__fadeIn animate__animated animate__faster"></div>