<div id="carouselCaptions" [ngClass]="{'carousel-fade': imgCarousel}" class="carousel slide caption-animate carousel-products" data-ride="carousel" data-interval="5000" data-keyboard="true">
   
    <!-- Products carousel -->
    <ng-container *ngIf="!imgCarousel">
        <div class="carousel-inner" role="listbox" style=" width:100%">
            <div *ngFor="let slider of numberOfSliders; let j = index;" class="carousel-item products px-1 px-md-4" [ngClass]="{'active': j == 0}">
                <div *ngFor="let product of products; let i = index;">
                    <div class="product-wrap">
                        <app-product-card *ngIf="i >= productsInSlide * j  && i < productsInSlide * ( j+1 ) && j == 0" [product]="product"></app-product-card>
                        <app-product-card *ngIf="i >= productsInSlide * j  && i < productsInSlide * ( j+1 ) && j > 0" [product]="product"></app-product-card>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <div *ngIf="imgCarousel && carousels.length > 0" class="carousel-inner" role="listbox" style="width:100%">
        <div *ngFor="let carousel of carousels; let i = index" [ngClass]="{'active': i == 0}" class="carousel-item images active">

            <ol *ngIf="imgCarousel && carousels.length > 0" class="carousel-indicators">
                <li *ngFor="let item of carousels; let j = index" data-target="#carouselCaptions" [attr.data-slide-to]="j" [ngClass]="{'active': j == i}"></li>
            </ol>
            <img [src]="carousel.url" [ngClass]="{'visible': imageLoaded}" (load)="imageLoaded = true" class="d-block opacity0 shortAnim" alt="...">
            <div [ngClass]="{'clickable': carousel?.link && carousel?.link.length > 1 }" (click)="openLink(carousel.link)" class="carousel-caption text-left d-block animate__animated animate__fadeInDown" loading="lazy">                
                <div *ngIf="carousel.supertitle.length > 1" class="over-heading text-gray text-bolderr text-uppercase">{{ carousel.supertitle }}</div>
                <div *ngIf="carousel.title.length > 1" [innerHTML]="carousel.title" class="heading text-gray text-bolderr"></div>
                <div *ngIf="carousel.description.length > 1" class="text-carousel mt-4">{{ carousel.description }}</div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!imgCarousel || (imgCarousel && carousels?.length > 1)">
        <a [ngClass]="{ 'arrow-position': carousels?.length > 1 }" class="carousel-control-prev shortAnim visible d-lg-flex" (click)="prev()" role="button">
            <app-icons [icon]="'angle_left'"></app-icons>
        </a>
        <a [ngClass]="{ 'arrow-position': carousels?.length > 1 }" class="carousel-control-next shortAnim visible d-lg-flex" (click)="next()" role="button">
            <app-icons [icon]="'angle_right'"></app-icons>
        </a>
    </ng-container>
</div>