<div class="wrapper" class="resetBg">
    <div class="block-center mt-xl wd-xl">
        <div class="container-fluid pt-login-wrapper">
            <div class="row fix-img-place">
                <div class="col-md-6 pt-login-left">
                    <img src="../../../assets/logo-white.svg"/>
                </div>
                <div class="col-md-6 col-xs-12 pt-login-right">
                    <img class="login-logo-right" src="../../../assets/logo-header.svg" />
                    <h4 class="text-black">{{ 'RESET-PASSWORD.HEADERMESSAGE' | translate }}</h4>
                    <input id="emailResetPassword" type="text" required [placeholder]="'LOGIN.ENTERUSERNAME' | translate" maxlength="100" [(ngModel)]="emailMemberNumber">
                    <div class="pt-login-buttons">
                        <button (click)='submitForm()' [disabled]="emailMemberNumber == '' || loadingService.unfinishedRequests" class="pt-login-prijavise disable-cursor outline-none ">{{ 'MODAL.SUBMIT' | translate }}</button>
                    </div>
                    <p><a class="text-decoration-none" [routerLink]="'/login'">{{ 'RESET-PASSWORD.BACKTOLOGIN' | translate }}</a></p>
                    <!-- <p>Za detaljno uputstvo kliknite <a href="https://s3-eu-west-1.amazonaws.com/test-farmasi-bucket/manual/ordering-manual-klub.farmasi.ba.pdf">ovdje</a>.</p> -->
                </div>
            </div>
        </div>