import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContextService } from 'src/app/core';

@Component({
    selector: 'app-contract-modal',
    templateUrl: './contract-modal.component.html',
    styleUrls: ['./contract-modal.component.scss']
})
export class ContractModalComponent implements OnInit {
    @Input('orderDc') orderDc;
    @Output() closeContractModalEvent = new EventEmitter();
    public paymentDeadline = 10;
    public accountNumber = "ProcreditBank: 220-0000000139221-14; UnicreditBank: 170-0030023688000-04;";

    constructor(public contextService: ContextService) { }

    ngOnInit(): void {
    }

    displayDate() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return mm + '.' + dd + '.' + yyyy + '.';
    }

}
