import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';

@Injectable()
export class MenuService {
    constructor(private authService: AuthService) {
    }
    
    // private _menuItems: ExtendedMenuItem[];
    
    // public get menuItems(): ExtendedMenuItem[] {
    //     return this._menuItems;
    // }
    
    // public set menuItems(items: ExtendedMenuItem[]) {
    //     this._menuItems = items.map(item => this.setVisibility(item));
    // }

    // private setVisibility(item: ExtendedMenuItem): ExtendedMenuItem {
    //     if (item.authorities || item.disabled)
    //         item.visible = !item.disabled && (!item.authorities || this.authService.hasAnyAuthority(...item.authorities));

    //     if (item.items)
    //         item.items = item.items.map(i => this.setVisibility(i));
            
    //     return item;
    // }
}
