import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterService } from './register.service';
import { AlertService, AuthService, ContextService, TranslatorService } from 'src/app/core';
import { DialogService } from '../../shared/dialog.service';
import { AppService } from '../../app.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, AfterViewInit {
    @ViewChild('tou') tou;
    @ViewChild('rcm') rcm;

    public registerForm: FormGroup;
    public genderOptions = this.registerService.getGenderOptions();
    public languagesOptions = this.translatorService.languagesOptions;
    public placesOptions = this.registerService.places;

    public recDisabled: boolean = false;
    public validateZipCode: boolean = false;

    public companyId = null;

    public type: any = 'PERSON';

    public pdfURL = '';

    public visibleData: Array<any> = [];

    public telephoneCountryCode: String = null;

    public minDate = new Date(1920, 0, 1).toISOString().split("T")[0];
    public maxDate = new Date().toISOString().split("T")[0];
    public eighteenYearsAgo = new Date().getTime() - (365 * 24 * 60 * 60 * 1000);

    public isTempMember = this.contextService.isTempMember();

    constructor(private fb: FormBuilder, private registerService: RegisterService, private alertService: AlertService, 
        private translatorService: TranslatorService, public appService: AppService, private dialogService: DialogService,
         private router: Router, public route: ActivatedRoute, public contextService: ContextService, private authService: AuthService) { }

    ngOnInit(): void {
        this.setFormGroup();
        this.pdfURL = "assets/docs/" + window.location.hostname + ".pdf";

        if(this.contextService.getCompanyId() === 2)
          this.pdfURL = "https://www.farmasi.rs/index.php/informacije/opsti-uslovi-prodaje-u-internet-prodavnici"
        else if(this.contextService.getCompanyId() === 3)
          this.pdfURL = "https://www.farmasi.hr/index.php/informacije/opci-uvjeti-prodaje-internet-trgovine";
        else if(this.contextService.getCompanyId() === 4)
          this.pdfURL = "https://www.farmasi.si/index.php/informacije/splosni-pogoji-prodaje-internetne-trgovine";
        else if(this.contextService.getCompanyId() === 6)
          this.pdfURL = "https://www.farmasi.co.me/index.php/informacije/opsti-uslovi-prodaje-internet-trgovine";

        this.route.params.subscribe(params => {
            let id = params['token'];

            if (id != null) {
                this.registerService.getInvitationData(id, (response) => {
                    this.registerForm.controls['recommendation'].setValue(response.recommendation);
                    this.registerForm.controls['email'].setValue(response.email);
                    this.registerForm.controls['invitationToken'].setValue(id);
                }, () => {
                    this.router.navigate(['login']);
                    this.alertService.error('ERRORS.TOKEN-EXPIRED');
                });

                this.recDisabled = true;
            }
        });
    }

    ngAfterViewInit(){
        this.registerService.loadCompanyForRegistrationByDomain(window.location.hostname, () => { this.companieChanged(); });
    }

    public setFormGroup() {
        this.registerForm = this.fb.group({
            // Group 1
            'firstName': [null, Validators.compose([Validators.required, Validators.minLength(2)])],
            'parentName': [null, Validators.compose([Validators.minLength(2)])],
            'lastName': [null, Validators.compose([Validators.required, Validators.minLength(2)])],
            'gender': [null, Validators.required],
            'email': [null, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])],
            'phoneMobile': [null, Validators.compose([Validators.required, Validators.minLength(7), Validators.pattern('^[1-9][0-9]*$')])],
            // Group 2
            'place': [null, Validators.compose([Validators.required, Validators.minLength(2)])],
            'zipCode': [null, Validators.compose([Validators.required, Validators.pattern('^\\d{4}(?:\\d{1})?$')])],
            'address': [null, Validators.compose([Validators.required, Validators.minLength(2)])],
            'addressNo': [null, Validators.required],
            // Group 3
             'idNumber': [null, Validators.compose([Validators.minLength(2)])],
            'personalIdentificationNumber': null,
            'birthDate': [null],
            'birthPlace': [null, Validators.compose([Validators.minLength(2)])],
            'defaultLocale': [null, Validators.required],
            'recommendation': null,
            // Other
            'subscribeNewsLetter': false,
            'touAccepted': false,
            'companyId': [null, Validators.required],
            'invitationToken': null
        });
    }
    
    submitForm(value: any) {
        // if(!this.registerForm.valid || !this.registerForm.controls['touAccepted'].value) TODO sta s ovim
        //     this.alertService.error()
        
        let user = this.appService.createDeepCopy(value);
        user.type = this.type;
        //user.birthDate = user.birthDate + "T00:00:00";

        // var ageDifMs = Date.now() - new Date(user.birthDate).getTime();
        // var ageDate = new Date(ageDifMs); // miliseconds from epoch
        // var years = Math.abs(ageDate.getUTCFullYear() - 1970);

        // if (years < 18) {
        //     this.alertService.error('ERRORS.USER-UNDER-EIGHTEEN');
        //     return;
        // }

        if (this.validateZipCode)
            user.place = user.place.place;

        user.email = (user.email || '').toLowerCase();
        user.phoneMobile = this.telephoneCountryCode + user.phoneMobile.toString();        
        
        this.registerService.registerUser(user, () => {
            this.dialogService.OpenModal('REGISTRATION-COMPLETE.M1', 'REGISTRATION-COMPLETE.TITLE', () => {

                this.router.navigate(['login']);

                if(this.isTempMember)
                  this.authService.removeAuth();
            });
        });
    }

    public checkBirthDate(e): void {
        var ageDifMs = Date.now() - new Date(e.target.value).getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        var years = Math.abs(ageDate.getUTCFullYear() - 1970);
        if (years < 18) {
            this.alertService.error('ERRORS.USER-UNDER-EIGHTEEN');
            return;
        } else {
            this.registerForm.controls['birthDate'].setValue(e.target.value);            
        }
    }

    public onLanguageChanged(e){
        this.languagesOptions.forEach(element => {
            if(element.label == e.target.value)
                this.registerForm.controls['defaultLocale'].setValue(element.value);
        });
    }

    public onGenderChanged(e){
        this.genderOptions.forEach(element => {
            if(element.label == e.target.value){
                // this.registerForm.patchValue({ gender: element.value });
                this.registerForm.controls['gender'].setValue(element.value);
            }
        });
    }

    public ignore(e: KeyboardEvent): boolean {
        const value: string | null = this.registerForm.controls['phoneMobile'].value;

        if(value === null || value === '')
          return e.key >= '1' && e.key <= '9';

        return e.key >= '0' && e.key <= '9';
    }

    public capitalize(controlName: string): void {
        let originalValue = this.registerForm.controls[controlName].value;

        if (originalValue != null) {
            let trimmedValue = originalValue.trim();

            if (controlName == 'email') {
                this.registerForm.controls[controlName].setValue(trimmedValue.toLowerCase());
                return;
            }

            let output = [];

            if (controlName == 'firstName' || controlName == 'parentName' || controlName == 'lastName')
                for (let i = 0; i < trimmedValue.length; i++)
                    if (i == 0 || (trimmedValue[i - 1] == ' ' || trimmedValue[i - 1] == '-'))
                        output.push(trimmedValue[i].toUpperCase());
                    else
                        output.push(trimmedValue[i].toLowerCase());
            else
                for (let i = 0; i < trimmedValue.length; i++)
                    if (i == 0)
                        output.push(trimmedValue[i].toUpperCase());
                    else if (trimmedValue[i - 1] != ' ' && trimmedValue[i - 1] != '-')
                        output.push(trimmedValue[i].toLowerCase());
                    else
                        output.push(trimmedValue[i]);

            this.registerForm.controls[controlName].setValue(output.join(''));
        }
    }

    companieChanged() {
        this.visibleData = [];

        let collectableProfileData = this.registerService.collectableProfileData.find(e => e.companyId == this.registerService.currentCompany.id).registrationFields;
        
        for (let profileData of collectableProfileData)
            if (profileData.visible)
                this.visibleData[profileData.field] = true;
            else
                this.registerForm.controls[profileData.field].setValue(profileData.default);

        this.registerForm.controls['companyId'].setValue(this.registerService.currentCompany.id);

        this.validateZipCode = false; // TODO uradjena validacija na profile settingsu
        this.telephoneCountryCode = this.registerService.currentCompany.telephoneCountryCode;

        if (this.registerService.currentCompany.validateZipCode) {
            this.registerService.loadZipCodesForCompany(this.registerService.currentCompany.id);
            this.validateZipCode = true; // TODO uradjena validacija na profile settingsu
        }

        
    }

    changePlace() {
        if(this.registerForm.controls['place'].value != '') {
            let selectedPlace = this.registerService.places.find(place => place.label == this.registerForm.controls['place'].value);
            if(selectedPlace && this.validateZipCode){
                this.registerForm.controls['place'].setValue(selectedPlace.value.place);
                this.registerForm.controls['zipCode'].setValue(selectedPlace.value.zipCode);
            } else if (!selectedPlace && this.validateZipCode) {
                this.registerForm.controls['place'].setValue('');
                this.registerForm.controls['zipCode'].setValue('');
            }
        }
    }
    
    public showTermsOfUse(): void {
        this.tou.show();
    }
}
