import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { loadMessages, locale } from 'devextreme/localization';



@Injectable({
    providedIn: 'root'
})
export class TranslatorService {
    // TODO staviti na eng defaultni
    public selectedLanguage: string = 'bs-BA';
    public defaultLanguage: string = 'bs-BA';

    public languagesOptions: Array<any> = [
        // { label: '', value: null },
        { label: 'Bosanski', value: 'bs-BA' },
        { label: 'Crnogorski', value: 'sr-ME' },
        { label: 'English', value: 'en-US' },
        { label: 'Hrvatski', value: 'hr-HR' },
        { label: 'македонски', value: 'mk-MK' },
        { label: 'Shqip', value: 'sq-AL' },
        { label: 'Shqip - Kosova', value: 'sq-KV' },
        { label: 'Slovenščina', value: 'sl-SI' },
        { label: 'Srpski', value: 'sr-Latn-RS' }
    ];

    public calendarLocale: any = {
        "firstDayOfWeek": 1,
        "dayNames": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        "dayNamesShort": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        "dayNamesMin": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        "monthNames": ["January", "Feburary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        "monthNamesShort": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        "today": "Today",
        "clear": "Clear"
    };

    constructor(private translateService: TranslateService) {
        if (!translateService.getDefaultLang())
            translateService.setDefaultLang(this.defaultLanguage);

        this.useLanguageByDomain();
    }

    public useLanguage(language: string = null): void {
        if(!language) return
        let newLanguage = language || this.translateService.getDefaultLang();

        this.selectedLanguage = newLanguage;
        this.translateService.use(newLanguage);

        this.translateService.get('CALENDAR').subscribe((calendarLocale: string) => {
            this.calendarLocale = calendarLocale;
        });
        this.translateService.get('DEV-EXTREME').subscribe((dxLocale: string) => {
            loadMessages({ 'dxLocale': dxLocale });
            locale('dxLocale');
        });
    }

    public useLanguageByDomain(): void {
        let hostname = window.location.hostname;

        if (hostname.endsWith('.me'))
            this.useLanguage('sr-ME');
        else if (hostname.endsWith('.hr'))
            this.useLanguage('hr-HR');
        else if (hostname.endsWith('.mk'))
            this.useLanguage('mk-MK');
        else if (hostname.endsWith('.com.al'))
            this.useLanguage('sq-KV');
        else if (hostname.endsWith('.al'))
            this.useLanguage('sq-AL');
        else if (hostname.endsWith('.si'))
            this.useLanguage('sl-SI');
        else if (hostname.endsWith('.rs'))
            this.useLanguage('sr-Latn-RS');
        else if (hostname.startsWith('ba.'))
            this.useLanguage('bs-BA');
        else if (hostname.startsWith('me.'))
            this.useLanguage('sr-ME');
        else if (hostname.startsWith('hr.'))
            this.useLanguage('hr-HR');
        else if (hostname.startsWith('mk.'))
            this.useLanguage('mk-MK');
        else if (hostname.startsWith('al.'))
            this.useLanguage('sq-KV');
        else if (hostname.startsWith('ko.'))
            this.useLanguage('sq-AL');
        else if (hostname.startsWith('si.'))
            this.useLanguage('sl-SI');
        else if (hostname.startsWith('rs.'))
            this.useLanguage('sr-Latn-RS');
        else if (hostname.endsWith('.ba'))
            this.useLanguage('bs-BA');
        else
            this.useLanguage();
    }
}
