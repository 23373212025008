import { Component, OnInit, HostListener, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Product, CartLocal } from '../routes/shop/shop.models';
import { BasketService } from 'src/app/shared/basket.service';
import { ContextService, AuthService, UtilityService } from 'src/app/core';
import { ProfileService } from '../routes/club-layout/profile/profile.service';
import { AppService } from 'src/app/app.service';
import { DialogService } from 'src/app/shared/dialog.service';
import { CartService } from '../routes/cart/cart.service';
import { CompanyInfo } from '../routes/cart/cart.model';
import { SafeHtml } from '@angular/platform-browser';
import { CataloguesService } from '../routes/catalogues/catalogues.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
    scrolledContent: boolean = false;
    prepareHeaderStyle: boolean = false;
    showSearchInput: boolean = false;
    showSidebar: boolean = false;
    showCataloguePreview: boolean = true;
    searchInput: string = '';
    subscribtion: Subscription;
    mode: number;
    edit: boolean;
    iFrameSource: SafeHtml;
    baseUrl: string;

    cartItems: Product[] = [];
    orderTotal: number;
    showCartItems: number = 0;
    qualifyReferral: boolean = false;
    minimalValue: number = null;

    companyInfo: CompanyInfo = new CompanyInfo;
    showReferalLink: boolean = false;
    referralLoaded: boolean = false;
    showFlipbook: boolean = false;
    showBannerFlipbook: boolean = false;
    private readonly availableFlipbooks: string = 'avbl_flks';
    currentCampaingImg: string;


    altCurrency: string;
    altCurrencyExchangeRate: number;

    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (document.body.scrollTop > 128 || document.documentElement.scrollTop > 128) // ovaj dio je da animacija bude smooth
            this.prepareHeaderStyle = true;
        else
            this.prepareHeaderStyle = false;

        if (document.body.scrollTop > 170 || document.documentElement.scrollTop > 170)
            this.scrolledContent = true;
        else
            this.scrolledContent = false;
    }
    
    @HostListener('document:click', ['$event'])
    clickout(event) { // outside click 
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.showSearchInput = false;
            // this.showCartItems = 0;
        }
    }
    constructor(private eRef: ElementRef, public router: Router, public basketService: BasketService,
        public contextService: ContextService, private route: ActivatedRoute,
        private profileService: ProfileService,
        private authService: AuthService, private utilityService: UtilityService,
        public appService: AppService, private dialogService: DialogService,
        private activatedRoute: ActivatedRoute, private cartService: CartService,
        private catalogueService: CataloguesService
    ) { }

    ngOnInit(): void {
        this.handleCartNotifications();

        //if(!this.referralLoaded)
    
        this.isReferralLinkVisible();

        // check if alt currency exists
        this.checkAltCurrency();

        this.cartService.getMinimalValue((response: number) => { this.minimalValue = response; });
        this.companyInfo = this.contextService.getCompanyInfo();

        this.subscribtion = this.route.queryParams.subscribe(query => {
            if (query == null || query.search == '' || query.search == null)
                this.searchInput = '';
        });
        this.contextService.getProfile(() => {
            this.checkIframeSources();
        });
    }

    ngAfterViewInit() {
        this.contextService.clearData(); //ovo je kopirano iz starog shopa
        this.contextService.getContext(); //ovo je kopirano iz starog shopa
    }

    copyReferalLink() {
        let URL = window.location.origin + '/referral/' + this.contextService.memberNumber;
        this.utilityService.copyToClipboard(URL);
        // example. https://klub.farmasi.ba/referral/BH02783
        
    }

    public logout(redirect = 'login'): void {
        this.authService.removeAuth();
        // this.menu.clearMenu(); TODO ovo mislim da ne treba mijenjati
        this.router.navigate([redirect]);
    }

    isReferralLinkVisible() {
        if (this.authService.isTokenInStorage && !this.contextService.isPublicMember()) {
            //get MLM_LEVEL_REFERRAL
            this.contextService.getMlmLevelReferral((conf) => {
                this.profileService.getProfileDetails((profile: any) => {
                    this.qualifyReferral = profile != null && conf.value != null && +profile.mlmLevelCode >= conf.value;
                    this.referralLoaded = true;
                    if (this.qualifyReferral || profile.referralQualified) {
                        this.showReferalLink = true;
                    } else {
                        this.showReferalLink = false;
                    }
                });
            });
        }
    }

    checkAltCurrency() {
        this.contextService.getCompanyAltCurrency((altCurr: { companyId: string, altCurrency: string }) => {
            if (altCurr.altCurrency) {
                this.altCurrency = altCurr.altCurrency;

                if (this.altCurrency)
                    this.contextService.getCompanyAltCurrencyExchangeRate((altCurrExchangeRate: { companyId: string, altCurrencyExchangeRate: number }) => {
                        if (altCurrExchangeRate.altCurrencyExchangeRate)
                            this.altCurrencyExchangeRate = altCurrExchangeRate.altCurrencyExchangeRate;
                    })
            }
        })
    }

    changedInput() {
        let wordSearch = this.searchInput;
        setTimeout(() => {
            if (wordSearch == this.searchInput) {
                if (this.router.url.includes('shop')) {
                    this.router.navigate(
                        [],
                        {
                            relativeTo: this.activatedRoute,
                            queryParams: { search: this.searchInput },
                            queryParamsHandling: 'merge', // remove to replace all query params by provided
                        }
                    );
                } else {
                    this.router.navigate(['/shop'], { queryParams: { search: this.searchInput } });
                }
                setTimeout(() => this.showSearchInput = false, 500);
            } else if (wordSearch == '' && this.router.url.includes('shop')) {
                this.router.navigate(
                    [],
                    {
                        queryParams: { search: '' },
                        relativeTo: this.activatedRoute,
                        queryParamsHandling: 'merge', // remove to replace all query params by provided
                    }
                );
            }
        }, 1000);
    }

    private handleCartNotifications() {
        this.basketService.cartSubject.subscribe((data: CartLocal) => {
            this.mode = this.basketService.getOrderId();
            this.cartItems = data.items;
            this.orderTotal = 0;
            data.items.forEach(product => {
                this.orderTotal += product.quantity * product.price;
            });

            if (data.items.length == 0) {
                this.showCartItems = 0;
                return
            }
            //  Don't end animation if new product is added
            this.showCartItems++;
            let initialNumber = this.showCartItems;
            setTimeout(() => {
                if (initialNumber == this.showCartItems)
                    this.showCartItems = 0;
            }, 5000);
        });
    }

    closeItemsCart() {
        setTimeout(() => {
            this.showCartItems = 0;
        }, 100);
    }

    forceShowCartItems() {
        if (this.cartItems.length == 0)
            this.showCartItems = 0;
        else
            this.showCartItems++;
    }

    isLogged() {
        if (this.authService.isTokenInStorage)
            return true;

        return false;
    }

    isReferral() {
        return !!localStorage.getItem("referral_number");
    }

    checkIframeSources(): void {
        this.catalogueService.getIpaperCatalogues((res: any[]) =>  { 
            
            const flipbook = res.find(obj => obj.type === 'CAMPAIGN');

            if(flipbook !== undefined) {
                this.currentCampaingImg = flipbook.imageUrl;
                this.showFlipbook = true;
            }

            this.showBannerFlipbook = res.find(obj => obj.type === 'SPECIAL_OFFER') !== undefined;
        });
    }
}
