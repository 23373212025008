import { Injectable } from '@angular/core';
import { RequestService, HttpRequestType, ContextService } from 'src/app/core';
import { Category, Product } from './shop.models';
import { Constants } from '../../../app.constants';
import { BaseService } from '../../../shared/base.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ShopService {
    // private categories: Category[] = [];

    public categoriesSubject = new Subject<Category[]>();
    private categories: Category[] = [];
    public products: Product[] = [];
    public homeProducts; // initial products on home screen
    private readonly pageSize: number = 36;

    constructor(private requestService: RequestService, private baseService: BaseService, private contextService: ContextService) { 
        setTimeout(() => {
            this.homeProducts = null;
        }, 10000);
    }

    public getProductsByCategory(filter: any, motiv: boolean, onSuccess: Function, onFail: Function) {
      
        let sortColumn = filter.column.length > 0 ? `&sortBy=${filter.column}` : '';
        let sortOrder = filter.order.length > 0 ? `&sortOrder=${filter.order}` : '';
        let inputString = filter.search != null ? (filter.search.length > 0 ? `&search=${filter.search.toUpperCase()}` : ''): '';
        let groupID = filter.selectedCategoryId ? `/${filter.selectedCategoryId}` : '';
        let action = filter.action ? `&action=1` : '';

        let page = filter.page;

        console.log(filter);

        this.requestService.createRequest(HttpRequestType.Get, `${Constants.mlm}article-category/products${groupID}?size=${this.pageSize}&page=${page}${sortColumn}${sortOrder}${inputString}${action}&motivational=true`, null, null, (productByCategory: Array<any>) => {
            this.products = [];
            for (let product of productByCategory['content']) {
                this.products.push(
                    new Product(
                        product.id,
                        product.name,
                        product.productBrand,
                        product.description,
                        product.images,
                        product.price,
                        product.isMotivationalProduct,
                        product.productClassification,
                        product.kolicina,
                        product.excluded,
                        product.odnospak,
                        product.doppak,
                        product.shortDescription,
                        product.quantityOrderMax == null ? 99 : product.quantityOrderMax,
                        product.saleProduct,
                        product.newProduct,
                        product.popularProduct,
                        0,
                        product.loyaltyProductPoints,
                        product.rebateFixed
                        ));
            }
            productByCategory['content'] = this.products;
            onSuccess(productByCategory);
            if(filter.column == "ca.naziv" && filter.order == "desc" && filter.page == 0 && filter.search == "" && filter.selectedCategoryId == "")
                this.homeProducts = productByCategory;
        }, onFail);
    }

    public getCategories(onSuccess: Function) {
        if (this.categories.length != 0)
            onSuccess(this.categories);
        else
            this.baseService.getAll(`${Constants.mlm}article-category?motivational=${this.contextService.isPublicMember()}`, (response: Category[]) => {
                this.categories = [];
                for (let i = 0; i < response.length; i++) {
                    if (response[i].group.length == 1)
                        this.categories.push(response[i])

                    for (let j = 0; j < this.categories.length; j++) {
                        if (response[i].group[0] == this.categories[j].group[0] && response[i].group.length >= 1)
                            this.categories[j].group.push(response[i])
                    }
                }
                onSuccess(this.categories);
            });
    }

    public getProduct(id: any, onSuccess: Function) {
        let index = this.products.findIndex(el => el.productId == id);        
        if(index != -1)
            onSuccess(this.products[index]);
        else
            this.baseService.getById(`${Constants.mlm}article-category/product`, id, (response) => {
                let modifyResponse: Product;
                modifyResponse = new Product(
                    response.id,
                    response.name,
                    response.productBrand,
                    response.description,
                    response.images,
                    response.price,
                    response.isMotivationalProduct,
                    response.productClassification,
                    response.kolicina,
                    response.excluded,
                    response.odnospak,
                    response.doppak,
                    response.shortDescription,
                    response.quantityOrderMax == null ? 99 : response.quantityOrderMax,
                    response.saleProduct,
                    response.newProduct,
                    response.popularProduct,
                    0,
                    response.loyaltyProductPoints,
                    response.rebateFixed)
                this.products.push(modifyResponse);
                onSuccess(modifyResponse)
            });
    }
}
