import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-first-order-submit-modal',
    templateUrl: './first-order-submit-modal.component.html',
    styleUrls: ['./first-order-submit-modal.component.scss']
})
export class FirstOrderSubmitModalComponent implements OnInit {
    @Output() closeFirstOrderModalEvent = new EventEmitter();

    constructor(private router: Router) { }

    ngOnInit(): void {
    }

    closeAndRedirect($event){
        this.closeFirstOrderModalEvent.emit($event);
        this.router.navigate(['login']);
    }
}
