import { Injectable } from '@angular/core';


import { finalize } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { LoadingService } from './loading.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './alert.service';


@Injectable()
export class RequestService {

    public constructor(
        private authService: AuthService,
        private http: HttpClient,
        private loadingService: LoadingService,
        private router: Router, 
        private translateService: TranslateService,
        private alertService: AlertService
    ) { }

    public createRequest(httpRequestType: HttpRequestType, url: string, body: any, headers: HttpHeaders = null, onSuccess: Function = null, onFail: Function = null): void {
        let requestType = this.determineRequestType(httpRequestType);

        setTimeout(() => {
            this.loadingService.showLoader();
        });

        this.authService.refreshTokenIfNeeded()
            .pipe(finalize(() =>
                    requestType(url, body, { headers: headers || this.authService.authHeaders, body: body })
                    .pipe(finalize(() =>
                        setTimeout(() => {
                            this.loadingService.hideLoader();
                        })
                    ))
                    .subscribe(
                        (response: any) => {
                            if (onSuccess != null) {
                                if(response == null)
                                    onSuccess();
                                else if (response._body == '')
                                    onSuccess();
                                else {
                                    onSuccess(response);
                                }
                            }
                        },
                        (error: any) => onFail != null ? onFail(error) : this.handleError(error)
                    )
                )
            ).subscribe();
    }

    public createRequestAsPromise(httpRequestType: HttpRequestType, url: string, body: any, headers: HttpHeaders = null, onSuccess: Function = null, onFail: Function = null): Promise<Response> {
        let requestType = this.determineRequestType(httpRequestType);

        setTimeout(() => {
            this.loadingService.showLoader();
        });

        return this.authService.refreshTokenIfNeeded().toPromise()
            .then(() => this.executeAfterTokenRefresh(requestType, url, body, headers, onSuccess, onFail))
            .catch(() => this.executeAfterTokenRefresh(requestType, url, body, headers, onSuccess, onFail));
    }

    private executeAfterTokenRefresh(requestType: Function, url: string, body: any, headers: HttpHeaders = null, onSuccess: Function = null, onFail: Function = null): Promise<Response> {
        return requestType(url, body, { headers: headers || this.authService.authHeaders }).toPromise()
            .then((response: any) => {
                setTimeout(() => {
                    this.loadingService.hideLoader();
                });
                
                return onSuccess != null ? response._body == '' ? onSuccess() : onSuccess(response) : response;
            })
            .catch((error: any) => {
                setTimeout(() => {
                    this.loadingService.hideLoader();
                });

                return onFail != null ? onFail(error) : this.handleError(error);
            });
    }

    private determineRequestType(httpRequestType: HttpRequestType): Function {
        switch (httpRequestType) {
            case HttpRequestType.Get:
                return (url: string, _: any, options: any) => this.http.get(url, options);
            case HttpRequestType.Post:
                return (url: string, body: any, options: any) => this.http.post(url, body, options);
            case HttpRequestType.Put:
                return (url: string, body: any, options: any) => this.http.put(url, body, options);
            case HttpRequestType.Delete:
                return (url: string, body: any, options: any) => this.http.delete(url, options);
            case HttpRequestType.Patch:
                return (url: string, body: any, options: any) => this.http.patch(url, body, options);
        }
    }

    
    private lastErrorTimeStamp: Date = new Date();
    private lastErrorMessage: string = '';

    public handleError(error: any): void {
        let message: string = '';
        let multipleErrors: Array<any> = [];
        let now: Date = new Date();

        if (error.status == 503) {
            this.authService.removeAuth();
            if (error._body.includes("does not allow public access")) { //redirect public user to login if access is not allowed
                this.router.navigate(['login']);
            }
            else {
                this.router.navigate(['/']);
                this.router.navigate(['maintenance']);
            }
        }
        if (error.status == 401) {
            this.translateService.get('ERRORS.UNAUTHORIZED').subscribe((translation: string) => {
                message = translation;
            });

            if (message != this.lastErrorMessage || (now.getTime() - this.lastErrorTimeStamp.getTime()) > 1000)
                this.alertService.error(message);

            this.lastErrorTimeStamp = now;
            this.lastErrorMessage = message;

            this.authService.removeAuth();
            this.router.navigate(['login']);
        }
        else {
            try {
                message = error.error.error_description || error.error.message;
                multipleErrors = error.error.field_errors || error.error.items || [];
            } catch (exception) {
                this.translateService.get('ERRORS.GENERAL').subscribe((translation: string) => {
                    message = translation;
                });
            }

            if (multipleErrors.length > 0)
                for (let errorItem of multipleErrors) {
                    if (errorItem.message != "")
                        this.alertService.error(errorItem.message, false);
                    else
                        this.alertService.error(message, false);
                }
            else {
                if (message != this.lastErrorMessage || (now.getTime() - this.lastErrorTimeStamp.getTime()) > 1000)
                    this.alertService.error(message || 'ERRORS.GENERAL');

                this.lastErrorTimeStamp = now;
                this.lastErrorMessage = message;
            }
        }
    }    
}

export enum HttpRequestType {
    Get, Post, Put, Delete, Patch
}

export class RequestStatus {
    private result = 0;

    constructor() { }

    public setSuccessful() {
        this.result = 1;
    }

    public setFailed() {
        this.result = 2;
    }

    public isSuccessful() {
        return this.result == 1;
    }

    public isFailed() {
        return this.result == 2;
    }
}
