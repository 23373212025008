<div class="noselect text-left" *ngIf = "this.pagination.totalPages.length > 2">
    <div>
        <ul class="m-0 px-0">
            <li [routerLink]="['./']" [queryParams]="{ p: '0' }" queryParamsHandling="merge" *ngIf="pagination.curPage > 3" class="d-inline-block pager-list-item shortAnim clickable opacity50">
                <a [routerLink]="['./']" [queryParams]="{ p: '0' }" queryParamsHandling="merge" >
                    <app-icons class="hover-pink" [icon]="'angle_left'"></app-icons><app-icons class="hover-pink" [icon]="'angle_left'"></app-icons>
                </a>
            </li>
            <li [routerLink]="['./']" [queryParams]="{ p: pagination.curPage - 1 }" queryParamsHandling="merge" *ngIf="!pagination.firstPage" class="d-inline-block pager-list-item shortAnim clickable opacity50">
                <a [routerLink]="['./']" [queryParams]="{ p: pagination.curPage - 1 }" queryParamsHandling="merge">
                    <app-icons class="hover-pink" [icon]="'angle_left'"></app-icons>
                </a>
            </li>
            <li [routerLink]="['./']" [queryParams]="{ p: page }" queryParamsHandling="merge" class="d-inline-block pager-list-item shortAnim clickable" *ngFor="let page of pagination.displayPages; let i = index">
                <a [routerLink]="['./']" [queryParams]="{ p: page }" queryParamsHandling="merge" [ngClass]="{'text-pink': page == pagination.curPage}"> 
                    {{ page + 1 }}
                </a>
            </li>
            <li [routerLink]="['./']" [queryParams]="{ p: pagination.curPage + 1 }" queryParamsHandling="merge" class="d-inline-block pager-list-item shortAnim clickable opacity50" *ngIf="!pagination.lastPage">
                <a [routerLink]="['./']" [queryParams]="{ p: pagination.curPage + 1 }" queryParamsHandling="merge">
                    <app-icons class="hover-pink" [icon]="'angle_right'"></app-icons>
                </a>
            </li>
            <li [routerLink]="['./']" [queryParams]="{ p: pagination.totalPages?.length - 1 }" queryParamsHandling="merge" class="d-inline-block pager-list-item shortAnim clickable opacity50" *ngIf="pagination.curPage < pagination.totalPages?.length - 4" >
                <a [routerLink]="['./']" [queryParams]="{ p: pagination.totalPages?.length - 1 }" queryParamsHandling="merge">
                    <app-icons class="hover-pink" [icon]="'angle_right'"></app-icons><app-icons class="hover-pink" [icon]="'angle_right'"></app-icons>
                </a>
            </li>
        </ul>
    </div>
</div>