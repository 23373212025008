export class Banner {
    id?: number;
    companyId?: number;
    name?: string;
    target?: string;
    scope?: string;
    scopeId?: number | string;
    timeFrom?: Date;
    timeTo?: Date;
    refreshRepeatInterval?: number;
    timedRepeatInterval?: number;
    url?: string;
    text?: string;
    lastShownTime?: Date;
    lastShownRefresh?: number;
    popup?: boolean;
    locations?: PromoBannerLocationType[]
}

export enum PromoBannerLocationType {
    HOME, CHECK_OUT, MOTIVATIONAL, ORDER_VIEW,
    FINAL_ORDER_SURVEY
}