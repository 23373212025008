import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AlertService } from '../core/alert.service';
import { CartLocal, Product } from '../inside-layout/routes/shop/shop.models';
import { ContextService } from '../core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class BasketService {
    basket: string = "farmasi_basket";

    cart: CartLocal = {
        orderId: null,
        items: [],
        campaigId: null,
        time: new Date()
    };

    orderId: number;

    cartSubject = new BehaviorSubject(new CartLocal());

    constructor(private alertService: AlertService, private contextService: ContextService, private route: ActivatedRoute) {
        this.getBasketAndCheckTime();
    };

    addOrUpdateProduct(product: Product, orderId: number = null, quantity: number) {
        if(product.quantity == 0)
            product.quantity = quantity;
        let index = this.cart.items.findIndex(el => el.productId === product.productId);
        if (orderId != this.cart.orderId)
            this.clearItems();
        if (index == -1){ // if dont exist
            product.quantity = quantity;
            this.cart.items.unshift(product);
            window.innerWidth < 992 ? this.alertService.success('SHOP.CART.ADDED') : '';
        } 
        else { // update item
            if(product.quantityOrderMax == null)
              product.quantityOrderMax = 99;
            if (product.quantityOrderMax <= this.cart.items[index].quantity + quantity) {
                this.alertService.error('SHOP.QUANTITY_ORDER_MAX');
                this.cart.items[index].quantity = product.quantityOrderMax;
                return;
            }
            this.cart.items[index].quantity = this.cart.items[index].quantity + quantity;
            window.innerWidth < 992 ? this.alertService.success('SHOP.CART.ADDED') : '';
        }

        this.updateLocal(orderId);
        return this.cart.items;
    }

    updateProductWithId(product: string, orderId: number = null, quantity: number){ // ovo se koristi kad se iz cart-a mijenjaju kolicine
        let index = this.cart.items.findIndex(el => el.productId === product);
        if (orderId != this.cart.orderId)
            this.clearItems();
        else if(index != -1) {
            if(this.cart.items[index].quantityOrderMax == null)
                this.cart.items[index].quantityOrderMax = 99;
            if (this.cart.items[index].quantityOrderMax <= this.cart.items[index].quantity) {
                this.alertService.error('SHOP.QUANTITY_ORDER_MAX');
                return;
            }
            this.cart.items[index].quantity = quantity;
        }
        this.updateLocal(orderId);
    }

    removeProduct(id: string, orderId: number = null) {
        let index = this.cart.items.findIndex(el => el.productId == id);
        if (index != -1) { // if exist in array
            this.cart.items.splice(index, 1);
            this.updateLocal(orderId);
            // this.alertService.error('CART.DELETED', false);
        }
    }

    setItemsToLocal(orderId: number, products: Product[]) {
        this.clearItems();
        this.cart.items = products;
        this.updateLocal(orderId);
    }

    // getItems(orderId: number = null) {
    //     if (orderId == this.cart.orderId)
    //         return this.cart.items;
    //     else if (orderId != this.cart.orderId)
    //         return this.clearItems().items;
    // }

    updateLocal(orderId: number) {
        this.cart = {
            orderId: orderId,
            campaigId: this.contextService.profile.currentCampaignId,
            items: this.cart.items,
            time: new Date()
        }
        localStorage.setItem(this.basket, JSON.stringify(this.cart));
        this.cartSubject.next(this.cart);
    }

    clearItems() {
        // pozivam u order-previewu clearItems, ne znam treba li ranije
        this.cart = {
            orderId: null,
            campaigId: this.contextService.profile.currentCampaignId,
            items: [],
            time: new Date()
        }
        localStorage.removeItem(this.basket);
        this.cartSubject.next(this.cart);
        return this.cart;
    }

    setOrderId(orderId){
        this.orderId = orderId;
    }

    emptyCartIfEdit(){
        if(this.cart.orderId != null)
            this.clearItems();
    }

    getOrderId(){
        if(this.orderId == this.cart.orderId)
            return this.orderId
        else {
            return null
            this.orderId = null;
        }
    }

    getQuantityForProduct(productId: string): number {    
        let index = this.cart.items.findIndex(el => el.productId === productId);
        if (index != -1)
            return this.cart.items[index].quantity;
        else
            return 0;
    }

    getBasketAndCheckTime(){
        var sevenDaysAgo = new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
        let localBasket = JSON.parse(localStorage.getItem(this.basket));
                    
        if (localBasket == null || new Date(sevenDaysAgo) > new Date(localBasket.time)) // TODO treba dodati ako je campaing != currentCamapign
            this.clearItems();
        else {
            this.cart = localBasket;
            this.updateLocal(this.cart.orderId);
            this.setMaxItemsQuantity(); // if is public user set max quantity to 99
        }
    }

    private setMaxItemsQuantity(){  // if is public user set max quantity to 99
        if(this.contextService.isPublicMember())
            for(let product of this.cart.items)
                if(product.quantity > 1){
                    let compareNumber: Array<number> = [product.quantity, 99]; //check smaller number
                    product.quantity = Math.min(...compareNumber);
                    localStorage.setItem(this.basket, JSON.stringify(this.cart));
                }
    }
}