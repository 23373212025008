import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './alert.service';

@Injectable()
export class UtilityService {
    constructor(private translateService: TranslateService, private alertService: AlertService) { }

    public showError(control: AbstractControl, errorType: string): boolean {
        return control.touched && control.errors && control.errors[errorType];
    }

    public getDefaultDateFormat(): string {
        return 'dd.mm.yy';
    }

    public getDefaultYearRange(): string {
        return '1900:2030';
    }

    public formatDate(date: Date): string {
        if(date == null)
            return '';

        date = new Date(date);
            
        return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
    }

    public formatTime(date: Date): string {
        if(date == null)
            return '';

        date = new Date(date);
        
        let hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
        let min = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

        return `${hours}:${min}`;
    }
    
    public getNow(addDays?: number): string {
        if (addDays)
            return new Date(new Date(Date.now()).getDate() + addDays).toLocaleString();
        
        return new Date(Date.now()).toLocaleString();
    }

    public prepareLookupItems(items: any[], valueField: string = null, labelField: string = null, translationPrefix: string = null): any[] {
        if (items.length < 1)
            return [];
        
        if (translationPrefix == null) {
            items.map(item => ({ value: item[valueField] || item, label: item[labelField] || item }));
            return items;
        } else {
            let translatedItems = [];
            this.translateService.get(items.map(e => `${ translationPrefix }${ e[labelField] }`)).subscribe((translations: any[]) => {
                for (let item of items)
                    translatedItems.push({ value: item[valueField], label: translations[`${ translationPrefix }${ item[labelField] }`] });
            });
            return translatedItems;
        }
    }

    public enumToSelectArray(enumObject: any, isString = false): any[] {
        return Object.keys(enumObject).filter(k => typeof enumObject[k as any] === 'number').map(k => ({ value: !isString ? enumObject[k as any] : k, label: k }));
    }

    public getAllYears(maxYear: number = null, minYear: number = null) {
        let reverse: boolean = false;
        let currentYear = new Date().getFullYear();

        if(maxYear == null) {
            reverse = true;
            maxYear = currentYear + 10;
        }

        if(minYear == null) {
            minYear = currentYear;
        }

        let years = [];
        for(let i=maxYear; i>=minYear; i--)
            years.push({value: i});

        
        if(reverse)
            years.reverse();
            
        return years;
    }

    public generateUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
    }

    public formatNotificationDateTime(dateTime: string) {
        if(dateTime == null || dateTime == '')
            return null;

        let date = this.formatDate(new Date(dateTime.substring(0, 10)));
        let time = dateTime.substring(11, 16)

        return { date: date, time: time };
    }

    copyToClipboard(URL: string){
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = URL;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.alertService.success('SHOP.SUCCESS_GENERATED');
    }
}
