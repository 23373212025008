import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/core';

@Component({
  selector: '',
  templateUrl: './mailing-unsubscribe.component.html',
})
export class MailingUnsubscribeComponent implements OnInit {

    private readonly id = this.route.snapshot.params['id']; 

    constructor(
      private readonly route: ActivatedRoute, 
      private readonly http: HttpClient, 
      private readonly alertService: AlertService,
      private readonly router: Router,
    ) {}

    ngOnInit(): void {    
       this.http.delete(`/mlm/api/v1/mailing-lists/${this.id}/unsubscribe`)
                .subscribe({
                  next: () =>  {
                    this.alertService.success('MAILING.UNSUBSCRIBE-MESSAGE');
                    localStorage.removeItem('email_sub_modal_shown');
                    setTimeout(() => this.router.navigate(['']), 5000);
                  },
                  error: () => {
                    this.alertService.error('ERRORS.GENERAL');
                    setTimeout(() => this.router.navigate(['']), 5000);
                  }
                }
      );
    }

}
