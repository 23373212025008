import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { AlertService, LoadingService } from 'src/app/core';
import { SettingsService } from 'src/app/core/settings.service';
import { RegisterService } from '../register/register.service';
@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit, OnDestroy {
    private sub: Subscription;
    private mode: any;
    
    public passwordForm  = this.formBuilder.group({
        'password': ['', [Validators.required, Validators.pattern('^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9]{6,}$')]],
        'confirmPassword': '',
        'resetToken': [null, Validators.required]
    }, {validator: this.checkPasswords});

    constructor(private alertService: AlertService, public formBuilder: FormBuilder, public route: ActivatedRoute, private registerService: RegisterService, public router: Router, public appService: AppService, public settings: SettingsService, public loadingService: LoadingService) {}

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.mode = params['token'];
            if (this.mode != null)
                this.passwordForm.controls['resetToken'].setValue(this.mode);
        });
    }

    ngAfterViewInit() {
        this.appService.focusElement('resetPassword');
    }

    ngOnDestroy(){
        if(this.sub)
            this.sub.unsubscribe();
    }    

    public submitForm(): void {
        this.registerService.resetPasswordNewPassword(this.passwordForm.value, () => {
            this.alertService.success("REGISTRATION.SUCCESPASSWORDSET");
            this.router.navigate(['/login']);
        });
    }

    checkPasswords(group: FormGroup) {
        let pass = group.get('password').value;
        let confirmPass = group.get('confirmPassword').value;
        return pass === confirmPass ? null : { notSame: true }
  }
}