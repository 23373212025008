<div class="text-center" style="padding-top: 100px">
    <h2>MAINTENANCE MODE</h2>
    <img src="../../../assets/images/maintenance.png" style="margin-top: 50px">
</div>
<!-- Maintenance mode for every company - used for new year -->
<!-- <div class="row bg-img">
    <img src="../../../assets/images/farmasi_new_year.jpg" alt="" class="mobile-img">
    <div class="col-6 d-none d-sm-block">

    </div>
    <div class="col-12 col-sm-6 m-auto">
        <ng-container *ngIf="companyId == 1">
            <p>Poštovani članovi,</p>
            <p>
                Radi ažuriranja sistema na postavke za 2021., web shop neće raditi 48 sati, a sa radom krećemo u nedjelju, 3.1. u 00:00 sati.
            </p>
            <p>
                Sretnu i uspješnu Novu godinu želi vam <br>
                FARMASI BH
            </p>
        </ng-container>
        <ng-container *ngIf="companyId == 3">
            <p>Poštovani članovi,                
            </p>
            <p>
                Radi ažuriranja sistema na postavke za 2021., web shop neće raditi 48 sati, a s radom krećemo u nedjelju, 3. 1. u 00:00 sati.
            </p>
            <p>
                Sretnu i uspješnu Novu godinu želi vam <br>
                
                FARMASI HR
            </p>
        </ng-container>
        <ng-container *ngIf="companyId == 6">
            <p>Poštovani članovi,                
            </p>
            <p>
                Radi ažuriranja sistema na postavke za 2021., web shop neće raditi 48 sati, a s radom krećemo u nedjelju, 3. 1. u 00:00 sati.
            </p>
            <p>
                Sretnu i uspješnu Novu godinu želi vam <br>
                
                FARMASI ME
            </p>
        </ng-container>
        <ng-container *ngIf="companyId == 2">
            <p>Poštovani članovi,
                
            </p>
            <p>

                Zbog ažuriranja sistema na postavke za 2021., web shop neće raditi 48 sati, a sa radom krećemo u nedelju, 3.1. u 00:00 sati.
                

            </p>
            <p>
                Srećnu i uspešnu Novu godinu želi vam <br>
                FARMASI SRBIJA

            </p>
        </ng-container>
        <ng-container *ngIf="companyId == 5">
            <p>Почитувани членови,
                
            </p>
            <p>

                Поради ажурирање на системот и сетирање на 2021 год., web придавницата нема да работи 48 часа, и со работа започнуваме во недела, 03.01. во 00:00 часот.
                

            </p>
            <p>
                Среќна и успешна Нова Година Ви посакува <br>
                FARMASI Козметика Дооел

            </p>
        </ng-container>
        <ng-container *ngIf="companyId == 12">
            <p>Të nderuar anëtarë,
                
            </p>
            <p>
                Për shkak të azhornimit të sistemit për 2021, web shop nuk do të punojë 47 orë, dhe do të rifillojë të jetë në punë më 03.01 në orën 00:00.
                

            </p>
            <p>Ju urojmë një vit plot lumturi dhe të suksesshëm <br>
                Famsi ALB

            </p>
        </ng-container>
        <ng-container *ngIf="companyId == 13">
            <p>Të nderuar anëtarë,
                
            </p>
            <p>
                Për shkak të azhornimit të sistemit për 2021, web shop nuk do të punojë 47 orë, dhe do të rifillojë të jetë në punë më 03.01 në orën 00:00.
                

            </p>
            <p>Ju urojmë një vit plot lumturi dhe të suksesshëm <br>
                Famsi KS

            </p>
        </ng-container>
        <ng-container *ngIf="companyId == 4">
            <p>Spoštovani člani,
                
            </p>
            <p>

                zaradi ažuriranja sistema na postavke za leto 2021, spletna trgovina ne bo delovala 48 ur, z delovanjem ponovno začnemo v nedeljo, 3.1. ob polnoči (00:00 uri).
                

            </p>
            <p>
                Srečno in uspešno Novo leto vam želi <br>
                
                FARMASI SLO

            </p>
        </ng-container>
    </div>
</div>
<img class="logo" src="../../../assets/logo-header.svg" alt=""> -->