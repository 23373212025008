import { Component, Input, OnInit } from '@angular/core';
import { ContextService } from '../../core/context-service';
import { ProfileService } from '../../inside-layout/routes/club-layout/profile/profile.service';
import { MemberLoyaltyProgram } from 'src/app/inside-layout/routes/club-layout/profile/profile.model';

@Component({
  selector: 'app-loyalty-info-dropdown',
  templateUrl: './loyalty-info-dropdown.component.html',
  styleUrls: ['./loyalty-info-dropdown.component.scss']
})
export class LoyaltyInfoDropdownComponent implements OnInit {
  @Input('class') class: string;
  @Input('type') type: string = 'SIMPLE' || 'DROPDOWN';

  public memberLoyaltyProgram: MemberLoyaltyProgram[] = [];
  public showType: string = 'COIN' || 'WALLET';

  constructor(
    public contextService: ContextService,
    public profileService: ProfileService
  ) {
  }

  ngOnInit() {
    this.getLoyaltyProgramDetails('COIN');
  }

  getLoyaltyProgramDetails(type: string = 'COIN' || 'WALLET') {
    this.showType = type;
    this.profileService.getLoyaltyProgramDetails((res: MemberLoyaltyProgram[]) => {
      this.memberLoyaltyProgram = res;
    })
  }
}
