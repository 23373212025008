import { Injectable } from '@angular/core';
import {
  MatDialog
} from "@angular/material/dialog";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CatalogueArtikal } from './banners/banners.service';
import { DialogComponent } from "./dialog/dialog.component";
import { InputDialogComponent } from './dialog/input-dialog/input-dialog.component';
import { SurveyDialogComponent } from './dialog/survey-dialog/survey-dialog.component';
import { InputDialogData, SurveyDialogData } from './shared.models';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(private matDialog: MatDialog, private router: Router) { }

    OpenModal(message: string, heading: string = 'MODAL.CONFIRMATION', callback = null, colorBtnTextKey?: string, neutralBtnTextKey?: string) {
        let data = {
          message: message,
          heading: heading,
          callback: callback,
          colorBtnTextKey: colorBtnTextKey ?? null,
          neutralBtnTextKey: neutralBtnTextKey ?? null
        }

        let matDialogRef = this.matDialog.open(DialogComponent, { data });

        matDialogRef.afterClosed().subscribe(res => {
            if(callback && res)
                callback();
        });
    }

    openInputDialog(message: string): Observable<InputDialogData> {
      return this.matDialog.open(InputDialogComponent, { data: { message: message } })
        .afterClosed();
    }

    openSurveyDialog(message: string, catalogueArtikals: CatalogueArtikal[]): Observable<SurveyDialogData> {
      return this.matDialog.open(SurveyDialogComponent, { width: 'min(700px, 95vw)', maxWidth: '95vw', data: { message, options: catalogueArtikals } })
        .afterClosed();
    }
}