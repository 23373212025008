<div [@inOutAnimation] *ngIf="!product" class="loader">
    <div></div>
    <div></div>
</div>
<div [@inOutAnimation] *ngIf="product" class="row">
    <div class="back-btn mb-3 mb-lg-0">
        <a class="hover-pink clickable" (click)="goBack()">
            <app-icons [icon]="'angle_left'" class="hover-pink"></app-icons>
            <span>
                {{ 'SHOP.CONTINUE_TO_SHOP' | translate }}
            </span>
        </a>
        <!-- <ng-template #editContinue>
            <a [routerLink]="['/shop/edit/'+mode]">
                <app-icons [icon]="'angle_left'" class="hover-pink"></app-icons>
                <span>
                    {{ 'SHOP.CONTINUE_TO_SHOP' | translate }}
                </span>
            </a>
        </ng-template> -->
    </div>
    <div class="col-12 col-lg-5 img-div-mobile d-flex dummy-img">
        <img *ngIf="product?.images?.length > 0; else dummyImage" [ngClass]="{'visible': imageLoaded }" (load)="imageLoaded = true" [src]="product.images[0]" [alt]="product.name" class="opacity0 shortAnim img-fluid d-block m-auto product-single-img">
        <ng-template #dummyImage>
            <img src="assets\logo-footer.svg" class="img-responsive product-img-large noselect d-block mb-5 mb-lg-auto m-auto product-single-img dummy-img">
        </ng-template>
    </div>
    <div class="col-12 col-lg-7">
        <div class="label-wrap text-uppercase">
            <span *ngIf="product.newProduct" class="new">{{ 'SHOP.LABEL.NEW'| translate }}</span>
            <span *ngIf="product.popularProduct" class="popular mx-2">{{'SHOP.LABEL.POPULAR' | translate}}</span>
            <span *ngIf="product.saleProduct" class="sale">{{'SHOP.LABEL.SALE' | translate}}</span>
            <!-- <span *ngIf="product?.rebateFixed && product.rebateFixed > 0" class="rebate">-{{ product.rebateFixed }}%</span> -->
            <div *ngIf="product.loyaltyProductPoints > 0" class="loyalty-container mt-1 mb-1 mb-sm-0 clickable">
                <a [routerLink]="['/loyalty']" >
                    <img src="../../../assets/icons/farmasi_coin.png" class="clickable loyaltyProductBadge">
                    <span class="text-black-50 clickable">{{ "LOYALTY.LOYALTY_PRODUCT" | translate }}</span>
                    <span class="material-icons text-black-50 p-0">help</span>
                </a>
            </div>
        </div>
                
        <p class="product-single-name">{{ product.name }}</p>
        <div class="text-pink text-xxl text-bolder d-flex align-items-baseline">
            <div class="d-flex flex-column">
                <span class="price">{{ product.price | number: '1.2-2' }} {{contextService.localCurr}}</span>
                <ng-container *ngIf="showQuantityPerUnitPrice">
                    <span class="text-md text-black-50">
                        {{ product.odnospak }} {{ product.doppak | lowercase }} 
                        (
                            {{ product.price / product.odnospak | number: '1.2-2' }} 
                            {{ contextService.localCurr }} {{ "SHARED.FOR" | translate }} 
                            1 {{ product.doppak | lowercase }}
                        )
                    </span>
                    <span *ngIf="altCurrency && altCurrencyExchangeRate" class="alt-price-sm">
                        {{ product.odnospak }} {{ product.doppak | lowercase }} 
                        (
                            {{ (product.price / altCurrencyExchangeRate) / product.odnospak | number: '1.2-2' }} 
                            {{ altCurrency }} {{ "SHARED.FOR" | translate }} 
                            1 {{ product.doppak | lowercase }}
                        )
                    </span>
                </ng-container>
            </div>
            <span *ngIf="altCurrency && altCurrencyExchangeRate" class="alt-price">
                {{ (product.price / altCurrencyExchangeRate) | number: '1.2-2' }} {{altCurrency}}
            </span>
        </div>
       
        <div class="row mt-2">
            <div class="col-12 order-3 order-sm-1 description">
                <p class="text-md">{{ product.description }}</p>
                <ng-container *ngIf="product.shortDescription">
                    <b class="text-sm text-black mb-0">{{ 'SHOP.INGREDIENTS' | translate}}:</b>
                    <p class="text-sm text-black">{{ product.shortDescription }}</p>
                </ng-container>
            </div>
            
            <div class="col-12 order-2 order-sm-3 mt-3">
                <p class="text-sm text-black">{{ 'SHOP.PRICE_TEARMS' | translate }}</p>
            </div>
            <div class="col-12 order-1 order-sm-2 mb-4 mb-sm-0 mt-1">
                <p [@inOutAnimation] *ngIf="basketService.getQuantityForProduct(product.productId)" class="text-pink text-l">{{ 'SHOP.CURRENTLY' | translate }}: <span class="text-bolder">{{ basketService.getQuantityForProduct(product.productId) }} </span>{{ 'SHOP.PIECES' | translate }}</p>
        
                <div class="number-input md-number-input d-inline">
                    <button (click)="minus()" class="minus"></button>
                    <input class="quantity" type="number" [(ngModel)]="quantity" min="1" value="1" (ngModelChange)="onKey()" (keyup)="onKey()">
                    <button (click)="plus()" class="plus"></button>
                </div>
                <div class="d-inline">
                    <button [disabled]="quantity == 0" (click)="addToBasket()" class="pink-black-btn outline-none px-5 py-4">
                        <app-icons [icon]="'cart_1'"></app-icons>
                        <span *ngIf="!showAddedToCartText" class="d-none d-md-inline">
                            {{ product.kolicina > 1 ? ('SHOP.CART.ADD' | translate) : ('SHOP.OUT_OF_STOCK' | translate) }}
                        </span>
                        <span *ngIf="showAddedToCartText" class="d-none d-md-inline">
                            {{ 'SHOP.SUCCESS_ADDED' | translate }}
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <!-- <div class="mt-3">
            dodaj u omiljene
        </div> -->
        <!-- <div class="p-2">
            <app-icons [icon]="'fb'"></app-icons>
            <app-icons [icon]="'ig'"></app-icons>
        </div> -->
    </div>
</div>